import { actions } from "../../utils/constants"

export const addDataToSheetAction = (data) => {
    return { type: actions.SET_DEVICES_LIST, data }
}

export const updateDataTosheetAction = (id, field, value) => {
    return { type: actions.UPDATE_DEVICES_LIST, id, field, value }
}
export const removeItemFromDeviceList = (id) => {
    return { type: actions.REMOVE_DEVICE_LIST, id }
}
export const setErrorsInDeviceList = (errors) => {
    return { type: actions.SET_ERRORS, errors }
}