import axios from "axios";
import { getHeaders } from "../utils/helper";

import endpoints from "./endpoints";

export const getReplacementTicketListApi = async params => {
    const callResponse = await axios({
        url: endpoints.replacementBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getReplacementTicketCountApi = async params => {
    const callResponse = await axios({
        url: endpoints.replacementCount,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const getReplacementTicketById = async params => {

    const callResponse = await axios({
        url: endpoints.replacementByTicketNo,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const createReplacementTicketApi = async data => {
    const callResponse = await axios({
        url: endpoints.replacementBase,
        method: "POST",
        headers: getHeaders(),        
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateReplacementTicketStatus = async data => {
    const callResponse = await axios({
        url: endpoints.replacementBase,
        method: "PUT",
        headers: getHeaders(),        
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const replaceCustomerDevice = async data => {
    const callResponse = await axios({
        url: endpoints.replacementCustomerReplace,
        method: "PUT",
        headers: getHeaders(),        
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const createReplacementTicketOTPApi = async data => {
    const callResponse = await axios({
        url: endpoints.replacementCreateOtp,
        method: "POST",
        headers: getHeaders(),        
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const verifyReplacementTicketOTPApi = async data => {
    const callResponse = await axios({
        url: endpoints.replacementVerifyOtp,
        method: "POST",
        headers: getHeaders(),        
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};