import { Box, Grid } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useMatch, useNavigate, useParams, useResolvedPath } from "react-router-dom"
import { fetchInventoryCountAction, setInventoryCountFilters } from "../../../store/actions/inventoryCountAction"
import { allowedDateFilters, getDateFiltersTime } from "../../../utils/helper"
import ClickButton from "../../button/ClickButton"
import CustomContainer from "../CustomContainer"

const InventoryCount = ({ isChannel }) => {

    const { loading, data, filters } = useSelector(state => state.inventoryCount)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const location = useLocation()


    useEffect(() => {
        dispatch(fetchInventoryCountAction({ isChannel, usercode: params.usercode, productCategory: params.productCategoryId, ...getDateFiltersTime(filters.dateTime), ...filters }, () => { }, () => { }))
    }, [params.usercode, filters, params.productCategoryId])



    return (
        <Box pt={2} pb={2}>
            <Grid container sx={{ justifyContent: "center" }} spacing={{
                xs: 1,
                md: 2,
            }}>
                {loading && [0, 1, 2, 3].map((val) => (<Grid key={val} item xs={6} md={3}>
                    <ClickButton loading={true} title={''} subTitle={""} />
                </Grid>))}

                {!loading && Array.isArray(data) && data.map((val) => {
                    if (val.children)
                        return <Grid item xs={12} md={12 * 3 / 5}>
                            <Box sx={(theme) => ({ height: "100%", display: "flex", width: "100%", background: theme.palette.light.main, borderRadius: 2,boxShadow:1 })} p={2}>
                                <Grid container spacing={1}>
                                    {val.children.map(child => (
                                        <Grid key={child.role} item xs={4} md={12 / data.length}>
                                            <ClickButton
                                            hideShadow={true}
                                                dropDownData={child.dropdown && allowedDateFilters}
                                                dropDownProps={{
                                                    value: filters.dateTime,
                                                    onChange: (e) => { e.preventDefault(); dispatch(setInventoryCountFilters('dateTime', (e.target.value))) }
                                                }}
                                                disabled={!child.redirect}
                                                onClick={() => {
                                                    if (child.redirect) {
                                                        navigate(child.redirect)
                                                    }
                                                }}
                                                active={location.pathname.split('/')[location.pathname.split('/').length - 1] == child.redirect}
                                                title={child.count}
                                                subTitle={child.name} />
                                        </Grid>
                                    )

                                    )}
                                </Grid>
                            </Box>
                        </Grid>
                    else
                        return (

                            <Grid key={val.role} item xs={6} md={12 / 5}>
                                <ClickButton
                                    dropDownData={val.dropdown && allowedDateFilters}
                                    dropDownProps={{
                                        value: filters.dateTime,
                                        onChange: (e) => { e.preventDefault(); dispatch(setInventoryCountFilters('dateTime', (e.target.value))) }
                                    }}
                                    disabled={!val.redirect}
                                    onClick={() => {
                                        if (val.redirect) {
                                            navigate(val.redirect)
                                        }
                                    }}
                                    active={location.pathname.split('/')[location.pathname.split('/').length - 1] == val.redirect}
                                    title={val.count}
                                    subTitle={val.name} />
                            </Grid>
                        )
                })}

            </Grid>
        </Box>
    )
}
export default InventoryCount