import { Search } from "@mui/icons-material"
import { InputAdornment, MenuItem } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { fetchInventoryList } from "../apis/inventory.api"
import AsyncDropDown from "./inputs/AsyncDropDown"
import { DynamicSearchBar } from "./inputs/SearchBar"

const CentralSearchBar = () => {
    const navigate = useNavigate()
    const params = useParams()
    const onBoxSelect = (boxId) => {
        if (boxId) {
            navigate('/dashboard/' + params.usercode + '/device/' + boxId)
        } else {
            navigate('/dashboard/' + params.usercode + '/inventory/')
        }
    }
    return <AsyncDropDown
        startAdornment={<InputAdornment position="end">

            <Search sx={{ fill: "gray", marginRight: 1 }} />

        </InputAdornment>}
        OptionComponent={({ option, ...rest }) => {
            return <MenuItem {...rest}>{option.boxId}</MenuItem>
        }}
        hideEndAdornment={true}
        InputComponent={DynamicSearchBar}
        onChange={(boxId) => { onBoxSelect(boxId?.boxId) }}
        titleKey={'boxId'}
        valueKey={"boxId"}
        lazyFun={async (filters) => { return await fetchInventoryList({ ...filters, centralSearch: true, onlyBoxId: true }) }}

    />
}
export default CentralSearchBar