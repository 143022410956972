import { fetchLifeTimeStockCount } from "../../apis/inventory.api"
import { actions, soldCategoriesName } from "../../utils/constants"
import { callApiAction } from "./commonAction"

export const fetchLifeTimeStockCountAction = (data, onSuccess, onError) => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.START_LIFE_STOCK_LOADING })
        dispatch(callApiAction(
            async () => await fetchLifeTimeStockCount(data),
            (response) => {

                const temp = [...response];
                let total = 0
                let i = 0


                const finalArray = []
                for (let row of temp) {
                    if (row.name != soldCategoriesName.REPLACED && row.name != soldCategoriesName.RETURNED)
                        total += row.count
                    if (row.name == soldCategoriesName.RETURNED)
                        temp[i]['name'] = "Total Replacement"

                    temp[i]['redirect'] = 'life-stock-data/' + row._id
                    temp[i]['activeKey'] = row._id


                    if (row.name != soldCategoriesName.REPLACED)
                        finalArray.push(temp[i])
                    
                    i++
                }

                finalArray.unshift({
                    name: "Total Supply",
                    redirect: 'life-stock',
                    activeKey: 'life-stock',
                    count: total,
                    _id: 'lifestock'
                })

                dispatch({ type: actions.SET_LIFE_STOCK_DATA, value: finalArray })
                onSuccess(response)

            },
            (err) => {

                onError(err)
            }
        ))
    }
}

