import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchSoldInvoiceList, fetchSoldList, payInvoiceApi } from "../../../apis/inventory.api"
import { dateConverter, getDateFiltersTime } from "../../../utils/helper"
import SoldPageUi from "./SoldPageUi"
import { invoicePaymentStatus, salesTypes } from "../../../utils/constants"
import InvoicePreview from "../invoices/InvoicePreview"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import MessageDilog from "../../../components/MessageDilog"
import { fetchInventoryCountAction } from "../../../store/actions/inventoryCountAction"
import SoldInvoicesUi from "./SoldInvoicesUi"
import { Box, Button, Chip, IconButton, Tooltip } from "@mui/material"
import { Done, Info } from "@mui/icons-material"
import DeviceDisplayModal from "../../../components/layouts/inventory/DeviceDisplayModal"
const SoldInvoiceCOntroller = ({ paymentStatus }) => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)
    const inventoryCount = useSelector(state => state.inventoryCount)

    const dispatch = useDispatch()
    const params = useParams()

    const openDeviceModal = useCallback((invoiceId, devices) => {
        console.log("Modal function called")
        dispatch(openModal({
            title : `Devices(${invoiceId})`,
            component : <DeviceDisplayModal devices={devices}/>
        }))
    },[])

    const columns = useMemo(() => [
        { id: 1, fieldName: 'invoiceNo', label: 'Invoice No.', align: "left", sort: true},
        { id: 2, fieldName: 'date', label: 'Sale Date', align: "left", renderValue: (params) => dateConverter({value: params.date}) },
        { 
            id: 3, 
            fieldName: '', 
            label: 'Payment Terms', 
            align: "left", 
            renderValue: (params) =>
            // console.log(paymentStatus, "payment status inside render value")
            <>
            {paymentStatus == invoicePaymentStatus.CREDIT ?
                    <Button
                        disableElevation
                        onClick={onPaymentbuttonCLick}
                        variant="contained"
                        color="primary"
                        aria-label="download"
                        size="small"

                    >
                        Mark&nbsp;As&nbsp;Paid
                    </Button>
                    : <Chip label="PAID" icon={<Done color="light" />} size="small" color="success" />}
                    </>
        },
        {
            id : 4,
            fieldName : '',
            label : 'Actions',
            align : 'left',
            minWidth: '250px',
            renderValue: (params, setParams) =>
            <> 
                <Box sx={{ width: "100%", display: "flex" }}>
                    <Tooltip title="View Details">
                     <IconButton onClick={() => openDeviceModal(params.invoiceNo, params.devices)} >
                        <Info color='info' />
                    </IconButton>
                    </Tooltip>
                </Box>
            </>
        },
    ], [dispatch]);


    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        salesType: '',
        paymentStatus: paymentStatus,
        productCategory: params.productCategoryId

    })

    const fetchList = () => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchSoldInvoiceList({ ...filters, ...inventoryCount.filters, ...getDateFiltersTime(inventoryCount.filters.dateTime) }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }

    const onDownloadInvoice = useCallback(async (invoiceId, name) => {
        dispatch(openModal({
            component:<InvoicePreview invoiceId={invoiceId} name={name} />,
            size : "md",
        }))
    }, [])


    const payInvoice = useCallback((type, boxId, invoiceId) => {
        return () => {
            
            dispatch(callApiAction(
                async () => await payInvoiceApi({ usercode: params.usercode, type, boxId, invoiceId }),
                (response) => {
                    dispatch(fetchInventoryCountAction({ usercode: params.usercode, productCategory: params.productCategoryId, ...inventoryCount.filters, ...getDateFiltersTime(inventoryCount.filters.dateTime)}, () => { }, () => { }))
                    fetchList()
                    dispatch(closeModal())
                },
                (err) => {
                    alert("something wents wrong")
                }
            ))
        }
    },[dispatch,inventoryCount,params])

    const onPaymentbuttonCLick = useCallback((type, boxId, invoiceId) => {
        dispatch(openModal({
            component:<MessageDilog title="Alert!" message={"Are you sure to mark it paid?"} onSubmit={payInvoice(type, boxId, invoiceId)} />,
            size : "xs",
        }))
    }, [dispatch,inventoryCount,params])

    useEffect(() => {
        fetchList()
    }, [filters, inventoryCount.filters])


    return (
        <>
            <SoldInvoicesUi
                onPaymentbuttonCLick={onPaymentbuttonCLick}
                onDownloadInvoice={onDownloadInvoice}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                paymentStatus={paymentStatus}
                list={list}
                columns={columns}
            />

        </>
    )
}
export default SoldInvoiceCOntroller