import { Button, FormControl, MenuItem, Select, Skeleton, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { center } from "../../assets/css/theme/common"
const buttonStyle = (theme, hideShadow) => {
    return {
        ...center,
        height: '100%',
        boxShadow:hideShadow ? "none" : 2,
        border: "2px solid " + theme.palette.grey.main,
        flexDirection: "column",
        overflow: "hidden",
        py: 4,
        background: theme.palette.light.main,
        "h4": {
            transition: "all 0.1s linear",
            color: theme.palette.dark.main
        },
        "h2": {
            transition: "all 0.1s linear",
            // color: theme.palette.dark.main
        },
        ":disabled": {
            cursor: "default",
            ":hover": {
                background: theme.palette.light.main
            },
            "h4": {

                color: theme.palette.dark.main
            },
            "h1": {

                color: theme.palette.primary.main
            }
        },
        ":hover": {
            background: "linear-gradient(0.22deg, #A91674 -94.29%, #E6204B 121.72%)",
            color: theme.palette.light.main,
            "h4": {

                color: theme.palette.light.main
            }
        },

    }
}

const skeletonStyle = (theme, rest) => {

    return {
        ...center,
        width: "100%",
        paddingTop: "65%",
        boxShadow: 2,




    }
}

const activeButtonStyle = (theme, hideShadow) => {
    
    return {
        ...center,
        height: '100%',
        boxShadow: hideShadow ? "none" : 2,
        border: "2px solid " + theme.palette.grey.main,
        py: 4,
        flexDirection: "column",
        background: "linear-gradient(261.22deg, #A91674 -94.29%, #E6204B 121.72%)",
        color: theme.palette.light.main
    }
}

const dropDownStyle = (theme) => {
    return {

        position: "absolute",
        bottom: "0px",

        display: "flex",
        textAlign: "left",
        px: 1,
        flexDirection: "column",

        left: 0,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        width: "100%",
        '::before': {
            display: "none !important"
        },
        background: theme.palette.light.main,
        color: theme.palette.dark.main
    }
}
const ClickButton = ({ hideShadow, loading, active, title, subTitle, icon, dropDownData, dropDownProps, ...props }) => {
    if (loading) {
        return <Skeleton variant="rounded" sx={skeletonStyle} />
    }
    return <Button  {...props} fullWidth={true} sx={(theme) => active ? activeButtonStyle(theme, hideShadow) : buttonStyle(theme, hideShadow)} >
        {icon}
        <Typography variant="h1">{title}</Typography>
        <Typography variant="h4"  >{subTitle}</Typography>


        {dropDownData && Array.isArray(dropDownData) &&

            <FormControl variant="standard" sx={dropDownStyle} fullWidth={true} >
                <Select
                    fullWidth={true}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="select interval"
                    sx={{ ":before": { display: "none" } }}
                    {...dropDownProps}
                >

                    {
                        dropDownData.map((item) => {
                            return <MenuItem key={item.id} value={item.id}>
                                {item.label}
                            </MenuItem>
                        })
                    }
                    <MenuItem value={' '}>
                        Lifetime
                    </MenuItem>
                </Select>
            </FormControl>
        }
    </Button>
}
export default ClickButton