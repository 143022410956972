
const typography = {
    typography: {
        display1: {
            fontSize: "54px",
            '@media (max-width:960px)': {
                fontSize: '48px',
            },
            '@media (max-width:650px)': {
                fontSize: '30px',
            }
        },
        h1: {
            fontSize: "48px",
            '@media (max-width:960px)': {
                fontSize: '30px',
            },
            '@media (max-width:650px)': {
                fontSize: '20px',
            }
        },
        h2: {
            fontSize: "36px",
            '@media (max-width:960px)': {
                fontSize: '28px',
            },
            '@media (max-width:650px)': {
                fontSize: '18px',
            }
        },
        h3: {
            fontSize: "24px",
            fontWeight: "bold",
            '@media (max-width:960px)': {
                fontSize: '20px',
            },
            '@media (max-width:650px)': {
                fontSize: '14px',
            }
        },
        h4: {
            fontSize: "20px",
            fontWeight: "normal",
            '@media (max-width:960px)': {
                fontSize: '20px',
            },
            '@media (max-width:650px)': {
                fontSize: '14px',
            }
        },
        h5: {
            fontSize: "18px",
            fontWeight: "normal",
            '@media (max-width:960px)': {
                fontSize: '16px',
            },
            '@media (max-width:650px)': {
                fontSize: '14px',
            }
        },
        h6: {
            fontSize: "16px",
            fontWeight: "normal",
            '@media (max-width:960px)': {
                fontSize: '10px',
            },
            '@media (max-width:650px)': {
                fontSize: '10px',
            }
        },
        button: {
            fontSize: "16px",
            fontWeight: "normal",
            '@media (max-width:960px)': {
                fontSize: '16px',
            },
            '@media (max-width:650px)': {
                fontSize: '14px',
            }
        },
        subtitle2: {
            fontSize: "14px",
            fontWeight: "normal",
            '@media (max-width:960px)': {
                fontSize: '10px',
            },
            '@media (max-width:650px)': {
                fontSize: '10px',
            }
        },
        pdfh1: {
            fontSize: "16px",
            '@media (max-width:960px)': {
                fontSize: '16px',
            },
            '@media (max-width:650px)': {
                fontSize: '16px',
            }
        },
        pdfh2: {
            fontSize: "14px",
            fontWeight: 400,
            '@media (max-width:960px)': {
                fontSize: '14px',
            },
            '@media (max-width:650px)': {
                fontSize: '14px',
            }
        },
        pdfh3: {
            fontSize: "12px",
            fontWeight: 400,
            '@media (max-width:960px)': {
                fontSize: '12px',
            },
            '@media (max-width:650px)': {
                fontSize: '12px',
            }
        }
    }
}
export default typography