import { fetchStockCount } from "../../apis/inventory.api"
import { actions } from "../../utils/constants"
import { callApiAction } from "./commonAction"

export const fetchInventoryCountAction = (data, onSuccess, onError) => {
    return async (dispatch, getState) => {

        dispatch({ type: actions.START_INVENTORY_COUNT_LOADING })

        dispatch(callApiAction(
            async () => await fetchStockCount(data),
            (response) => {                
                dispatch({ type: actions.SET_INVENTORY_COUNT_DATA, value: response })

                onSuccess(response)
            },
            (err) => {

                onError(err)
            }
        ))
    }
}

export const setInventoryCountFilters = (field,value) => {
    return { type: actions.SET_INVENTORY_COUNT_FILTERS, field,value }
}

