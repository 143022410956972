import { Chip, CircularProgress, Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { dateConverter, snake2Pascal } from "../../../utils/helper"
import { center } from "../../../assets/css/theme/common"
import SearchBar from "../../../components/inputs/SearchBar"
import OneViewBox from "../../../components/layouts/OneViewBox"
import { COMPANY_NAME, inventoryStatus, permissions } from "../../../utils/constants"
import RevertButton from "../../../components/button/RevertButton"
import SplitConvertedBoxesButton from "../../../components/button/SplitButton"


const InventoryDetailsUI = ({ listLoading, filters, setFilters, list, callBack }) => {
    const user = useSelector(state => state.user)
    const params = useParams()

    return (
        <>

            <Box p={4} id="box_detail" >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Details of {params.boxId}</Typography>

                        {!listLoading && Object.keys(list).length > 0 && <Box>
                            <RevertButton callBack={callBack} boxId={params.boxId} status={list.currentStatus} />
                            <SplitConvertedBoxesButton callBack={callBack} boxId={params.boxId} convertedProducts={list.convertedProducts} status={list.currentStatus} />
                        </Box>}
                    </Box>

                    {listLoading && <Box sx={{ ...center }}><CircularProgress size={50} /> </Box>}

                    {!listLoading && Object.keys(list).length > 0 &&

                        <>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h4" mb={3}>Product Details</Typography>
                                    <Box sx={{ border: 1, borderRadius: 1 }} p={2}>


                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Box Id</Grid>
                                            <Grid item xs={6}> {params.boxId}</Grid>
                                        </Grid>

                                        {list.currentStatus != inventoryStatus.ALLOCATED && <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Status</Grid>
                                            <Grid item xs={6}> <Chip input color="primary" label={snake2Pascal(list.currentStatus)} ></Chip> </Grid>
                                        </Grid>}
                                        {list.currentStatus == inventoryStatus.CONVERTED && list.convertedInto && <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Coverted Into</Grid>
                                            <Grid item xs={6}> <Link to={"/dashboard/" + user.data.usercode + '/device/' + list.convertedInto.boxId} > {snake2Pascal(list.convertedInto.boxId)}</Link> </Grid>
                                        </Grid>}

                                        {list.currentStatus == inventoryStatus.ALLOCATED && list.allocatedLast && list.allocatedLast.usercode != params.usercode && <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Allocated to</Grid>
                                            <Grid item xs={6}> <Chip color="primary" label={snake2Pascal(list.allocatedLast.name)} ></Chip> </Grid>
                                        </Grid>}

                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Mac Id</Grid>
                                            <Grid item xs={6}> {snake2Pascal(list.macId)}</Grid>
                                        </Grid>

                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Product</Grid>
                                            <Grid item xs={6}> {list.product && list.product.name}</Grid>
                                        </Grid>


                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Color</Grid>
                                            <Grid item xs={6}> {list.product && list.product.color && list.product.color.name}</Grid>
                                        </Grid>

                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Type</Grid>
                                            <Grid item xs={6}> {list.product && list.product.type && list.product.type.name}</Grid>
                                        </Grid>


                                        {/* <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Registered By</Grid>
                                            <Grid item xs={6}> {list.registeredBy && list.registeredBy.name}</Grid>
                                        </Grid> */}

                                        {list.convertedProducts && Array.isArray(list.convertedProducts) && list.convertedProducts.length > 0 && <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Converted From</Grid>
                                            <Grid item xs={6}> {list.convertedProducts && list.convertedProducts.map((id) => <Link key={id} sx={{ mr: 2 }} to={"/dashboard/" + user.data.usercode + '/device/' + id} > | {id}</Link>)}</Grid>
                                        </Grid>}

                                        {list.replacedWith && Array.isArray(list.replacedWith) && list.replacedWith.length > 0 && <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Replaced With</Grid>
                                            <Grid item xs={6}> {list.replacedWith.map((id) => <Link key={id} sx={{ mr: 2 }} to={"/dashboard/" + user.data.usercode + '/device/' + id} > | {id}</Link>)}</Grid>
                                        </Grid>}

                                    </Box>
                                </Grid>
                                {list.sold && list.sold.cname && <Grid item xs={12} md={6}>
                                    <Typography variant="h4" mb={3}>Sales Details</Typography>
                                    <Box sx={{ border: 1, borderRadius: 1 }} p={2}>


                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Customer Name</Grid>
                                            <Grid item xs={6}> {list.sold.cname}</Grid>
                                        </Grid>

                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Customer Phone</Grid>
                                            <Grid item xs={6}> <a href={"tel:" + list.sold.cphone}>{list.sold.cphone}</a> </Grid>
                                        </Grid>


                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Customer Email</Grid>
                                            <Grid item xs={6}> <a href={"tel:" + list.sold.cemail}>{list.sold.cemail}</a> </Grid>
                                        </Grid>

                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Customer Address</Grid>
                                            <Grid item xs={6}> {list.sold.caddress && list.sold.caddress}</Grid>
                                        </Grid>


                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Sale price</Grid>
                                            <Grid item xs={6}> {list.sold.amount && list.sold.amount}</Grid>
                                        </Grid>

                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Sale By</Grid>
                                            <Grid item xs={6}> {list.sold.soldBy ? list.sold.soldBy.name : COMPANY_NAME}</Grid>
                                        </Grid>

                                        <Grid container spacing={2} mb={3}>
                                            <Grid item xs={6}>Sale Channel</Grid>
                                            <Grid item xs={6}> {list.sold.soldWith}</Grid>
                                        </Grid>



                                    </Box>
                                </Grid>}
                                {list.logs && Array.isArray(list.logs) && <Grid item xs={12} md={6}>
                                    <Box>
                                        <Typography variant="h4" mb={3}>Logs</Typography>

                                        {
                                            list.logs.filter((log) => user.permissions.includes(permissions.CRM_MANAGERS) || (log.to && log.to.usercode == user.data.usercode) || (log.from && log.from.usercode == user.data.usercode)).map((log) => (
                                                <Box sx={{ border: 1, my: 1, borderRadius: 1 }} key={log._id} p={2}>
                                                    <Grid container spacing={2} mb={3}>
                                                        <Grid item xs={6}>Invoice Number</Grid>
                                                        <Grid item xs={6}> {log.invoiceId && log.invoiceId.invoiceId}</Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} mb={3}>
                                                        <Grid item xs={6}>From</Grid>
                                                        <Grid item xs={6}> {log.from ? log.from && log.from.usercode == user.data.usercode ? "You" : log.from.name : COMPANY_NAME}</Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} mb={3}>
                                                        <Grid item xs={6}>To</Grid>
                                                        <Grid item xs={6}> {log.to ? log.to && log.to.usercode == user.data.usercode ? "You" : log.to.name : (log.type == inventoryStatus.SOLD && list.sold ? ("Customer: " + list.sold.cname) : COMPANY_NAME)}</Grid>
                                                    </Grid>
                                                    {user.permissions.includes(permissions.CRM_MANAGERS) && <Grid container spacing={2} mb={3}>
                                                        <Grid item xs={6}>Type</Grid>
                                                        <Grid item xs={6}> {log.type}</Grid>
                                                    </Grid>}

                                                    <Grid container spacing={2} mb={3}>
                                                        <Grid item xs={6}>{log.from ? (log.from.usercode == user.data.usercode ? "Sale" : "Purchase ") : (log.to ? "Sale" : "Purchase ")} Amount</Grid>
                                                        <Grid item xs={6}> {log.amount}</Grid>
                                                    </Grid>

                                                    {log.category && <Grid container spacing={2} mb={3}>
                                                        <Grid item xs={6}>{log.from ? log.from.usercode == user.data.usercode ? "Sale" : "Purchase " : log.to ? "Sale " : "Purchase "} Category</Grid>
                                                        <Grid item xs={6}> {log.category && log.category.name}</Grid>
                                                    </Grid>}
                                                    {log.invoiceId && <Grid container spacing={2} mb={3}>
                                                        <Grid item xs={6}>Date</Grid>
                                                        <Grid item xs={6}> {dateConverter({ type: "DD_MM_YYYY", value: log.invoiceId.createdAt })}</Grid>
                                                    </Grid>}

                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Grid>}
                            </Grid>


                        </>

                    }
                    {!listLoading && Object.keys(list).length == 0 && <Typography align="center">No device found with box Id {params.boxId}</Typography>}

                </Paper>
            </Box>

        </>
    )
}
export default InventoryDetailsUI