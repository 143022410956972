
import { styled } from '@mui/material/styles';

import { Container } from '@mui/system';

const CustomContainer = styled(Container)(({ theme }) => ({
    maxWidth: "95%",
    flex:"1",
    flexDirection:"row",
    display: "flex",
    margin:"auto",
    [theme.breakpoints.down('md')]: {
        maxWidth: "100%",
      },

}));

export default CustomContainer
