const mode =  'production' //process.env.REACT_APP_APP_MODE;
let domain = "";
switch (mode) {
  case "local":
    domain = "http://localhost:8000/";
    break;
  case "development":
    domain = "https://wehear-crm-development.up.railway.app/";
    break;
  case "production":
    domain = "https://wehear-crm-production.up.railway.app/";
    break;
  default:
    domain = "http://localhost:8000/";
}

export default {
  signIn: `${domain}api/auth/sign-in`,
  resetToken: `${domain}api/auth/reset-token`,
  resetPassword: `${domain}api/auth/reset-password`,
  userAndSystemFetchByToken: `${domain}api/system/`,
  userBase: `${domain}api/user/`,

  userCount: `${domain}api/user/count`,
  userRoleBase: `${domain}api/userrole/`,

  products: `${domain}api/product/`,
  productColors: `${domain}api/product/color/`,
  productCategorys: `${domain}api/product/category/`,
  productTypes: `${domain}api/product/type/`,

  leadBase: `${domain}api/lead/`,
  leadStatusCount: `${domain}api/lead/count/status`,
  leadTypeCount: `${domain}api/lead/count/type`,
  leadUser: `${domain}api/lead/users`,
  leadForward: `${domain}api/lead/forward`,

  leadTypes: `${domain}api/lead/type/`,
  leadStatuss: `${domain}api/lead/status/`,

  replacementBase: `${domain}api/replacement/`,
  replacementCount: `${domain}api/replacement/count/`,
  replacementByTicketNo: `${domain}api/replacement/ticket-no/`,


  replacementCreateOtp: `${domain}api/replacement/create-otp/`,
  replacementVerifyOtp: `${domain}api/replacement/verify-otp/`,
  replacementCustomerReplace: `${domain}api/replacement/customer-device/`,


  productCategory: `${domain}api/product/category`,
  revertBox: `${domain}api/inventory/revert`,
  splitConvertedtBox: `${domain}api/inventory/split`,
  inventorySummary: `${domain}api/inventory/summary`,
  inventoryChannelSummary: `${domain}api/inventory/channel-summary`,
  productCategoryList: `${domain}api/product//product-category`,
  stockByProductCategory: `${domain}api/inventory/stock-count-bycategory`,
  inventoryBase: `${domain}api/inventory/`,

  checkSoldStatus: `${domain}api/inventory/check-sold`,
  checkReturnableStatus: `${domain}api/inventory/check-returnable`,
  returnReplaceDevice: `${domain}api/inventory/return-device`,
  payInvoice: `${domain}api/inventory/invoice-pay`,
  inventoryTransfer: `${domain}api/inventory/transfer`,
  inventorySell: `${domain}api/inventory/sell`,
  inventoryLifeCount: `${domain}api/inventory/life-stock-count`,
  invoiceHtml: `${domain}api/inventory/invoice-html`,
  inventoryLifeData: `${domain}api/inventory/life-stock-data`,
  salesType: `${domain}api/inventory/sales-type`,
  invoices: `${domain}api/inventory/invoices`,
  inventoryDetails: `${domain}api/inventory/box-details`,
  inventoryCount: `${domain}api/inventory/inventory-count`,


  soldList: `${domain}api/inventory/sales-list`,
  soldInvoices:`${domain}api/inventory/sales-invoices`,
  channelStock: `${domain}api/inventory/channel-stock`,
  channelSales: `${domain}api/inventory/channel-sales`,


  modulePermissions: `${domain}api/module-permissions/`,

};
