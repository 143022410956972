import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from 'react-redux'
import { getUserRolesApi } from "../../apis/user.api"
import SubmitButton from "../../components/button/SubmitButton"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import CustomInput from "../../components/inputs/CustomInput"
import { closeModal } from "../../store/actions/modalAction"

const PasswordResetUI = ({ fields, setFields, loading, onSubmit }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>
        <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" onSubmit={onSubmit} >
            <DialogTitle variant="h3">{'Reset Password'}
                <Box component={'div'} ><Typography variant="h4" color={'red'}>{fields.err} </Typography></Box>
            </DialogTitle>
            <DialogContent>
                <CustomInput
                    disabled={loading}
                    value={fields.current_pass}
                    onChange={(e) => setFields({ ...fields, err: '', current_pass: e.target.value })}
                    type="password"
                    label={"Current Password*"}

                />
                <CustomInput
                    disabled={loading}
                    value={fields.new_pass}
                    onChange={(e) => setFields({ ...fields, err: '', new_pass: e.target.value })}
                    type="password"
                    label={"New Password*"}

                />
            </DialogContent>
            <DialogActions   >
                <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>
                <SubmitButton  disabled={loading}  loading={loading} type="submit" title="RESET" ></SubmitButton>
            </DialogActions>
        </Box>
    </>
}
export default PasswordResetUI