import { useEffect, useState } from "react"
import TicketDetailsUi from "./TicketDetailsUi"
import { getReplacementTicketById } from "../../apis/replacement.api"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"

const TicketDetailsController = ({ ticketNo, onlyTicket, onStart = () => { }, onSuccess = () => { }, onError = () => { } }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    useEffect(() => {
        onStart()
        setLoading(true)
        dispatch(callApiAction(
            async () => await getReplacementTicketById({ ticketNo }),
            (response) => {
                onSuccess(response)
                setData(response)
                setLoading(false)
            },
            (err) => {
                onError(err)
                setLoading(false)
            }
        ))
    }, [ticketNo])
    return <TicketDetailsUi loading={loading} data={data} onlyTicket={onlyTicket} />
}
export default TicketDetailsController