import './../../assets/css/css/authspace.css'
import CustomInput from '../../components/inputs/CustomInput';
import { Box, Grid, Typography } from '@mui/material';
import OneViewBox from '../../components/layouts/OneViewBox';
import BgGradient from '../../components/layouts/auth/BgGradient';
import CustomContainer from '../../components/layouts/CustomContainer';
import AuthLeftContainer from '../../components/layouts/auth/AuthLeftContainer';
import AuthRighttContainer from '../../components/layouts/auth/AuthRightContainer';
import SubmitButton from '../../components/button/SubmitButton';
import GradientText from '../../components/texts/GradientText';
import { center } from '../../assets/css/theme/common';






const SignInUI = ({ state, setState, onSubmit, loading }) => {

    return (

        <>
            <OneViewBox sx={{ overflow: "hidden" }}  >
                <BgGradient left={true} />
                <BgGradient />
                <GradientText variant='display1' sx={{ ...center }} mt={5} mb={5} >WEHEAR DMS</GradientText >
                <CustomContainer sx={{ marginBottom: "100px" }}>
                    <Grid container spacing={2} sx={{ display: "flex" }}>
                        <Grid item xs={12} md={6}>
                            <AuthLeftContainer />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <AuthRighttContainer >
                                <Box component={"form"} width={"100%"} onSubmit={onSubmit}>

                                    <Typography variant="h2" mb={2}>
                                        Log In
                                    </Typography>
                                    <Typography variant="h4" color={"red"} mb={1}>
                                        {state.err}
                                    </Typography>
                                    <Box>
                                        <CustomInput
                                            disabled={loading}
                                            value={state.email}
                                            onChange={(e) => setState({ ...state, err: '', email: e.target.value })}
                                            type="text"
                                            label={"Email"}
                                        />
                                        <CustomInput
                                            disabled={loading}
                                            value={state.password}
                                            onChange={(e) => setState({ ...state, err: '', password: e.target.value })}
                                            type="password"
                                            label={"Password"}
                                        />

                                    </Box>
                                    <Box mt={4}>
                                        <SubmitButton loading={loading} disabled={loading} type='submit' title='Sign In' />
                                    </Box>

                                </Box>
                            </AuthRighttContainer>
                        </Grid>
                    </Grid>




                </CustomContainer>
            </OneViewBox>


        </>
    )
}
export default SignInUI