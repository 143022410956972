import logo from './logo.svg';
import './App.css';
import SignInController from './pages/signin/SignInController';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux'
import NullRoutingComponent from './components/NullRoutingComponent';
import PopUpModal from './components/Modal';
import { accessToken, loggedInUser } from './utils/helper';
import { useDispatch } from 'react-redux'
import { fetchUserDetails } from './store/actions/userReducerAction';
import { memo, useEffect, useMemo, useState } from 'react';
import OneViewBox from './components/layouts/OneViewBox';
import { center } from './assets/css/theme/common';
import { CircularProgress } from '@mui/material';
import HomePage from './pages/dashboard/HomePage';
import UserController from './pages/user/UserController';
import LeadController from './pages/lead/LeadController';
import InventoryMain from './pages/inventory/InventoryMain';
import SoldController from './pages/inventory/sold/SoldController';
import StockController from './pages/inventory/stock/StockController';
import ChannelStockController from './pages/inventory/channel/ChannelStockController';
import ChannelSoldController from './pages/inventory/channelsold/ChannelSoldController';
import InvoicesController from './pages/inventory/invoices/InvoicesController';
import InventoryDetailsController from './pages/inventory/details/InventoryDetailsController';
import ProfileController from './pages/profile/ProfileController';
import LifetimeStockByCatrgoryController from './pages/inventory/lifetimeStockByCategory/LifetimeStockByCatrgoryController';
import SummaryController from './pages/inventory/summary/SummaryController';
import InventoryInnerOutlet from './pages/inventory/InventoryInnerOutlet';
import { invoicePaymentStatus, permissions } from './utils/constants';
import SoldInvoiceCOntroller from './pages/inventory/sold/SoldInvoicesController';
import ReturnReplacementMain from './pages/returnreplacement/ReturnReplacementMain';
import ListController from './pages/returnreplacement/ListController';
function App() {

  const [loading, setLoading] = useState(true)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (accessToken.get()) {
      dispatch(fetchUserDetails(undefined, (res) => { setLoading(false) }, (err) => { setLoading(false) }))
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) {
    return <OneViewBox sx={{ ...center }}><CircularProgress size={50} /> </OneViewBox>
  }
  return (
    <>
      <PopUpModal />
      <Routes  >
        {
          (!user.isLoggedIn || !loggedInUser.get()) && <>
            <Route path='sign-in' element={<SignInController />} />
            <Route path='*' element={<Navigate to={"/sign-in"} />} />
          </>
        }
        {user.isLoggedIn && loggedInUser.get() && <>

          <Route path='' element={<Navigate to={"dashboard/" + loggedInUser.get().usercode} />} />
          <Route path='dashboard/:usercode' errorElement={<h1>OOPS SOmething went wrong</h1>} element={<NullRoutingComponent allowfetchUser={true} />} >
            <Route path='' element={<HomePage />} />
            <Route path='users' element={<UserController />} />
            <Route path='leads' element={<LeadController />} />

            <Route path='inventory' element={<InventoryMain />} >
              <Route path='' element={<SummaryController />} />
              <Route path=':productCategoryId' element={<InventoryInnerOutlet />}>
                <Route path='life-stock' element={<InvoicesController />} />
                <Route path='life-stock-data/:category' element={<LifetimeStockByCatrgoryController />} />
                <Route path='sold' element={<SoldController />} />
                <Route path='paid-sold' element={<SoldInvoiceCOntroller key={invoicePaymentStatus.PAID} paymentStatus={invoicePaymentStatus.PAID} />} />
                <Route path='credit-sold' element={<SoldInvoiceCOntroller key={invoicePaymentStatus.CREDIT} paymentStatus={invoicePaymentStatus.CREDIT} />} />
                <Route path='stock' element={<StockController />} />
                <Route path='replacement-stock' element={<StockController key={"returned"} status="returned" />} />
                <Route path='channel-stock' element={<ChannelStockController />} />
                <Route path='channel-sales' element={<ChannelSoldController />} />
              </Route>
            </Route>

            <Route path='channel-inventory' element={<InventoryMain isChannel={true} />} >

              <Route path='' element={<SummaryController isChannel={true} />} />
              <Route path=':productCategoryId' element={<InventoryInnerOutlet isChannel={true} />}>
                <Route path='channel-stock' element={<ChannelStockController />} />
                <Route path='channel-sales' element={<ChannelSoldController />} />
              </Route>
            </Route>


            <Route path='life-time-inventory' element={<InventoryMain isLifetime={true} />} >

              <Route path='' element={<SummaryController isLifetime={true} />} />
              <Route path=':productCategoryId' element={<InventoryInnerOutlet isLifetime={true} />}>
                <Route path='life-stock' element={<InvoicesController />} />
                <Route path='life-stock-data/:category' element={<LifetimeStockByCatrgoryController />} />
              </Route>
            </Route>

            <Route path='replacement' element={<ReturnReplacementMain isAdmin={user.permissions.includes(permissions.REPLACEMENT_GENERAL)} />} >
              <Route path='' element={<ListController onlyme />} />
              <Route path='my' element={<ListController onlyme />} />
              <Route path='channel' element={<ListController />} />


            </Route>


            <Route path='profile' element={<ProfileController />} />
            <Route path='device/:boxId' element={<InventoryDetailsController />} />
          </Route>
          <Route path='' element={<Navigate to={"dashboard/" + loggedInUser.get().usercode} />} />
        </>
        }

      </Routes >
    </>

  );
}

export default memo(App);
