export const COMPANY_NAME = "WEHEAR Innovation Pvt. Ltd."
export const TAXES = [{
    name: "IGST @ 9%",
    percent: 9
},
{
    name: "CGST @ 9%",
    percent: 9
}]


export const soldCategoriesName = {
    REPLACED: "Replacement",
    RETURNED: "Returned"
}
export const invoicePaymentStatus = {
    PAID: "paid",
    CREDIT: 'credit'
}
export const companyDetails = {
    NAME: "Wehear",
    EMAIL: "info@wehear.in",
    ADDRESS: '805 , Supath ,Vijay Cross Roads , Navrangpura , Ahmedabad',
    GST: "24AACCW8915D1ZE",
    bankName: "Kotak -9090960606",
    bankIFSC: "KKBK0002588",
    bankNo: "9090960606",
    companyName: COMPANY_NAME

}

export const actions = {
    'SIGN_IN': 'SIGN_IN',
    'SET_USER': 'SET_USER',
    SIGN_OUT: "SIGN_OUT",
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',

    SET_LIFE_STOCK_DATA: "SET_LIFE_STOCK_DATA",
    START_LIFE_STOCK_LOADING: 'START_LIFE_STOCK_LOADING',



    SET_PRODUCT_CATEGORY_DATA: "SET_PRODUCT_CATEGORY_DATA",
    START_PRODUCT_CATEGORY_LOADING: 'START_PRODUCT_CATEGORY_LOADING',

    SET_INVENTORY_COUNT_DATA: "SET_INVENTORY_COUNT_DATA",
    SET_INVENTORY_COUNT_FILTERS: "SET_INVENTORY_COUNT_FILTERS",
    START_INVENTORY_COUNT_LOADING: 'START_INVENTORY_COUNT_LOADING',
    SET_INVENTORY_LIST: 'SET_INVENTORY_LIST',
    UPDATE_INVENTORY_LIST: 'UPDATE_INVENTORY_LIST',


    SET_DEVICES_LIST: 'SET_DEVICES_LIST',
    UPDATE_DEVICES_LIST: "UPDATE_DEVICES_LIST",
    REMOVE_DEVICE_LIST: "REMOVE_DEVICE_LIST",
    SET_ERRORS: "SET_ERRORS"

}
export const permissions = {

    ADMIN_PANEL_USERS: "ADMIN_PANEL_USERS",
    ADMIN_USERS: "ADMIN_USERS",
    USER_CREATE: "USER_CREATE",
    USER_DELETE: "USER_DELETE",
    USERS_LISTING: "USERS_LISTING",
    LEAD_MODULE: "LEAD_MODULE",
    STOCK_MODULE: "STOCK_MODULE",
    FINANCE_MODULE: "FINANCE_MODULE",
    CRM_MANAGERS: "CRM_MANAGERS",
    USER_MANGERS: "USER_MANGERS",
    TRANSFER_DEVICES: "TRANSFER_DEVICES",


    REPLACEMENT_GENERAL: "REPLACEMENT_GENERAL",
    REPLACEMENT_APPROVE: "REPLACEMENT_APPROVE",
    REPLACEMENT_MODULE: "REPLACEMENT_MODULE"

}
export const inventoryStatus = {


    NOT_ALLOCATED: 'not_allocated',
    ALLOCATED: 'allocated',
    SOLD: 'sold',
    CONVERTED: 'converted',

    RETURNED: "returned",//when device is returned
    SOLD_WITH_REPLACEMENT: "sold_with_replacement",//when device is given to customer with replacement of defetcted device
}

export const invoiceTypes = {


    SOLD: 'sold',
    TRANSFERED: "transfered",
    REGISTERED: 'registered',
    RETURNED: 'returned',
    REPLACED: 'replaced',
}
export const salesTypes = {
    B2C: 'b2c',
    B2B: 'b2b'
}
export const REPLACEMENT_TICKETS_STATUS = {
    "pending": "Pending",
    'approved_and_pending': "Delivery Pending",
    
    'delivered': "Delivered",
    'delivery_confirmed': "Delivery Confirmed",
    'rejected': "Rejected",
}

export const REPLACEMENT_TICKETS_STATUS_OBJ = {
    "pending": "pending",
    'approved_and_pending': "approved_and_pending",
    
    'delivered': "delivered",
    'delivery_confirmed': "delivery_confirmed",
    'rejected': "rejected",
}