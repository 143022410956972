import { actions } from "../../utils/constants";
import { accessToken, loggedInUser, refreshToken } from "../../utils/helper";


const initialState = {
    data: {},
    permissions: [],
    isLoggedIn: false,

}
const userReducer = (state = initialState, action) => {

    switch (action.type) {

        case actions.SET_USER: return { ...state, data: action.value.currentUser, permissions: action.value.permissions, isLoggedIn: true };
        case actions.SIGN_IN: {
            accessToken.set(action.value.accessToken)
            loggedInUser.set({ usercode: action.value.currentUser.usercode, name: action.value.currentUser.name })
            refreshToken.set(action.value.refreshToken)
            return { ...state, data: action.value.currentUser, permissions: action.value.permissions, isLoggedIn: true };
        }
        case actions.SIGN_OUT: return { ...state, data: {}, permissions: [], isLoggedIn: false };
        default: return { ...state }
    }

}
export default userReducer