import { DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from 'react-redux'
import { getUserRolesApi } from "../../apis/user.api"
import SubmitButton from "../../components/button/SubmitButton"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import CustomInput from "../../components/inputs/CustomInput"
import { closeModal } from "../../store/actions/modalAction"

const UserCreateUI = ({ fields, setFields, loading, onSubmit }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>
        <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" onSubmit={onSubmit} >
            <DialogTitle variant="h3">{'Create User'}
                <Box component={'div'} ><Typography variant="h4" color={'red'}>{fields.err} </Typography></Box>
            </DialogTitle>
            <DialogContent>

                <CustomInput
                    disabled={loading}
                    value={fields.name}
                    onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                    type="text"
                    label={"Name*"}

                />
                <CustomInput
                    disabled={loading}
                    value={fields.email}
                    onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                    type="email"
                    label={"Email*"}

                />
                <CustomInput
                    disabled={loading}
                    value={fields.password}
                    onChange={(e) => setFields({ ...fields, err: '', password: e.target.value })}
                    type="password"
                    label={"Password*"}

                />
                <AsyncDropDown
  OptionComponent={({ option, ...rest }) => {
    return <MenuItem {...rest}>{option.title}</MenuItem>
}}
                    lazyFun={getUserRolesApi}
                    label="Role*"
                    onChange={async (changedVal) => { setFields({ ...fields, err: '', role:changedVal?.rolecode }) }}
                    titleKey={'title'}
                    valueKey={"rolecode"}


                />


                <CustomInput
                    disabled={loading}
                    value={fields.phoneNo}
                    onChange={(e) => setFields({ ...fields, err: '', phoneNo: e.target.value })}
                    type="text"
                    label={"Phone No*"}

                />



                <CustomInput
                    disabled={loading}
                    value={fields.companyName}
                    onChange={(e) => setFields({ ...fields, err: '', companyName: e.target.value })}
                    type="companyName"
                    label={"Company Name"}

                />

                <CustomInput
                    disabled={loading}
                    value={fields.gstNo}
                    onChange={(e) => setFields({ ...fields, err: '', gstNo: e.target.value })}
                    type="gstNo"
                    label={"Gst No."}

                />

                <CustomInput
                    disabled={loading}
                    value={fields.territory}
                    onChange={(e) => setFields({ ...fields, err: '', territory: e.target.value })}
                    type="territory"
                    label={"Territory"}

                />



            </DialogContent>
            <DialogActions   >
                <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>
                <SubmitButton loading={loading}  disabled={loading}   type="submit" title="Create" ></SubmitButton>
            </DialogActions>
        </Box>
    </>
}
export default UserCreateUI