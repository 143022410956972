import { Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { center } from "../../assets/css/theme/common"
import ClickButton from "../../components/button/ClickButton"
import SubmitButton from "../../components/button/SubmitButton"
import SearchBar from "../../components/inputs/SearchBar"

import GrayBgBox from "../../components/layouts/GrayBgBox"
import { permissions } from "../../utils/constants"
import DataTable from "../../components/tables/DataTable"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"


const UserPageUi = ({ listLoading, filters, setFilters, list, roles, loading, onCreateBtnClick, columns }) => {
    const user = useSelector(state => state.user)
    return (
        <>
            <GrayBgBox >

                <Grid container sx={{ justifyContent: "center" }} spacing={{
                    xs: 1,
                    md: 2,
                }}>
                    {loading && [0, 1, 2, 3].map((val) => (<Grid key={val} item xs={6} md={2}>
                        <ClickButton loading={true} title={''} subTitle={"users"} />
                    </Grid>))}

                    {!loading && Array.isArray(roles) && roles.map((val) => (<Grid key={val.role} item xs={6} md={2}>
                        <ClickButton onClick={() => setFilters({ ...filters, role: val.role, pageNo: 1 })} active={filters.role == val.role} title={val.count} subTitle={val.roleName} />
                    </Grid>))}


                </Grid>






            </GrayBgBox>
            <Box p={4} >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Users</Typography>
                        <Box pl={2} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            {/* <SearchBar placeholder={"Search Name | Email | Usercode"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            <AsynSearchBar
                                fullWidth
                                title="Search Name | Email | Usercode"
                                size="large"
                                placeholder={"Search Name | Email | Usercode"}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />
                            {user.permissions.includes(permissions.USER_CREATE) && <Box pl={2}>
                                <SubmitButton onClick={onCreateBtnClick} title={"Create User"} />
                            </Box>}
                        </Box>
                    </Box>

                    <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={listLoading} />
                        

                    {/* <TableContainer >








                        <Table aria-label="collapsible table">

                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell >Usercode</TableCell>
                                    <TableCell >Email</TableCell>
                                    <TableCell >Phone</TableCell>
                                    <TableCell >Role</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listLoading &&

                                    <>
                                        <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>

                                    </>
                                }
                                {!listLoading && list && list.result && list.result.length <= 0 && <TableRow >
                                    <TableCell colSpan={5} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                                </TableRow>}
                                {!listLoading && list && list.result && list.result.map((row, index) => (
                                    <TableRow key={row.usercode}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell ><Link to={'/dashboard/' + row.usercode} >{row.usercode}</Link></TableCell>
                                        <TableCell >{row.email}</TableCell>
                                        <TableCell >{row.phoneNo}</TableCell>
                                        <TableCell >{row.role && row.role.title}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>



                        </Table>


                        {list && list.total && list.total > 0 && <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={list.total}
                            rowsPerPage={parseInt(filters.pageSize)}
                            page={parseInt(filters.pageNo) - 1}
                            onPageChange={(e, page) => { setFilters({ ...filters, 'pageNo': page + 1 }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, 'pageSize': e.target.value }) }}
                        />}
                    </TableContainer> */}
                </Paper>
            </Box>

        </>
    )
}
export default UserPageUi