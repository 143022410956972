import { useCallback, useEffect, useState } from "react"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { getUserApi, getUserCountApi } from "../../apis/user.api"
import { useParams } from "react-router-dom"
import { openModal } from "../../store/actions/modalAction"
import LeadCreateController from "./LeadCreateController"
import { getLeadApi, getLeadStatusCountApi, getLeadTypeApi, getLeadTypeCountApi } from "../../apis/lead.api"
import LeadPageUi from "./LeadPageUI"
import LeadForwardController from "./LeadForwardController"
import LeadChangeStatusController from "./LeadChangeStatusController"


const LeadController = () => {

    const [list, setList] = useState({})
    const [types, setTypes] = useState([

    ])
    const [statuses, setStatuses] = useState([

    ])
    const [loading, setLoading] = useState(false)
    const [statusesLoading, setStatusesLoading] = useState(false)

    const [listLoading, setListLoading] = useState(false)

    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        type: '',
        mainStatus: '',
        status: '',
        source: '',

    })

    const fetchList = () => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await getLeadApi(filters),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
    const fetchStatus = () => {
        setStatusesLoading(true)
        dispatch(callApiAction(
            async () => await getLeadStatusCountApi(filters),
            (response) => {
                let total = 0

                if (response && Array.isArray(response)) {
                    for (let item of response) {
                        total = total + item.count

                    }

                    response.unshift({
                        count: total,
                        _id: '',
                        name: "All"
                    })

                    setStatuses(response)


                }

                setStatusesLoading(false)
            },
            (err) => {
                setStatusesLoading(false)
            }
        ))
    }

    const fetchTypes = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getLeadTypeCountApi(filters),
            (response) => {
                let total = 0

                if (response && Array.isArray(response)) {
                    for (let item of response) {
                        total = total + item.count

                    }

                    response.unshift({
                        count: total,
                        _id: '',
                        name: "All"
                    })

                    setTypes(response)


                }

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const callBack = async () => { await Promise.all([fetchList(), fetchStatus(), fetchTypes()]) }

    const onCreateBtnClick = () => {
        dispatch(openModal({
            component:<LeadCreateController callBack={async () => { await Promise.all([fetchList(), fetchStatus(), fetchTypes()]) }} />,
            size : "sm",
        }))
    }
    const onForward = useCallback((id) => {
        dispatch(openModal({
            component:<LeadForwardController id={id} callBack={async () => { await Promise.all([fetchList(), fetchStatus(), fetchTypes()]) }} />,
            size : "sm",
        }))

    }, [])
    const onStatusChange = useCallback(() => {

        callBack()

    }, [filters])
    useEffect(() => {
        fetchList()
    }, [filters])
    useEffect(() => {

        fetchTypes()
    }, [])
    useEffect(() => {
        fetchStatus();
    }, [filters.type])

    return (
        <>
            <LeadPageUi
                onStatusChange={onStatusChange}
                onForward={onForward}
                onCreateBtnClick={onCreateBtnClick}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                statusesLoading={statusesLoading}
                types={types}
                statuses={statuses}


            />

        </>
    )
}
export default LeadController