import * as React from 'react';

import { alpha, styled } from '@mui/material/styles';
import { IconButton, Input, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'


export const grayInputStyle = ({ theme }) => ({
  maxWidth: 300,
  width: "100%",
  padding: 3,
  alignItems: "center",
  display: "flex",
  background: theme.palette.grey.main,
  '::before': {
    content: "none",

  },
  ":focus": {

    '::before': {
      content: "none",
      borderRadius: theme.shape.borderRadius,
    },


  },
  '.MuiOutlinedInput-root': {
    padding: '0px !important',
  },
  'fieldset': {
    padding: '0px !important',
    border: "none !important"
  },
  ':after': {
    display: 'none',
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  color: theme.palette.grey.light,
  outline: "none",
  borderRadius: theme.shape.borderRadius,

})
const InputStyled = styled(Input)(grayInputStyle);

const TextFieldStyled = styled(TextField)(grayInputStyle);

export const InputComponent = ({ ...props }) => {
  return <InputStyled inputProps={{ style: { paddingLeft: 10 } }} {...props} />;
}
export default function SearchBar({ ...props }) {
  return <InputStyled startAdornment={
    <InputAdornment position="end">

      <SearchIcon sx={{ fill: "gray", marginRight: 1 }} />

    </InputAdornment>
  } inputProps={{ style: { padding: 5 } }} {...props} />;
}
export function DynamicSearchBar({ ...props }) {
  return <TextFieldStyled fullWidth={true} sx={{ maxWidth: "none" }} placeholder="Search by Box Id"  {...props} />;
}

