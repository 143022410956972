import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { center } from "../../../assets/css/theme/common"

const outerboxDisplayStyle = (theme) => (
    {
        p: 2,
        ...center,
        justifyContent:"flex-start",
        borderLeft: 1,
        borderRight: 1,
        borderLeftColor: theme.palette.grey.main,
        borderRightColor: theme.palette.grey.main,
    }
)
const ChannelStockDisplay = ({ title, subTitle, isChannel }) => {
    return <Box sx={outerboxDisplayStyle}>

        <Box sx={{ mr: 1 }}>
            {isChannel ?


                <svg xmlns="http://www.w3.org/2000/svg" width="67" height="68" viewBox="0 0 67 68" fill="none">
                    <path d="M66.5 33.6913C66.5 51.9713 51.724 66.7878 33.5 66.7878C15.276 66.7878 0.5 51.9713 0.5 33.6913C0.5 15.4112 15.276 0.594727 33.5 0.594727C51.724 0.594727 66.5 15.4112 66.5 33.6913Z" stroke="url(#paint0_linear_1206_2409)" />
                    <path d="M46.3411 38.2031C46.3411 43.378 42.1678 47.5634 37.0078 47.5634L38.4078 45.2233" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M46.3411 38.2031C46.3411 43.378 42.1678 47.5634 37.0078 47.5634L38.4078 45.2233" stroke="url(#paint1_linear_1206_2409)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M19.6758 30.1805C19.6758 25.0057 23.8491 20.8203 29.0091 20.8203L27.6091 23.1604" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M19.6758 30.1805C19.6758 25.0057 23.8491 20.8203 29.0091 20.8203L27.6091 23.1604" stroke="url(#paint2_linear_1206_2409)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M34.0781 23.4805L40.5804 27.2489L47.0173 23.4969" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M34.0781 23.4805L40.5804 27.2489L47.0173 23.4969" stroke="url(#paint3_linear_1206_2409)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M40.582 33.9173V27.2324" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M40.582 33.9173V27.2324" stroke="url(#paint4_linear_1206_2409)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M39.0462 19.8109L35.1252 21.9899C34.243 22.4815 33.5078 23.7267 33.5078 24.7425V28.9042C33.5078 29.92 34.2266 31.1652 35.1252 31.6568L39.0462 33.8359C39.8794 34.3111 41.2517 34.3111 42.1012 33.8359L46.0222 31.6568C46.9044 31.1652 47.6396 29.92 47.6396 28.9042V24.7425C47.6396 23.7267 46.9207 22.4815 46.0222 21.9899L42.1012 19.8109C41.268 19.3521 39.8957 19.3521 39.0462 19.8109Z" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M39.0462 19.8109L35.1252 21.9899C34.243 22.4815 33.5078 23.7267 33.5078 24.7425V28.9042C33.5078 29.92 34.2266 31.1652 35.1252 31.6568L39.0462 33.8359C39.8794 34.3111 41.2517 34.3111 42.1012 33.8359L46.0222 31.6568C46.9044 31.1652 47.6396 29.92 47.6396 28.9042V24.7425C47.6396 23.7267 46.9207 22.4815 46.0222 21.9899L42.1012 19.8109C41.268 19.3521 39.8957 19.3521 39.0462 19.8109Z" stroke="url(#paint5_linear_1206_2409)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.9609 38.1973L25.4321 41.9571L31.8707 38.2136" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.9609 38.1973L25.4321 41.9571L31.8707 38.2136" stroke="url(#paint6_linear_1206_2409)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M25.4297 48.6101V41.9404" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M25.4297 48.6101V41.9404" stroke="url(#paint7_linear_1206_2409)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M23.9203 34.5357L20.0083 36.7098C19.1281 37.2002 18.3945 38.4426 18.3945 39.4561V43.6084C18.3945 44.6219 19.1118 45.8643 20.0083 46.3547L23.9203 48.5289C24.7516 49.003 26.1208 49.003 26.9684 48.5289L30.8805 46.3547C31.7607 45.8643 32.4942 44.6219 32.4942 43.6084V39.4561C32.4942 38.4426 31.777 37.2002 30.8805 36.7098L26.9684 34.5357C26.1208 34.078 24.7516 34.078 23.9203 34.5357Z" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M23.9203 34.5357L20.0083 36.7098C19.1281 37.2002 18.3945 38.4426 18.3945 39.4561V43.6084C18.3945 44.6219 19.1118 45.8643 20.0083 46.3547L23.9203 48.5289C24.7516 49.003 26.1208 49.003 26.9684 48.5289L30.8805 46.3547C31.7607 45.8643 32.4942 44.6219 32.4942 43.6084V39.4561C32.4942 38.4426 31.777 37.2002 30.8805 36.7098L26.9684 34.5357C26.1208 34.078 24.7516 34.078 23.9203 34.5357Z" stroke="url(#paint8_linear_1206_2409)" stroke-linecap="round" stroke-linejoin="round" />
                    <defs>
                        <linearGradient id="paint0_linear_1206_2409" x1="-26.5208" y1="-15.3037" x2="106.835" y2="87.4477" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_1206_2409" x1="33.3134" y1="36.0581" x2="51.8903" y2="50.3717" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_1206_2409" x1="15.9813" y1="18.6753" x2="34.5582" y2="32.9889" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_1206_2409" x1="28.9564" y1="22.6169" x2="34.0615" y2="36.162" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_1206_2409" x1="40.1862" y1="25.7005" x2="43.3164" y2="26.0623" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_1206_2409" x1="27.914" y1="16.0922" x2="56.8356" y2="37.5397" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_1206_2409" x1="13.8508" y1="37.3356" x2="18.9443" y2="50.85" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_1206_2409" x1="25.0339" y1="40.412" x2="28.1639" y2="40.7746" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_1206_2409" x1="12.8134" y1="30.8255" x2="41.6694" y2="52.2242" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                    </defs>
                </svg>
                : <svg xmlns="http://www.w3.org/2000/svg" width="67" height="68" viewBox="0 0 67 68" fill="none">
                    <path d="M66.5 33.6913C66.5 51.9713 51.724 66.7878 33.5 66.7878C15.276 66.7878 0.5 51.9713 0.5 33.6913C0.5 15.4112 15.276 0.594727 33.5 0.594727C51.724 0.594727 66.5 15.4112 66.5 33.6913Z" stroke="url(#paint0_linear_1206_2398)" />
                    <path d="M21.2266 28.0957L32.9999 34.9287L44.6932 28.1358" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M21.2266 28.0957L32.9999 34.9287L44.6932 28.1358" stroke="url(#paint1_linear_1206_2398)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M33 47.0432V34.915" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M33 47.0432V34.915" stroke="url(#paint2_linear_1206_2398)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M45.8141 30.4088V37.9773C45.8141 38.0442 45.8142 38.0976 45.8008 38.1644C44.8675 37.3488 43.6675 36.8674 42.3341 36.8674C41.0808 36.8674 39.9208 37.3087 39.0008 38.0441C37.7741 39.0203 37.0008 40.5313 37.0008 42.2161C37.0008 43.219 37.2808 44.1684 37.7741 44.9707C37.8941 45.1847 38.0408 45.3852 38.2008 45.5725L35.7608 46.923C34.2408 47.7788 31.7608 47.7788 30.2408 46.923L23.1208 42.9649C21.5075 42.069 20.1875 39.8226 20.1875 37.9773V30.4088C20.1875 28.5635 21.5075 26.3171 23.1208 25.4212L30.2408 21.4631C31.7608 20.6073 34.2408 20.6073 35.7608 21.4631L42.8808 25.4212C44.4942 26.3171 45.8141 28.5635 45.8141 30.4088Z" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M45.8141 30.4088V37.9773C45.8141 38.0442 45.8142 38.0976 45.8008 38.1644C44.8675 37.3488 43.6675 36.8674 42.3341 36.8674C41.0808 36.8674 39.9208 37.3087 39.0008 38.0441C37.7741 39.0203 37.0008 40.5313 37.0008 42.2161C37.0008 43.219 37.2808 44.1684 37.7741 44.9707C37.8941 45.1847 38.0408 45.3852 38.2008 45.5725L35.7608 46.923C34.2408 47.7788 31.7608 47.7788 30.2408 46.923L23.1208 42.9649C21.5075 42.069 20.1875 39.8226 20.1875 37.9773V30.4088C20.1875 28.5635 21.5075 26.3171 23.1208 25.4212L30.2408 21.4631C31.7608 20.6073 34.2408 20.6073 35.7608 21.4631L42.8808 25.4212C44.4942 26.3171 45.8141 28.5635 45.8141 30.4088Z" stroke="url(#paint3_linear_1206_2398)" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M47.6667 42.2159C47.6667 43.8205 46.96 45.2513 45.8534 46.2274C44.9067 47.0565 43.68 47.5646 42.3333 47.5646C39.3867 47.5646 37 45.171 37 42.2159C37 40.531 37.7733 39.02 39 38.0439C39.92 37.3085 41.08 36.8672 42.3333 36.8672C45.28 36.8672 47.6667 39.2607 47.6667 42.2159Z" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M47.6667 42.2159C47.6667 43.8205 46.96 45.2513 45.8534 46.2274C44.9067 47.0565 43.68 47.5646 42.3333 47.5646C39.3867 47.5646 37 45.171 37 42.2159C37 40.531 37.7733 39.02 39 38.0439C39.92 37.3085 41.08 36.8672 42.3333 36.8672C45.28 36.8672 47.6667 39.2607 47.6667 42.2159Z" stroke="url(#paint4_linear_1206_2398)" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M42.6667 40.5439V42.5497L41 43.5526" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M42.6667 40.5439V42.5497L41 43.5526" stroke="url(#paint5_linear_1206_2398)" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <defs>
                        <linearGradient id="paint0_linear_1206_2398" x1="-26.5208" y1="-15.3037" x2="106.835" y2="87.4477" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_1206_2398" x1="11.9377" y1="26.5298" x2="21.1929" y2="51.0914" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_1206_2398" x1="32.6042" y1="32.1357" x2="35.7634" y2="32.3369" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_1206_2398" x1="10.0436" y1="14.6926" x2="62.5463" y2="53.5684" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_1206_2398" x1="32.7778" y1="34.4157" x2="54.0085" y2="50.7741" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_1206_2398" x1="40.3403" y1="39.8545" x2="44.8083" y2="41.767" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1818" />
                            <stop offset="1" stop-color="#A91674" />
                        </linearGradient>
                    </defs>
                </svg>}
        </Box>
        <Box sx={{ flexDirection: "column" }}>
            <Typography sx={{ width: '100%' }} variant="h2">{title}</Typography>
            <Typography sx={{ width: '100%' }} variant="body1" color="GrayText">{subTitle}</Typography>
        </Box>
    </Box>
}
export default ChannelStockDisplay