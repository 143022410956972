import { DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from 'react-redux'
import { getLeadStatusApi, getLeadTypeApi, getLeadUsersApi } from "../../apis/lead.api"
import { getUserRolesApi } from "../../apis/user.api"
import SubmitButton from "../../components/button/SubmitButton"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import CustomInput from "../../components/inputs/CustomInput"
import { closeModal } from "../../store/actions/modalAction"

const LeadChangeStatusdUI = ({ fields, setFields, loading, onSubmit, defaultVal }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <Box sx={{ width: "100%" }} >

        <AsyncDropDown
            OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.name}</MenuItem>
            }}
            defaultVal={defaultVal}
            disabled={loading}
            lazyFun={getLeadStatusApi}
            label="Status"
            onChange={async (changedVal) => { setFields({ ...fields, err: '', status: changedVal?._id }) }}
            titleKey={'name'}
            valueKey={"_id"}


        />
    </Box>
}
export default LeadChangeStatusdUI