import { actions } from "../../utils/constants";


const initialState = {
    loading: false,
    data: [],
    filters: {
       dateTime:'today'
    }

}
const inventoryCountReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case actions.SET_INVENTORY_COUNT_FILTERS: return { ...state, filters: { [action.field]: action.value } };
        case actions.START_INVENTORY_COUNT_LOADING: return { ...state, loading: true, data: [] };
        case actions.SET_INVENTORY_COUNT_DATA: return { ...state, loading: false, data: action.value };
        default: return { ...state }
    }

}
export default inventoryCountReducer