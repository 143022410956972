import axios from "axios";
import { getHeaders } from "../utils/helper";

import endpoints from "./endpoints";

export const getLeadStatusApi = async params => {
    const callResponse = await axios({
        url: endpoints.leadStatuss,
        method: "get",
        headers: getHeaders(),
        params:{...params,noParent:true},
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getLeadStatusCountApi = async params => {
    const callResponse = await axios({
        url: endpoints.leadStatusCount,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const getLeadTypeCountApi = async params => {
    const callResponse = await axios({
        url: endpoints.leadTypeCount,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getLeadTypeApi = async params => {
    const callResponse = await axios({
        url: endpoints.leadTypes,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const getLeadApi = async params => {
    const callResponse = await axios({
        url: endpoints.leadBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const createLeadApi = async data => {
    const callResponse = await axios({
        url: endpoints.leadBase,
        method: "POST",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateLeadApi = async data => {
    const callResponse = await axios({
        url: endpoints.leadBase,
        method: "PATCH",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const getLeadUsersApi = async params => {
    const callResponse = await axios({
        url: endpoints.leadUser,
        method: "GET",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const forwardLeadsApi = async data => {
    const callResponse = await axios({
        url: endpoints.leadForward,
        method: "PATCH",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
