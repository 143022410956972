import { Button, ButtonGroup, Chip, CircularProgress, Collapse, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { dateConverter } from "../../../utils/helper"
import { center } from "../../../assets/css/theme/common"
import SearchBar from "../../../components/inputs/SearchBar"
import { invoicePaymentStatus, salesTypes } from "../../../utils/constants"
import { Done, Download, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { Fragment, memo, useState } from "react"
import GrayBgBox from "../../../components/layouts/GrayBgBox"
import DataTable from "../../../components/tables/DataTable"
const Row = memo(({ row, onDownloadInvoice, onPaymentbuttonClick, paymentStatus, ...props }) => {

    const [open, setOpen] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const onDownloadBtnClick = async () => {
        await onDownloadInvoice(row._id, row.invoiceId)

    }
    return <Fragment key={row.usercode}>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

           <TableCell >{row.invoiceNo}</TableCell>
                                       <TableCell >{row.date && dateConverter({ value: row.date })}</TableCell>
           

            <TableCell>
                {downloading ? <CircularProgress /> : <Tooltip title="download invoice">
                    <IconButton
                        aria-label="download"
                        size="small"
                        onClick={onDownloadBtnClick}

                    >
                        <Download  />
                    </IconButton>
                </Tooltip>}
            </TableCell>
            <TableCell>
                {paymentStatus == invoicePaymentStatus.CREDIT ?
                    <Button
                        disableElevation
                        onClick={onPaymentbuttonClick}
                        variant="contained"
                        color="primary"
                        aria-label="download"
                        size="small"

                    >

                        Mark&nbsp;As&nbsp;Paid
                    </Button>
                    : <Chip label="PAID" icon={<Done color="light" />} size="small" color="success" />}
            </TableCell>
            <TableCell >

                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>


            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
                    <GrayBgBox sx={{ width: "100%", flexWrap: "wrap" }}>
                        {
                            row.devices && Array.isArray(row.devices) && row.devices.map(device => <Chip key={device.boxId} color="primary" label={device.boxId} style={{ marginRight: 2, marginBottom: 2 }} ></Chip>)
                        }
                    </GrayBgBox>
                </Collapse>
            </TableCell >
        </TableRow>
    </Fragment>
})



const SoldInvoicesUi = ({ listLoading, filters, setFilters, list,columns,  onDownloadInvoice, onPaymentbuttonCLick, paymentStatus }) => {
    const user = useSelector(state => state.user)
    const params = useParams()
    return (
        <>

            <Box pt={4}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Sold Items</Typography>
                        <Box pl={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            {/* <SearchBar title="Search by Box Id & Customer Name" placeholder={"Search by Box Id & Customer Name"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                        </Box>
                    </Box>
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "sflex-end", alignItems: "center" }}>
                        <ButtonGroup variant="outlined" sx={{ margin: 1 }}>
                            <Button color="primary" disableElevation variant={filters.salesType === '' ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, salesType: '' })} >All</Button>
                            <Button color="primary" disableElevation variant={filters.salesType === salesTypes.B2C ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, salesType: salesTypes.B2C })} >B2C</Button>
                            <Button color="primary" disableElevation variant={filters.salesType === salesTypes.B2B ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, salesType: salesTypes.B2B })} >B2B</Button>
                        </ButtonGroup>
                    </Box>
                    {/* <TableContainer >


                        <Table aria-label="collapsible table">

                            <TableHead>
                                <TableRow>




                                    <TableCell >Invoice No.</TableCell>
                                    <TableCell >Sale Date</TableCell>
                                    <TableCell >Download Invoice</TableCell>
                                    <TableCell >Payment Terms</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listLoading &&

                                    <>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>

                                    </>
                                }
                                {!listLoading && list && list.result && list.result.length <= 0 && <TableRow >
                                    <TableCell colSpan={9} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                                </TableRow>}
                                {!listLoading && list && list.result && list.result.length > 0 && list.result.map((row, index) => (
                                    // <TableRow key={row.boxId}>


                                    //     <TableCell >{row.invoiceNo}</TableCell>
                                    //     <TableCell >{row.date && dateConverter({ value: row.date })}</TableCell>

                                    //     <TableCell>

                                    //         <Tooltip title="download invoice">

                                    //             <IconButton
                                    //                 aria-label="download"
                                    //                 size="small"

                                    //             >
                                    //                 <Download onClick={() => {
                                    //                     onDownloadInvoice(row.invoiceId, '')
                                    //                 }} />
                                    //             </IconButton>
                                    //         </Tooltip>
                                    //     </TableCell>


                                    //     <TableCell>
                                    //         {paymentStatus== invoicePaymentStatus.CREDIT ?
                                    //             <Button
                                    //             disableElevation
                                    //                 onClick={() => {
                                    //                     onPaymentbuttonCLick("invoice", row.boxId, row.invoiceId)
                                    //                 }}
                                    //                 variant="contained"
                                    //                 color="primary"
                                    //                 aria-label="download"
                                    //                 size="small"

                                    //             >

                                    //                 Mark&nbsp;As&nbsp;Paid
                                    //             </Button>
                                    //             : <Chip label="PAID" icon={<Done color="light" />} size="small" color="success" />}
                                    //     </TableCell>
                                    // </TableRow>

                                    <Row paymentStatus={paymentStatus} key={row._id} row={row} onDownloadInvoice={() => {
                                        onDownloadInvoice(row.invoiceId, '')
                                    }}
                                        onPaymentbuttonClick={() => { onPaymentbuttonCLick("invoice", row.boxId, row.invoiceId) }}
                                    />
                                ))}
                            </TableBody>



                        </Table>


                        {list && list.total > 0 && <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={list.total}
                            rowsPerPage={parseInt(filters.pageSize)}
                            page={parseInt(filters.pageNo) - 1}
                            onPageChange={(e, page) => { setFilters({ ...filters, 'pageNo': page + 1 }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, 'pageSize': e.target.value }) }}
                        />}
                    </TableContainer> */}
                    <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={listLoading} />
                </Paper>
            </Box>

        </>
    )
}
export default SoldInvoicesUi