import { RecentActors } from "@mui/icons-material"
import { Button, ButtonGroup, Collapse, Grid, IconButton, Paper, Skeleton, Step, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { center } from "../../assets/css/theme/common"
import ClickButton from "../../components/button/ClickButton"
import SubmitButton from "../../components/button/SubmitButton"
import SearchBar from "../../components/inputs/SearchBar"

import GrayBgBox from "../../components/layouts/GrayBgBox"

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getLeadStatusApi } from "../../apis/lead.api"
import LeadChangeStatusController from "./LeadChangeStatusController"

const Row = ({ row, ...props }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [open, setOpen] = useState(false)
    return <Fragment key={row.usercode}>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell >{row.personName}</TableCell>
            <TableCell>{row.leadTitle}</TableCell>
            <TableCell ><a href={"tel:" + row.contactNo} target={"_blank"}>{row.contactNo}</a></TableCell>

            <TableCell >{row.companyName}</TableCell>

            <TableCell >{row.thread && row.thread.from ? (row.thread.from.name) : "Company"}</TableCell>
            <TableCell >{row.status && (row.status.name)}</TableCell>
            <TableCell >

                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>

                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => { props.onForward(row._id) }}
                >
                    <ShortcutIcon />
                </IconButton>
            </TableCell>
        </TableRow>

        <TableRow sx={(theme) => ({ background: theme.palette.grey[200] })}>
            <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }} colSpan={7}>
                <Collapse in={open} timeout="auto" unmountOnExit>

                    <Grid container padding={2} spacing={3}>
                        <Grid item sx={12} md={8}>
                            <Typography variant={'h4'} >Lead Allocation</Typography>

                            <Paper sx={{ padding: 2, marginTop: 2 }} >
                                <Grid container spacing={3} sx={{ flexWrap: "wrap" }}>
                                    <Grid item xs={5}>
                                        <Typography variant={'h6'} >Persona Name : </Typography>

                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant={'h6'} >{row.personName}</Typography>
                                    </Grid>



                                    <Grid item xs={5}>
                                        <Typography variant={'h6'} >Title : </Typography>

                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant={'h6'} >{row.leadTitle}</Typography>
                                    </Grid>



                                    <Grid item xs={5}>
                                        <Typography variant={'h6'} >Contact No. : </Typography>

                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant={'h6'} >{row.contactNo}</Typography>
                                    </Grid>



                                    <Grid item xs={5}>
                                        <Typography variant={'h6'} >Copamny Name : </Typography>

                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant={'h6'} >{row.companyName}</Typography>
                                    </Grid>



                                    <Grid item xs={5}>
                                        <Typography variant={'h6'} >Address : </Typography>

                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant={'h6'} >{row.address}</Typography>
                                    </Grid>


                                    <Grid item xs={5}>
                                        <Typography variant={'h6'} >Assigned By : </Typography>

                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant={'h6'} >{row.thread && Array.isArray(row.thread) && row.thread[0].from ? (row.thread[0].from.name) : "Company"}</Typography>
                                    </Grid>






                                </Grid>
                                {row.thread && Array.isArray(row.thread) && row.thread.map((thread, index) => (
                                    <Box item sx={{ background: 'whitesmoke' }} key={index} mt={3} p={2}>


                                        <Grid container spacing={2}>

                                            <Grid item xs={5}>
                                                <Typography variant={'h6'} >Assigned To : </Typography>

                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography variant={'h6'} >{thread && thread.to ? (thread.to.name) : "Company"}</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant={'h6'} >Remarks : </Typography>

                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography variant={'h6'} >{thread.message}</Typography>
                                            </Grid>
                                        </Grid>

                                    </Box>
                                ))}


                            </Paper>

                            {/* {row.thread.map((step, index) => (
                                    <Step key={step._id}  >
                                        <StepLabel
                                            onClick={() => { setActiveStep(index) }}
                                        >
                                            <Typography variant="body1">From: {step.from ? step.from.name : "Company"}</Typography>

                                        </StepLabel>
                                        <StepContent>
                                            <Typography variant="body1">To: {step.to ? step.to.name : "Company"}</Typography>
                                            <Typography variant="body1">Message:  </Typography>
                                            <Typography variant="subtitle2" pb={3} >{step.message} </Typography>
                                        </StepContent>
                                    </Step>))
                                } */}

                        </Grid>
                        <Grid item sx={12} md={4} >
                            <Box sx={{ borderLeft: 1, paddingLeft: 2, flex: 1, height: "100%" }}>
                                <Typography variant={'h4'} mb={2} >Actions</Typography>

                                <LeadChangeStatusController defaultVal={row.status} id={row._id} callBack={props.onStatusChange} />
                            </Box>


                        </Grid>
                    </Grid>


                </Collapse>
            </TableCell>
        </TableRow>

    </Fragment>
}


const LeadPageUi = ({ listLoading, onStatusChange, filters, setFilters, list, types, statuses, statusesLoading, loading, onCreateBtnClick, onForward }) => {
    const user = useSelector(state => state.user)
    return (
        <>
            <GrayBgBox >

                <Grid container sx={{ justifyContent: "center" }} spacing={{
                    xs: 1,
                    md: 2,
                }}>
                    {loading && [0, 1, 2, 3].map((val) => (<Grid key={val} item xs={6} md={2}>
                        <ClickButton loading={true} title={''} subTitle={""} />
                    </Grid>))}

                    {!loading && Array.isArray(types) && types.map((val) => (<Grid key={val._id} item xs={6} md={2}>
                        <ClickButton onClick={() => setFilters({ ...filters, mainStatus: '', status: '', type: val._id })} active={filters.type == val._id} title={val.count} subTitle={val.name} />
                    </Grid>))}


                </Grid>

            </GrayBgBox>

            <Box p={5}>


                <Grid container sx={{ justifyContent: "center" }} spacing={{
                    xs: 1,
                    md: 2,
                }}>
                    {statusesLoading && [0, 1, 2, 3].map((val) => (<Grid key={val} item xs={6} md={2}>
                        <ClickButton loading={true} title={''} subTitle={""} />
                    </Grid>))}

                    {!statusesLoading && Array.isArray(statuses) && statuses.map((val) => (<Grid key={val._id} item xs={6} md={2}>
                        <ClickButton onClick={() => setFilters({ ...filters, status: Array.isArray(val.subStatus) ? val.subStatus[0]._id : '', mainStatus: val._id })} active={filters.mainStatus == val._id} title={val.count} subTitle={val.name} />
                    </Grid>))}


                </Grid>
            </Box>

            <Box p={4} >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center" }}>
                        <Typography variant="h3" >LEADS</Typography>
                        <Box pl={2} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <SearchBar placeholder={"Search Lead"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                            {<Box pl={2}>
                                <SubmitButton onClick={onCreateBtnClick} title={"Raise Lead"} />
                            </Box>}
                        </Box>
                    </Box>
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center" }} >
                        <ButtonGroup variant="outlined" key={filters.mainStatus} sx={{ margin: 1 }} >
                            {statusesLoading && <Skeleton width={"200px"} />}
                            {!statusesLoading && statuses && Array.isArray(statuses) && filters.mainStatus !== '' && <>

                                {statuses.filter(item => item._id === filters.mainStatus)[0].subStatus.map((subStatus) =>
                                    <Button key={subStatus._id} color="primary" disableElevation variant={filters.status === subStatus._id ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, status: subStatus._id })} >{subStatus.name}</Button>

                                )}
                            </>}
                        </ButtonGroup>
                        <ButtonGroup variant="outlined" sx={{ margin: 1 }}>
                            <Button color="primary" disableElevation variant={filters.source === '' ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, source: '' })} >{'All'}</Button>
                            <Button color="primary" disableElevation variant={filters.source === 'from' ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, source: 'from' })} >Created By me</Button>
                            <Button color="primary" disableElevation variant={filters.source === 'to' ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, source: 'to' })} >Allocated to me</Button>
                        </ButtonGroup>
                    </Box>
                    <TableContainer >








                        <Table aria-label="collapsible table">

                            <TableHead>
                                <TableRow>
                                    <TableCell >Person Name</TableCell>
                                    <TableCell>Lead Title</TableCell>
                                    <TableCell >Contact No.</TableCell>
                                    <TableCell >Copmany Name</TableCell>
                                    <TableCell >Assigned By</TableCell>
                                    <TableCell >Status</TableCell>
                                    <TableCell >Actions</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listLoading && <TableRow ><TableCell colSpan={7}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>}
                                {!listLoading && list && list.result && list.result.length <= 0 && <TableRow >
                                    <TableCell colSpan={7} align="Center" >
                                        <Typography sx={{ ...center }} variant="h3" >No Data</Typography>
                                    </TableCell>
                                </TableRow>}
                                {!listLoading && list && list.result && list.result.map((row, index) => (
                                    <Row row={row} key={row._id} onForward={onForward} onStatusChange={onStatusChange} />
                                ))}
                            </TableBody>



                        </Table>


                        {list &&  list.total > 0 && <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={list.total}
                            rowsPerPage={parseInt(filters.pageSize)}
                            page={parseInt(filters.pageNo) - 1}
                            onPageChange={(e, page) => { setFilters({ ...filters, 'pageNo': page + 1 }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, 'pageSize': e.target.value }) }}
                        />}
                    </TableContainer>
                </Paper>
            </Box>

        </>
    )
}
export default LeadPageUi