import { actions } from "../../utils/constants";


const initialState = {
    loading: false,
    data: [],

}
const lifeTimestockCountReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.START_LIFE_STOCK_LOADING: return { ...state, loading: true, data: [] };
        case actions.SET_LIFE_STOCK_DATA: return { ...state,loading:false,data:action.value };
        default: return { ...state }
    }

}
export default lifeTimestockCountReducer