import { Box, Paper, Typography } from "@mui/material"
import SubmitButton from "../../components/button/SubmitButton"
import GrayBgBox from "../../components/layouts/GrayBgBox"
import ProfileItem from "./ProfileItem"

const ProfileUi = ({ state, setState, onUpdate, fields, onUpdatePasswordCick }) => {
    return <>
        <GrayBgBox p={4} sx={{ flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>

                <Box>
                <SubmitButton onClick={onUpdatePasswordCick} title="Reset Password" ></SubmitButton>
                </Box>
            </Box>

            {
                fields.map((item) => <ProfileItem title={item.title} value={state[item.key]} />)
            }
        </GrayBgBox>
    </>
}
export default ProfileUi