import { Button, Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { center } from "../../../assets/css/theme/common"
import SubmitButton from "../../../components/button/SubmitButton"
import SearchBar from "../../../components/inputs/SearchBar"
import StockByProductCategory from "../../../components/layouts/inventory/StockByProductCategory"
import { permissions } from "../../../utils/constants"
import { removeLastBracketFromName } from "../../../utils/helper"
import DataTable from "../../../components/tables/DataTable"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"


const StockPageUi = ({ columns, listLoading, filters, setFilters, list, onMassSellBtnClick, onFreeTransferBtnClick, onTransferBtnClick, onImportBtnClick, countLoading, counts, fetchList }) => {

    const user = useSelector(state => state.user)
    const params = useParams()
    return (
        <>
            {/* <Box p={4} pl={0} pr={0}>
                <StockByProductCategory loading={countLoading} data={counts} onClick={fetchList} activeId={filters.productCategory} />
            </Box> */}
            <Box p={4} pl={0} pr={0} >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Stock Items</Typography>
                        <Box pl={2} sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column", flex: 1 }}>
                            {/* <SearchBar placeholder={"Search by Box Id"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}

                            <AsynSearchBar

                                fullWidth
                                title="Search by Box Id"
                                size="small"
                                placeholder={"Search by Box Id"}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", flex: "none", justifyContent: "space-between", mt: 2, mb: 2 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box pr={2}>  {user.permissions.includes(permissions.CRM_MANAGERS) && <SubmitButton title="Import" onClick={onImportBtnClick} sx={{ marginRight: 2 }} />}</Box>
                        </Box>
                        <Box sx={{ display: 'flex', minWidth: "50%", flex: "none", justifyContent: "flex-end" }}>
                            <Box sx={{ width: "50%" }} pr={2}>  {user.permissions.includes(permissions.TRANSFER_DEVICES) && <SubmitButton title="Channel Sale" onClick={() => { onTransferBtnClick() }} sx={{ marginRight: 2 }} />}</Box>
                            <Box sx={{ width: "50%" }}> <SubmitButton variant="outlined" title="Retail Sale" onClick={() => { onMassSellBtnClick() }} /></Box>
                        </Box>
                    </Box>

                    <DataTable columns={columns} rows={list?.result ?? []} count={list?.total ?? 0} filters={filters} setFilters={setFilters} loading={listLoading} />

                </Paper>
            </Box>

        </>
    )
}
export default StockPageUi