import { useState } from "react"
import CustomerReplacementFindTicketUi from "./CustomerReplacementFindTicketUi"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { createReplacementTicketOTPApi, replaceCustomerDevice, verifyReplacementTicketOTPApi } from "../../../apis/replacement.api"
import CustomerReplacementOTPUi from "./CustomerReplacementOtpUi"
import { closeModal } from "../../../store/actions/modalAction"
import { DialogContent, DialogTitle, Typography } from "@mui/material"
import CustomerReplaceDeviceUI from "./CustomerReplaceDeviceUi"
import { useParams } from "react-router-dom"

const CustomerReplacementController = () => {
    const params = useParams()
    const [fields, setFields] = useState({
        ticketNo: '',
        devices: '',
        otp: '',
        otp_signature: ''
    })

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [ticketData, setTicketData] = useState({})


    const onOtpSend = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await createReplacementTicketOTPApi({ ticketNo: fields.ticketNo }),
                async (response) => {

                    setLoading(false)
                    setStep(2)

                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                }
            )
        )
    }
    const verifyOtp = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await verifyReplacementTicketOTPApi({ ticketNo: fields.ticketNo, otp: fields.otp }),
                async (response) => {
                    setFields({ ...fields, otp_signature: response.signature })
                    setLoading(false)
                    setStep(3)

                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                }
            )
        )
    }

    const replaceDevice = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await replaceCustomerDevice({ ticketNo: fields.ticketNo, otp_signature: fields.otp_signature, devices: fields.devices, usercode: params.usercode }),
                async (response) => {
                    // setFields({ ...fields })
                    setLoading(false)
                    dispatch(closeModal())

                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                }
            )
        )
    }

    return <>
        <DialogTitle>

            <Typography variant="h3" >  Customer Replacement</Typography>
            <Typography variant="h6" color="error">{fields.err}</Typography>
        </DialogTitle>

        <DialogContent>




            {step == 1 && <CustomerReplacementFindTicketUi
                loading={loading}
                onOtpSend={onOtpSend}
                ticketData={ticketData}
                setTicketData={setTicketData}
                setStep={setStep}
                step={step}
                fields={fields}
                setLoading={setLoading}
                setFields={setFields}
            />}
            {step == 2 && <CustomerReplacementOTPUi
                loading={loading}
                verifyOtp={verifyOtp}
                ticketData={ticketData}
                setTicketData={setTicketData}
                setStep={setStep}
                step={step}
                fields={fields}
                setLoading={setLoading}
                setFields={setFields}

            />}

            {step == 3 && <CustomerReplaceDeviceUI
                loading={loading}
                replaceDevice={replaceDevice}
                ticketData={ticketData}
                setTicketData={setTicketData}
                setStep={setStep}
                step={step}
                fields={fields}
                setLoading={setLoading}
                setFields={setFields}

            />}
        </DialogContent>
    </>
}
export default CustomerReplacementController