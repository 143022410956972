import { Paper, Typography } from "@mui/material"
import { toPascalCase } from "../../utils/helper"

const ProfileItem = ({ title, value }) => {
    if (title && value)
        return <Paper elevation={1} sx={{ p: 3, mt: 2 }}>
            <Typography variant="h3" color={'primary'}  textTransform={"capitalize"}>{(title)}</Typography>
            <Typography variant="h4"  textTransform={"capitalize"}  mt={1}>{(value)}</Typography>
        </Paper>

        return <></>
}
export default ProfileItem