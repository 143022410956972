import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchChannelStockList, fetchInventoryList, fetchSoldList } from "../../../apis/inventory.api"
import { getDateFiltersTime } from "../../../utils/helper"
import ChannelStockPageUi from "./ChannelStockPageUi"
const ChannelStockController = () => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)
    const inventoryCount = useSelector(state => state.inventoryCount)

    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        productCategory:params.productCategoryId

    })

    const fetchList = () => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchChannelStockList({ ...filters, ...inventoryCount.filters, ...getDateFiltersTime(inventoryCount.filters.dateTime) }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
   
   
    useEffect(() => {
        fetchList()
    }, [filters,inventoryCount.filters])
   

    return (
        <>
            <ChannelStockPageUi
                
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                
                list={list}
                 />

        </>
    )
}
export default ChannelStockController