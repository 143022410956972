import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchInvoiceHtml, fetchInvoices } from "../../../apis/inventory.api"
import InvoicePageUi from "./InvoicePageUi"
import jsPDF from 'jspdf';
import html2canvas from "html2canvas"
import { openModal } from "../../../store/actions/modalAction"
import InvoicePreview from "./InvoicePreview"
import { dateConverter } from "../../../utils/helper"
import { Box, IconButton, Tooltip } from "@mui/material"
import { Info } from "@mui/icons-material"
import DeviceDisplayModal from "../../../components/layouts/inventory/DeviceDisplayModal"

const InvoicesController = () => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()
    
    const openDeviceModal = useCallback((invoiceId, devices) => {
        console.log("Modal function called")
        dispatch(openModal({
            title : `Devices(${invoiceId})`,
            component : <DeviceDisplayModal devices={devices}/>
        }))
    },[])
    

    const columns = useMemo(() => [
        { id: 1, fieldName: 'createdAt', label: 'Date', align: "left", sort: true,  renderValue: (params) => dateConverter({value: params.createdAt}) },
        { id: 2, fieldName: 'invoiceId', label: 'Invoice Number', align: "left"},
        { id: 3, fieldName: 'devices', label: 'Quantity', align: "left", renderValue: (params) => params.devices ? params.devices.length : "0" },
        { id: 4, fieldName: 'devices', label: 'Amount', align: "left", renderValue: (params) =>  params.devices && Array.isArray(params.devices) && params.devices.reduce((total, num) => total + num.amount, 0)},
        {
            id : 5,
            fieldName : '',
            label : 'Actions',
            align : 'left',
            minWidth: '250px',
            renderValue: (params, setParams) =>
            <> 
                <Box sx={{ width: "100%", display: "flex" }}>
                    <Tooltip title="View Details">
                    <IconButton onClick={() => openDeviceModal(params.invoiceId, params.devices)}>
                        <Info color='info' />
                    </IconButton>
                    </Tooltip>
                </Box>
            </>
        },
    ], [dispatch]);

    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: ''

    })

    const fetchList = () => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchInvoices({ ...filters }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
    const onDownloadInvoice = useCallback(async (invoiceId, name) => {
        dispatch(openModal({
            component:<InvoicePreview invoiceId={invoiceId} name={name} />,
            size : "md",
        }))
    }, [])


    useEffect(() => {
        fetchList()
    }, [filters])


    return (
        <>
            <InvoicePageUi
                onDownloadInvoice={onDownloadInvoice}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                list={list}
                columns={columns}
            />

        </>
    )
}
export default InvoicesController