import { Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import { center } from "../../../assets/css/theme/common"
import SubmitButton from "../../../components/button/SubmitButton"
import SearchBar from "../../../components/inputs/SearchBar"
import StockByProductCategory from "../../../components/layouts/inventory/StockByProductCategory"
import { permissions } from "../../../utils/constants"
import { Link, useParams } from "react-router-dom"
import { removeLastBracketFromName } from "../../../utils/helper"
import DataTable from "../../../components/tables/DataTable"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"


const LifeTimeStockByCategoryUi = ({ listLoading, filters, setFilters, list, columns,  countsLoading, counts, fetchList, onCallBack }) => {
    const user = useSelector(state => state.user)
    const params = useParams()
    return (
        <>
            {/* <Box p={4}>
                <StockByProductCategory loading={countsLoading} data={counts} onClick={onCallBack} activeId={filters.productCategory} />
            </Box> */}
            <Box pt={4} >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >List of Devices</Typography>
                        <Box pl={2} sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column", flex: 1 }}>
                            {/* <SearchBar placeholder={"Search by Box Id"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            <AsynSearchBar
                                fullWidth
                                title="Search by Box Id"
                                size="medium"
                                placeholder={"Search by Box Id"}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />

                        </Box>
                    </Box>

                    <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={listLoading} />
                
                </Paper>
            </Box>

        </>
    )
}
export default LifeTimeStockByCategoryUi