import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchInventoryDetails } from "../../../apis/inventory.api"


import InventoryDetailsUI from "./InventoryDetailsUI"
const InventoryDetailsController = () => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)


    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        boxId: params.boxId
    })

    const fetchList = () => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchInventoryDetails({ ...filters }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }


    useEffect(() => {
        if (filters.boxId != params.boxId || filters.usercode != params.usercode)
            setFilters({ ...filters, boxId: params.boxId, usercode: params.usercode })
    }, [params])

    useEffect(() => {
        fetchList()
    }, [filters])

    return (
        <>
            <InventoryDetailsUI
                callBack={fetchList}
                listLoading={listLoading}
                list={list}
            />

        </>
    )
}
export default InventoryDetailsController