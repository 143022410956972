
import { Box, Checkbox, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Switch, Table, Typography } from "@mui/material"
import { memo, useEffect } from "react"
import { useDispatch } from "react-redux"
import { fetchInventoryCatgoryList } from "../../../apis/inventory.api"
import { getUserApi } from "../../../apis/user.api"
import SubmitButton from "../../../components/button/SubmitButton"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import CustomInput from "../../../components/inputs/CustomInput"
import { InputComponent } from "../../../components/inputs/SearchBar"
import { updateInventoryListAction } from "../../../store/actions/invenotryListAction"
import { closeModal } from "../../../store/actions/modalAction"
import { invoicePaymentStatus } from "../../../utils/constants"

const TransferBoxesUi = ({ title, onSubmit, isFree, boxes, onSelectRange, fields, setFields, loading, dispatchBoxes }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>

        <DialogTitle variant="h3">{boxes.length != 1 && 'Select Devices to'} {title}
            <Typography variant="h6">{boxes.length != 1 && ('Maximum 500 Devices')} </Typography>
            <Typography variant="h6" color="red">{fields.err} </Typography>
        </DialogTitle>

        <DialogContent sx={{ minHeight: '100px', display: "flex", position: "relative" }}>

            <Box sx={{ minHeight: '100px', width: "100%", display: "flex" }}>
                {boxes.length != 1 &&
                    <Box sx={{ position: "relaive", overflowY: 'scroll' }} >


                        {<Box onSubmit={onSelectRange} component={"form"} sx={{ border: 1, background: "white", zIndex: 1, p: 1, borderRadius: 1, position: "sticky", top: "0px" }}>
                            <Typography variant="h5" mb={2}>Select Devices Range</Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <InputComponent disabled={loading} placeholder={"Start"} value={fields.start} onChange={(e) => { setFields({ ...fields, err: '', start: e.target.value }) }} />
                                &nbsp;-&nbsp;
                                <InputComponent disabled={loading} placeholder={"End"} value={fields.end} onChange={(e) => { setFields({ ...fields, err: '', end: e.target.value }) }} />

                            </Box>
                            <SubmitButton loading={loading} disabled={loading} type="submit" sx={{ mt: 1 }} title="Find Boxes" />
                        </Box>}
                        {<Box sx={{ flex: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mt={1}>
                                <Typography variant="h5">Devices</Typography>
                                <Typography variant="h5">{boxes.length}</Typography>
                            </Box>

                            {boxes.length == 0 && <Typography variant="h6" mt={3} mb={3} textAlign="center">No data</Typography>}
                            {boxes.length > 0 &&

                                <Box>
                                    {
                                        boxes.map((box, index) => (<BoxItem
                                            key={box.boxId}
                                            title={box.boxId}
                                            amount={box.amount}
                                            category={box.category}
                                            selected={box.selected}
                                            dispatchBoxes={dispatchBoxes}
                                        />))
                                    }
                                </Box>
                            }
                        </Box>}
                    </Box>}



                <Box sx={{ border: 1, p: 1, borderRadius: 1, ml: 1, flexDirection: "column", overflowY: "scroll", flex: 1, display: 'flex', maxHeight: "100%" }}>
                    <Typography variant="h5" mb={2}>Details</Typography>
                    <Box sx={{}}>
                        <AsyncDropDown
                            OptionComponent={({ option, ...rest }) => {
                                return <MenuItem {...rest}>{option.name}</MenuItem>
                            }}

                            label="Transfer To*"
                            onChange={async (changedVal) => { setFields({ ...fields, err: '', assignedTo: changedVal?._id }) }}
                            titleKey={'name'}
                            defaultFilters={{
                                assignedTo: true
                            }}
                            valueKey={"_id"}
                            lazyFun={getUserApi} />
                    </Box>
                    {!isFree && <Box sx={{}}
                    >
                        <CustomInput type="number" label="Unit Price(₹)*" value={fields.defaultAmount} onChange={(e) => setFields({ ...fields, defaultAmount: e.target.value })} />
                    </Box>}

                    <Box sx={{}}>
                        <AsyncDropDown

                            OptionComponent={({ option, ...rest }) => {
                                return <MenuItem {...rest}>{option.name}</MenuItem>
                            }}

                            label="Enter Category *"
                            onChange={async (changedVal) => { setFields({ ...fields, err: '', defaultCategory: changedVal?._id }) }}
                            titleKey={'name'}
                            valueKey={"_id"}
                            lazyFun={fetchInventoryCatgoryList} /></Box>

                    {!isFree && <Box mb={1}>
                        <CustomInput type="text" label="Invoice Number*" onChange={(e) => setFields({ ...fields, invoiceId: e.target.value })} />
                    </Box>}

                    {!isFree && <FormControl >
                        <FormLabel id="demo-row-radio-buttons-group-label">Payment Terms:</FormLabel>
                        <RadioGroup
                            row
                            value={fields.paid}
                            onChange={(e) => { setFields({ ...fields, err: '', paid: e.target.value }) }}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value={invoicePaymentStatus.PAID} control={<Radio />} label="Paid" />
                            <FormControlLabel value={invoicePaymentStatus.CREDIT} control={<Radio />} label="Credit" />

                        </RadioGroup>
                    </FormControl>}

                </Box>


            </Box>
        </DialogContent>
        <DialogActions>
            <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>
            <SubmitButton loading={loading} disabled={loading} onClick={onSubmit ? onSubmit : handleClose} title="Transfer" ></SubmitButton>
        </DialogActions>


    </>
}

const BoxItem = memo(({ title, selected, dispatchBoxes }) => {


    return (
        <>
            <Box sx={{ display: 'flex', alignItems: "center" }}>
                <Box> <Checkbox checked={selected} onClick={(e) => { dispatchBoxes(updateInventoryListAction(title, 'selected', e.target.checked)) }} /> </Box>
                <Box sx={{ m: 2 }}>{title}</Box>
            </Box>

        </>
    )

})

export default TransferBoxesUi