import { memo, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import ApplyTicketUi from "./ApplyTicketUi"
import { createReplacementTicketApi } from "../../apis/replacement.api"

const ApplyTicketController = ({ callBack = () => { } }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        devices: '',
        
        remarks: "",
        usercode: params.usercode

    })


    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: fields.remarks,
            field: 'Remarks'
        },
        {
            required: true,
            value: fields.devices,
            field: 'Remarks',
            custom: () => {
                if (fields.devices.length <= 0) {
                    return "Please select devices to be returened."
                }
                return true
            }
        },

    ]), [fields])


    const onSubmit = async (e) => {
        e.preventDefault()

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createReplacementTicketApi(fields),
                    async (response) => {
                        await callBack()
                        setLoading(false)
                        dispatch(closeModal())

                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    return <ApplyTicketUi loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(ApplyTicketController)