import { actions } from "../../utils/constants";

const importDeviceSheetReducer = (state, action) => {

    switch (action.type) {
        case actions.SET_DEVICES_LIST: return action.data;

        case actions.UPDATE_DEVICES_LIST: {

            const { id, field, value } = action

            const temp = [...state]
            let i = 0
            for (let row of temp) {
                if (row.id == id) {
                    temp[i][field] = value
                    break;
                }
                i++
            }

            return temp

        };
        case actions.REMOVE_DEVICE_LIST: {

            const { id } = action

            const temp = [...state]
            let i = 0
            for (let row of temp) {
                if (row.id == id) {
                    temp.splice(i, 1)
                    break;
                }
                i++
            }

            return temp

        };
        case actions.SET_ERRORS: {


            const { errors } = action

            const temp = [...state]
            let i = 0            
            
            for (let row of temp) {

                for (let j = 0; j < errors.length; j++) {

                    const error = errors[j]
                    
                    if (error.value) {
                        if (row.boxId == error.value.boxId) {
                            temp[i]['hasError'] = error.msg
                            
                            break;
                        } else
                            temp[i]['hasError'] = ''
                    }

                }

                i++
            }

            return temp
        }
        default: return { ...state }
    }

}
export default importDeviceSheetReducer