import { Box } from "@mui/material"
import ImageComponent from "../../ImageComponent"
import logo from '../../../assets/images/logo_full.png';
import { center } from "../../../assets/css/theme/common.js";

const outerBoxStyle = (theme) => {

    return {
        display: "flex",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        borderRight: `2px solid ${theme.palette.secondary.main}`,
        [theme.breakpoints.down('md')]: {
            borderRight: `none`,
            width:"50%",
            margin:"auto"
        },
    }
}
const imageStyle =  {
    width: '60%',
    height: 'auto',
    maxHeight: '100%',
    objectFit: 'contain',
    maxWidth: '500px',
}
const AuthLeftContainer = () => {
    return (
        <Box sx={outerBoxStyle}>

            <ImageComponent
                sx={imageStyle}
                 src={logo} alt="" />

        </Box>
    )
}
export default AuthLeftContainer