import { combineReducers } from "@reduxjs/toolkit"
import modalReducer from "./modalReducer";
import userReducer from "./userReducer";
import lifeTimestockCountReducer from "./lifeTimestockCountReducer";
import inventoryCountReducer from "./inventoryCountReducer";
import productCategoryReducer from "./productCategoryReducer";

const rootReducer = combineReducers({
    user: userReducer,
    modal: modalReducer,
    inventoryCount: inventoryCountReducer,
    lifeStockCount: lifeTimestockCountReducer,
    productCategory: productCategoryReducer
})
export default rootReducer;