import { memo, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addUserApi } from "../../apis/user.api"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import PasswordResetUI from "./PasswordResetUi"
import { updateUserPassword } from "../../apis/auth.api"

const PasswordResetController = ({ callBack = () => { } }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        current_pass: '',
        new_pass: '',
        usercode: params.usercode

    })


    const validationSchema = useMemo(() => ([

        {
            required: true,
            value: fields.current_pass,
            field: 'Current Password',
        },
        {
            required: true,
            value: fields.new_pass,
            field: 'New Password',
        }
    ]), [fields])


    const onSubmit = async (e) => {
        e.preventDefault()

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateUserPassword(fields),
                    async (response) => {

                        await callBack()
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    return <PasswordResetUI loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(PasswordResetController)