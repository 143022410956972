import { tableCellClasses } from '@mui/material'
import { createTheme } from '@mui/material'
import breakPoint from './breakpoint'
import colorTheme from './colorTheme'
import typography from './typography'

const theme = createTheme({
  ...breakPoint,
  ...colorTheme,
  ...typography,
  shape: {
    borderRadius: 6
  },
  
  shadows: ['0px -20px 65px 1px rgba(230, 32, 75, 0.4)', '0px 1px 16px -5px rgba(0, 0, 0, 0.3)', '0px 1px 16px -5px rgba(0, 0, 0, 0.3)', '0px -20px 65px 1px rgba(230, 32, 75, 0.4)', '0px -20px 65px 1px rgba(230, 32, 75, 0.4)', '0px -20px 65px 1px rgba(230, 32, 75, 0.4)', '0px -20px 65px 1px rgba(230, 32, 75, 0.4)'],
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#C64095'
        },
        sticky: {
          backgroundColor: '#C64095'
        },
        tableCell: {

          backgroundColor: '#000'
        }
      }

    },
    MuiTableCell: {
      styleOverrides: {
        root: {

          MuiTableCell: {
            color: 'white',

          },
          [`&.${tableCellClasses.head}`]: {

            backgroundColor: '#C64095',
            color: 'white',

          }
        }
      }
    }
  }
})
export default theme
