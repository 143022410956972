import * as React from 'react';
import gradLeft from '../../../assets/images/auth-left.png';
import gradRight from '../../../assets/images/auth-right.png';
import ImageComponent from '../../ImageComponent';

const BgGradient = ({ className, ...props }) => {
    if (props.left)
        return <div className="auth-gradient-left">
            <ImageComponent src={gradLeft} className="fit-content" alt="" />
        </div>

    return <div className="auth-gradient-right">
        <ImageComponent src={gradRight} className="fit-content" alt="" />
    </div>
}

export default BgGradient