import { Box, Grid } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { fetchLifeTimeStockCountAction } from "../../../store/actions/lifeTimeStockCountAction"

import ClickButton from "../../button/ClickButton"
import CustomContainer from "../CustomContainer"

const LifeTimeStockCount = () => {
    const { loading, data } = useSelector(state => state.lifeStockCount)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    useEffect(() => {
        dispatch(fetchLifeTimeStockCountAction({ productCategory:params.productCategoryId,usercode: params.usercode }, () => { }, () => { }))
    }, [params.usercode,params.productCategoryId,])

    return (
<Box pt={2} pb={2}>


        <Grid container sx={{ justifyContent: "center" }} spacing={{
            xs: 1,
            md: 2,
        }}>
            {loading && [0, 1, 2, 3].map((val) => (<Grid key={val} item xs={6} md={3}>
                <ClickButton loading={true} title={''} subTitle={""} />
            </Grid>))}

            {!loading && Array.isArray(data) && data.map((val) => (
                <Grid key={val.role} item xs={6} md={12 / data.length}>
                    <ClickButton
                        disabled={!val.redirect}
                        onClick={() => {
                            if (val.redirect) {
                                navigate(val.redirect)
                            }
                        }}

                        active={val.activeKey ? (location.pathname.split('/')[location.pathname.split('/').length - 1] == val.activeKey) : false}
                        title={val.count} subTitle={val.name} />
                </Grid>))}


        </Grid>
        </Box>
    )
}
export default LifeTimeStockCount