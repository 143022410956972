import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchInventoryList, fetchSoldList, fetchStockCountByProductCategory } from "../../../apis/inventory.api"
import { getDateFiltersTime } from "../../../utils/helper"
import StockPageUi from "./StockPageUi"
import { openModal } from "../../../store/actions/modalAction"
import TransferBoxesController from "../common/TransferBoxesController"
import SellBoxesController from "../common/SellBoxesController"
import ImportDevicesController from "../importDevices/ImportDevicesController"
import { permissions } from "../../../utils/constants"
import SubmitButton from "../../../components/button/SubmitButton"
import { Box } from "@mui/material"
const ActionComponent = ({ params, setParams, status, onTransferBtnClick, onMassSellBtnClick, onFreeTransferBtnClick }) => {
    const { user } = useSelector(state => state)
    return <Box sx={{ display: 'flex' }}>
        {status == 'sellable' ? <Box sx={{ display: "flex" }}>
            {user.permissions.includes(permissions.TRANSFER_DEVICES) && <Box>
                <SubmitButton sx={(theme) => ({ fontSize: theme.typography.caption.fontSize })} title={"Channel Sale"} onClick={() => { onTransferBtnClick([{ boxId: params.boxId, selected: true }], "sm") }} />
            </Box>}

            <Box sx={{ mx:1  }}>
                <SubmitButton sx={(theme) => ({ fontSize: theme.typography.caption.fontSize })} title={"Retail sale"} onClick={() => { onMassSellBtnClick([{ boxId: params.boxId, selected: true }], "sm") }} />
            </Box>
            {user.permissions.includes(permissions.TRANSFER_DEVICES) && <Box>
                <SubmitButton sx={(theme) => ({ fontSize: theme.typography.caption.fontSize })} title={"Free Sale"} onClick={() => { onFreeTransferBtnClick([{ boxId: params.boxId, selected: true }], "sm") }} />
            </Box>}
        </Box> : "NA"}
    </Box>
}
const StockController = ({ status }) => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)

    const [counts, setCount] = useState([])
    const [countLoading, setCountLoading] = useState(false)

    const inventoryCount = useSelector(state => state.inventoryCount)

    const dispatch = useDispatch()
    const params = useParams()




    const onMassSellBtnClick = useCallback((defaultData = []) => {
        dispatch(openModal({
           component: <SellBoxesController defaultData={defaultData} callBack={() => { fetchStockDetailsAndData() }} title="Sell" />, 
           size : "md",}))
    }, [])

    const onTransferBtnClick = useCallback((defaultData = [] ) => {
        dispatch(openModal({
            component :<TransferBoxesController defaultData={defaultData} callBack={() => { fetchStockDetailsAndData() }} title="Transfer" />, 
            size:"md"}))
    }, [])
    const onFreeTransferBtnClick = useCallback((defaultData = [],) => {
        dispatch(openModal({
            component :<SellBoxesController isFree defaultData={defaultData} callBack={() => { fetchStockDetailsAndData() }} title="Free Sale" />, 
            size : "md"}))
    }, [])

    const onImportBtnClick = useCallback(() => {
        dispatch(openModal({
            component :<ImportDevicesController callBack={() => { fetchStockDetailsAndData() }} />, 
            size :'lg'}))
    }, [])

 


    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        productCategory: params.productCategoryId,
        status: status ?? "sellable"

    })
    const columns = useMemo(() => [



        { id: 2, fieldName: 'boxId', label: 'Box Id', align: "left", minWidth: '150px' },
        { id: 3, fieldName: 'product.name', label: 'Product', align: "left", renderValue: (params) => params?.product?.name },
        { id: 4, fieldName: 'product.color', label: 'Color', align: "left", renderValue: (params) => params?.product?.color?.name },
        { id: 5, fieldName: 'product.type', label: 'Size', align: "left", renderValue: (params) => params?.product?.type?.name },
        { id: 6, fieldName: '', label: 'Action', align: "left",minWidth: '300px', renderValue: (params) => <ActionComponent status={filters.status} onFreeTransferBtnClick={onFreeTransferBtnClick} onTransferBtnClick={onTransferBtnClick} onMassSellBtnClick={onMassSellBtnClick} params={params} /> },


    ], [dispatch, onMassSellBtnClick, onTransferBtnClick, onFreeTransferBtnClick, filters.status]);
    const fetchList = () => {

        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchInventoryList({ ...filters, ...inventoryCount.filters, ...getDateFiltersTime(inventoryCount.filters.dateTime) }),
            (response) => {

                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
    const fetchStockCount = () => {
        setCountLoading(true)

        dispatch(callApiAction(
            async () => await fetchStockCountByProductCategory({ ...filters }),
            (response) => {
                const temp = [...response]
                if (response && Array.isArray(response)) {
                    let total = temp.reduce((totalCount, item) => (totalCount + parseInt(item.count)), 0)
                    temp.unshift({
                        _id: '',
                        name: "All",
                        count: total
                    })
                }
                setCount(temp)
                setCountLoading(false)
            },
            (err) => {
                setCountLoading(false)
            }
        ))
    }
    const fetchStockDetailsAndData = () => {
        // fetchStockCount()
        fetchList()

    }


    useEffect(() => {
        fetchList()
    }, [filters, inventoryCount.filters])

    useEffect(() => {
        // fetchStockCount()
    }, [])
    return (
        <>
            <StockPageUi
                columns={columns}
                onFreeTransferBtnClick={onFreeTransferBtnClick}
                countLoading={countLoading}
                counts={counts}
                onImportBtnClick={onImportBtnClick}
                onTransferBtnClick={onTransferBtnClick}
                onMassSellBtnClick={onMassSellBtnClick}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                fetchList={(productCategory) => {
                    setFilters({ ...filters, productCategory })
                }}
                list={list}
            />

        </>
    )
}
export default StockController