import { Box, Grid, Paper, Typography } from "@mui/material"
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate'
import InventoryIcon from '@mui/icons-material/Inventory'
import ClickButton from "../../components/button/ClickButton"
import AdsImage from '../../assets/images/ads.jpg'
import GrayBgBox from "../../components/layouts/GrayBgBox"
import CustomContainer from "../../components/layouts/CustomContainer"
import ImageComponent from "../../components/ImageComponent"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { permissions } from "../../utils/constants"
import { openModal } from "../../store/actions/modalAction"
import MessageDilog from "../../components/MessageDilog"
import { AirplaneTicket, BackupTable, Loop } from "@mui/icons-material"
import AllInboxIcon from '@mui/icons-material/AllInbox';
import SellBoxesController from "../inventory/common/SellBoxesController"
import TransferBoxesController from "../inventory/common/TransferBoxesController"
import { useCallback } from "react"
import SubmitButton from "../../components/button/SubmitButton"
import ApplyTicketController from "../returnreplacement/ApplyTicketController"
import CustomerReplacementController from "../returnreplacement/customer/CustomerReplacementController"

const HomePage = () => {
    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const onMassSellBtnClick = useCallback((isFree) => {
        dispatch(openModal({
            component: <SellBoxesController isFree={isFree} defaultData={[]} callBack={() => { }} title="Sell" />,
            size : "md",
        }))
    },[])

    const onTransferBtnClick = useCallback((isFree) => {
        dispatch(openModal({
            component: <TransferBoxesController isFree={isFree} defaultData={[]} callBack={() => { }} title="Transfer" />,
            size : "md",
        }))
    },[])

    const onCustomerReplacementClick = useCallback(() => {
        dispatch(openModal({
            component: <CustomerReplacementController />,
            size : "sm",
        }))
    },[])

    const onReplacementTicketClick = useCallback((isFree) => {
        dispatch(openModal({
            component:<ApplyTicketController  />,
            size : "md",
        }))
    },[])
    
    return (
        <>

            <GrayBgBox >

                <Grid container spacing={{
                    xs: 1,
                    md: 3,
                }}>
                    {user.permissions.includes(permissions.USERS_LISTING) && <Grid item xs={6}>
                        <ClickButton onClick={() => navigate('users')} title={<ManageAccountsIcon fontSize="inherit" />} subTitle={"Users"} />
                    </Grid>}
                    {user.permissions.includes(permissions.STOCK_MODULE) && <Grid item xs={6}>
                        <ClickButton onClick={() => navigate('inventory')} title={<InventoryIcon fontSize="inherit" />} subTitle={"Stock"} />
                    </Grid>}
                    {user.permissions.includes(permissions.STOCK_MODULE) && <Grid item xs={6}>
                        <ClickButton onClick={() => navigate('life-time-inventory')} title={<AllInboxIcon fontSize="inherit" />} subTitle={"Life Time"} />
                    </Grid>}
                    {user.permissions.includes(permissions.TRANSFER_DEVICES) && <Grid item xs={6}>
                        <ClickButton onClick={() => navigate('channel-inventory')} title={<BackupTable fontSize="inherit" />} subTitle={"Channel STock"} />
                    </Grid>}
                    {user.permissions.includes(permissions.LEAD_MODULE) && <Grid item xs={6}>
                        <ClickButton onClick={() => navigate('leads')} title={<AssignmentLateIcon fontSize="inherit" />} subTitle={"LEADS"} />
                    </Grid>}
                    {user.permissions.includes(permissions.FINANCE_MODULE) && <Grid item xs={6}>
                        <ClickButton onClick={() => dispatch(openModal(<MessageDilog title="Coming soon..." message={"This feature is under development , it will be launched soon"} />, "sm"))} title={<RequestQuoteIcon fontSize="inherit" />} subTitle={"Finance"} />
                    </Grid>}

                    {user.permissions.includes(permissions.REPLACEMENT_MODULE) && <Grid item xs={6}>
                        <ClickButton onClick={() => { navigate('replacement/my') }} title={<Loop fontSize="inherit" />} subTitle={"Return & Replacement"} />
                    </Grid>}
                </Grid>
            </GrayBgBox>

            <CustomContainer sx={{ marginTop: 5, marginBottom: 5, borderRadius: 4, overflow: "hidden" }} >
                <CustomContainer sx={{ marginTop: 5, marginBottom: 5, overflow: "hidden", flexDirection: "column" }} >
                   {user.permissions.includes(permissions.STOCK_MODULE) && <Box mb={5} sx={{ display: 'flex', flex: "none", justifyContent: "spce-between" }}>
                        {user.permissions.includes(permissions.TRANSFER_DEVICES) && <Box sx={{ display: "flex", flex: 1 }} >  {<SubmitButton title="Channel Sale" onClick={() => { onTransferBtnClick() }} sx={{ marginRight: 2 }} />}</Box>}
                        <Box sx={{ display: "flex", flex: 1 }} pr={2} > <SubmitButton variant="outlined" title="Free Sale" onClick={() => { onMassSellBtnClick(true) }} /></Box>
                        <Box sx={{ display: "flex", flex: 1 }}> <SubmitButton variant="outlined" title="Retail Sale" onClick={() => { onMassSellBtnClick() }} /></Box>

                    </Box>}

                    {user.permissions.includes(permissions.REPLACEMENT_MODULE) && <Box mb={5} sx={{ display: 'flex', flex: "none", justifyContent: "spce-between" }}>
                        {<Box sx={{ display: "flex", flex: 1 }} >
                            <SubmitButton title="Customer Replacement" onClick={() => { onCustomerReplacementClick() }} sx={{ marginRight: 2 }} />
                        </Box>}
                       {!user.permissions.includes(permissions.REPLACEMENT_GENERAL) && <Box sx={{ display: "flex", flex: 1 }}  >
                            <SubmitButton variant="outlined" title="Apply Replacement" onClick={() => { onReplacementTicketClick() }} />
                        </Box>}


                    </Box>}
                    <ImageComponent sx={{ width: "100%", borderRadius: "8px", userSelect: "none", pointerEvents: "none" }} src={AdsImage} />
                </CustomContainer>
            </CustomContainer>
        </>
    )

}
export default HomePage