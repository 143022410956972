

export const accessToken = {
  set: val => {
    localStorage.setItem("wehear_access_token", val);
  },
  get: () => localStorage.getItem("wehear_access_token"),
  remove: () => {
    localStorage.removeItem("wehear_access_token");
  },
};
export const loggedInUser = {
  set: val => {
    localStorage.setItem("wehear_user", JSON.stringify(val));

  },
  get: () => localStorage.getItem("wehear_user") ? JSON.parse(localStorage.getItem("wehear_user")) : null,
  remove: () => {
    localStorage.removeItem("wehear_user");
  },
};

export const refreshToken = {
  set: val => {
    localStorage.setItem("wehear_refresh_token", val);
  },
  get: () => localStorage.getItem("wehear_refresh_token"),
  remove: () => {
    localStorage.removeItem("wehear_refresh_token");
  },
};
export const logOut = () => {
  refreshToken.remove();
  accessToken.remove();
  loggedInUser.remove()

};


export const getHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;
  return {
    Authorization: token,
  };
};
export function setZeroPrefix(val) {
  if (parseInt(val, 10) < 10) {
    return `0${val}`;
  }
  return val;
}

export const dateConverter = ({ type = "DD_MM_YYYY", value }) => {
  if (type === "DD_MM_YYYY" && value) {
    const dateObj = new Date(value);
    return `${setZeroPrefix(dateObj.getDate())}/${setZeroPrefix(
      dateObj.getMonth() + 1
    )}/${dateObj.getFullYear()}`;
  }
  return value;
};

export const validateEmail = (email) => {
  var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
}
export const validatePhone = (phone) => {
  var re = /^\d{10}$/;
  return phone.match(re);
}
export const allowedDateFilters = [
  {
    id: 'today',
    label: "Today",
  },
  {
    id: 'yesterday',
    label: "Yesterday",
  },
  {
    id: 'last_week',
    label: "Last Week",
  },
  {
    id: 'last_month',
    label: "Last Month",
  },
  {
    id: 'last_year',
    label: "Last Year",
  },
  {
    id: 'this_week',
    label: "This Week",
  },

  {
    id: 'this_month',
    label: "This Month",
  },

  {
    id: 'this_year',
    label: "This Year",
  }
]
export const getDateFiltersTime = value => {
  let date = new Date()
  let startDate = date.getTime()
  let endDate = date.getTime()

  switch (value) {
    case 'this_week':
      {
        const currentDay = date.getDay()
        startDate = startDate - currentDay * 3600 * 1000 * 24
        const temp = new Date(startDate)
        startDate = new Date(
          temp.getFullYear(),
          temp.getMonth(),
          temp.getDate()
        ).getTime()
      }
      break
    case 'this_month':
      {
        const temp = new Date(startDate)
        startDate = new Date(temp.getFullYear(), temp.getMonth()).getTime()
      }
      break
    case 'this_year':
      {
        const temp = new Date(startDate)
        startDate = new Date(temp.getFullYear()).getTime()
      }
      break

    case 'last_month':
      {
        const temp = new Date(startDate)
        startDate = new Date(temp.getFullYear(), temp.getMonth() - 1).getTime()
        endDate = new Date(temp.getFullYear(), temp.getMonth()).getTime()
      }
      break
    case 'last_year':
      {
        const temp = new Date(startDate)
        startDate = new Date(temp.getFullYear() - 1).getTime()
        endDate = new Date(temp.getFullYear()).getTime()
      }
      break
    case 'last_week':
      {
        const currentDay = date.getDay()
        endDate = endDate - currentDay * 3600 * 1000 * 24
        startDate = endDate - 7 * 3600 * 1000 * 24
        const temp = new Date(endDate)
        const tempStart = new Date(startDate)
        endDate = new Date(
          temp.getFullYear(),
          temp.getMonth(),
          temp.getDate()
        ).getTime()
        startDate = new Date(
          tempStart.getFullYear(),
          tempStart.getMonth(),
          tempStart.getDate()
        ).getTime()
      }
      break
    case 'today': {
      startDate = new Date(startDate)

      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      ).getTime()

      endDate = startDate + 1000 * 60 * 60 * 24
    }
      break
    case 'yesterday': {
      startDate = startDate - 1000 * 60 * 60 * 24
      startDate = new Date(startDate)
      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      ).getTime()
      endDate = new Date(endDate)
      endDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
      ).getTime()
    }
      break
    default: {
      const noOfDays = parseInt(value)

      // startDate = startDate - noOfDays * 3600 * 1000 * 24
      // const temp = new Date(startDate)
      // startDate = new Date(
      //   temp.getFullYear(),
      //   temp.getMonth(),
      //   temp.getDate()
      // ).getTime()
      startDate = ''
      endDate = ''
    }
  }

  return {
    startDate,
    endDate
  }
}

export const snake2Pascal = ( str )=>{
  str +='';
  str = str.split('_');

  function upper( str ){
      return str.slice(0,1).toUpperCase() + str.slice(1,str.length);
  }


  for(var i=0;i<str.length;i++){
      var str2 = str[i].split('/');
      for(var j=0;j<str2.length;j++){
          str2[j] = upper(str2[j]);
      }
      str[i] = str2.join('');
  }
  return str.join(' ');
}
export const removeLastBracketFromName = (str='')=>{
  if(str){
    const firstIndex = str.indexOf('(')
    const lastIndex = str.indexOf(')')
    
    if(firstIndex!=-1 && lastIndex!=-1)
     return  str.slice(0,firstIndex)
     else return str
  }
  return "NA"
}