import { actions } from "../../utils/constants";
function removeDuplicates(arr) {
    var unique = [];
    if (arr[0]) {
        unique.push(arr[0])
    }
    
    for (let i = 1; i < arr.length; i++) {
        let exist = false
        for (let j = 0; j < unique.length; j++) {
            if (unique[j].boxId == arr[i].boxId) {
                exist = true
                break
            }

        }
        if (!exist)
            unique.push(arr[i])
    }
    unique.sort()
    return unique.sort((a, b) => a - b);
}
const InventoryListReducer = (state = [], action) => {

    switch (action.type) {
        case actions.SET_INVENTORY_LIST: {

            const old = [...state]
            const newArr = old.concat(action.data)

            const finalArray = removeDuplicates([...newArr])



            return finalArray
        };

        case actions.UPDATE_INVENTORY_LIST: {

            const { boxId, field, value } = action

            const temp = [...state]
            let i = 0
            for (let row of temp) {
                if (row.boxId == boxId) {
                    temp[i][field] = value
                    break;
                }
                i++
            }

            return temp

        };

        default: return { ...state }
    }

}
export default InventoryListReducer