import { Button, ButtonGroup, Chip, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { dateConverter } from "../../../utils/helper"
import { center } from "../../../assets/css/theme/common"
import SearchBar from "../../../components/inputs/SearchBar"
import { invoicePaymentStatus, salesTypes } from "../../../utils/constants"
import { Done, Download } from "@mui/icons-material"
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import DataTable from "../../../components/tables/DataTable"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"

const SoldPageUi = ({ listLoading, filters, setFilters, list, columns,  onDownloadInvoice, onPaymentbuttonCLick }) => {
    const user = useSelector(state => state.user)
    const params = useParams()
    return (
        <>

            <Box pt={4}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Sold Items</Typography>
                        <Box pl={2} sx={{ display: "flex", justifyContent: "flex-end",width:"85%" }}>
                            {/* <SearchBar title="Search by Box Id & Customer Name" placeholder={"Search by Box Id & Customer Name"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            <AsynSearchBar
                                fullWidth
                                title="Search by Box Id & Customer Name"
                                size="large"
                                placeholder={"Search by Box Id & Customer Name"}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />
                        </Box>
                    </Box>
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "sflex-end", alignItems: "center" }}>
                        <ButtonGroup variant="outlined" sx={{ margin: 1 }}>
                            <Button color="primary" disableElevation variant={filters.salesType === '' ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, salesType: '' })} >All</Button>
                            <Button color="primary" disableElevation variant={filters.salesType === salesTypes.B2C ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, salesType: salesTypes.B2C })} >B2C</Button>
                            <Button color="primary" disableElevation variant={filters.salesType === salesTypes.B2B ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, salesType: salesTypes.B2B })} >B2B</Button>
                        </ButtonGroup>
                    </Box>
                    <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={listLoading} />
                   
                </Paper>
            </Box>

        </>
    )
}
export default SoldPageUi
            {/* <TableCell >Sold At</TableCell> */}
            {/* <TableCell >Download Invoice</TableCell> */}
            {/* <TableCell >Payment Terms</TableCell> */}
                {/* <TableCell   >{row.customer && row.customer.soldWith}</TableCell> */}
                {/* <TableCell>

                    <Tooltip title="download invoice">

                        <IconButton
                            aria-label="download"
                            size="small"

                        >
                            <Download onClick={() => {
                                onDownloadInvoice(row.invoiceId, '')
                            }} />
                        </IconButton>
                    </Tooltip>
                </TableCell> */}


                {/* <TableCell>
                    {row.paid && row.paid == invoicePaymentStatus.CREDIT ?
                        <Button
                        disableElevation
                            onClick={() => {
                                onPaymentbuttonCLick("single", row.boxId, row.invoiceId)
                            }}
                            variant="contained"
                            color="primary"
                            aria-label="download"
                            size="small"

                        >
                            
                            Mark&nbsp;As&nbsp;Paid
                        </Button>
                        : <Chip label="PAID" icon={<Done color="light" />} size="small" color="success" />}
                </TableCell> */}
           