import { Box, Paper, Tab, Tabs } from "@mui/material"
import { Outlet } from "react-router-dom"
import CustomContainer from "../../components/layouts/CustomContainer"
import GrayBgBox from "../../components/layouts/GrayBgBox"
import InventoryCount from "../../components/layouts/inventory/InventoryCount"
import LifeTimeStockCount from "../../components/layouts/inventory/LifeTImeStockCount"

const InventoryInnerOutlet = ({ isChannel, isLifetime }) => {
    console.log(isChannel)
    return (<>
        {isLifetime && <LifeTimeStockCount />}
       {!isLifetime && <InventoryCount isChannel={isChannel} />}
        <Outlet />

    </>)
}
export default InventoryInnerOutlet