import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { useParams } from "react-router-dom"

import { forwardLeadsApi, updateLeadApi } from "../../apis/lead.api"

import LeadChangeStatusdUI from "./LeadChangeStatusUi"

const LeadForwardController = ({ callBack, id, defaultVal }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        status: null,



        usercode: params.usercode

    })


    const validationSchema = useMemo(() => ([

        {
            required: true,
            value: fields.status,
            field: 'status',
        },
    ]), [fields])


    const onSubmit = async (e) => {

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateLeadApi(fields),
                    async (response) => {

                        await callBack()
                        setLoading(false)

                    },
                    (err) => {
                        setLoading(false)
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    useEffect(() => {
        
        if (defaultVal._id !== fields.status && fields.status != '') {
            onSubmit()
        }
    }, [fields.status])

    return <LeadChangeStatusdUI defaultVal={defaultVal} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(LeadForwardController)