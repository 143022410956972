import { Box, CircularProgress, Paper, styled, Tab, Tabs } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { center } from "../../assets/css/theme/common"
import CustomContainer from "../../components/layouts/CustomContainer"
import GrayBgBox from "../../components/layouts/GrayBgBox"
import InventoryCount from "../../components/layouts/inventory/InventoryCount"
import LifeTimeStockCount from "../../components/layouts/inventory/LifeTImeStockCount"
import OneViewBox from "../../components/layouts/OneViewBox"
import { fetchProductCategoryAction } from "../../store/actions/productCategoryAction"


const AntTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: theme.typography.h4.fontSize + " !important",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'white',
    borderRight: "1px solid white",
    marginRight: "0px !important",
    

    // '&:hover': {
    //   color: '#40a9ff',
    //   opacity: 1,
    // },
    '&.Mui-selected': {
        //   color: 'white',
        background: "white",
        borderBottom: "0px !important"
    },
    // '&.Mui-focusVisible': {
    //   backgroundColor: '#d1eaff',
    // },
}));


const ReturnReplacementMain = ({ isAdmin }) => {


    const params = useParams()
    const navigate = useNavigate()

    const location = useLocation()
    const path = location.pathname.split('/')

    const [tab, setTab] = useState(path[path.length - 1])

    const handleChange = (event, newValue) => {
        setTab(newValue)
        navigate(`/dashboard/${params.usercode}/replacement/` + newValue)
    }




    if (isAdmin) {
        return <CustomContainer >
            <Paper sx={{ width: "100%", zIndex: 111, mt: 2 }} mt={2}>
            <Tabs
                    sx={{
                        background: "linear-gradient(0.22deg, #A91674 -94.29%, #E6204B 121.72%)", '& .MuiTabs-indicator': {
                            backgroundColor: 'transparent'
                        },
                    }}
                    
                    variant="fullWidth"
                    aria-label="scrollable prevent tabs example"
                >
                    <AntTab label={"Ticket Dashboard"} value="my" />                    
                </Tabs>
                <GrayBgBox sx={{ flexDirection: "column" }}>
                    <Outlet />
                </GrayBgBox>
            </Paper>
        </CustomContainer>
    }
    return (<>
        <CustomContainer >
            <Paper sx={{ width: "100%", zIndex: 111, mt: 2 }} mt={2}>
                <Tabs
                    sx={{
                        background: "linear-gradient(0.22deg, #A91674 -94.29%, #E6204B 121.72%)", '& .MuiTabs-indicator': {
                            backgroundColor: 'transparent'
                        },
                    }}
                    value={tab}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="scrollable prevent tabs example"
                >
                    <AntTab label={"MY TICKETS"} value="my" />
                    <AntTab label={"CHANNEL TICKETS"} value="channel" />
                </Tabs>

                <GrayBgBox sx={{ flexDirection: "column" }}>
                    <Outlet />
                </GrayBgBox>
            </Paper>
        </CustomContainer>

    </>)
}
export default ReturnReplacementMain