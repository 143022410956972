import React from 'react'
import GrayBgBox from '../GrayBgBox'
import { Chip, Typography } from '@mui/material'

function DeviceDisplayModal({devices}) {
  return (
    <GrayBgBox sx={{ width: "100%" ,
    flexWrap:"wrap"
    }}>
    {
        // devices && Array.isArray(devices) && devices.map(device =><> <Chip key={device.boxId} color="primary" label={device.boxId} style={{marginRight:2,marginBottom:2}} ></Chip></>)
        devices && Array.isArray(devices) && devices.length > 0 ? (
          devices.map(device => (
            <Chip key={device.boxId} color="primary" label={device.boxId} style={{ marginRight: 2, marginBottom: 2 }} />
          ))
        ) : (
          <Typography variant="h4" style={{ color : "#a91674"  }}>No data</Typography>
        )
    }
    </GrayBgBox>
  )
}

export default DeviceDisplayModal



