import { useEffect, useState } from "react"
import { Outlet, useParams } from "react-router-dom"
import { center } from "../assets/css/theme/common"
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserDetails } from "../store/actions/userReducerAction"
import OneViewBox from "./layouts/OneViewBox"
import { CircularProgress } from "@mui/material"
import Header from "./Header"
const NullRoutingComponent = ({ allowfetchUser }) => {

    const [loading, setLoading] = useState(true)
    const user = useSelector(state => state.user)
    const params = useParams()
    const dispatch = useDispatch()

    useEffect(() => {

        if (allowfetchUser && params.usercode !== user.data.usercode) {
            dispatch(fetchUserDetails({ usercode: params.usercode }, (res) => { setLoading(false) }, (err) => { setLoading(false) }))
        } else {
            setLoading(false)
        }
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [params.usercode])

    // if (loading) {
    //     return <OneViewBox sx={{ ...center }}><CircularProgress size={50} /> </OneViewBox>
    // }
    return <><Header  /><Outlet /></>
}
export default NullRoutingComponent