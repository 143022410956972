import { useCallback, useMemo, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { checkDeviceReturnableStatus, replaceDeviceApi } from "../../../apis/inventory.api"
import MessageDilog from "../../../components/MessageDilog"
import { callApiAction } from "../../../store/actions/commonAction"
import { addInventoryListAction } from "../../../store/actions/invenotryListAction"
import { fetchInventoryCountAction } from "../../../store/actions/inventoryCountAction"
import { fetchLifeTimeStockCountAction } from "../../../store/actions/lifeTimeStockCountAction"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import useValidate from "../../../store/hooks/useValidator"
import InventoryListReducer from "../../../store/reducers/InventoryListReducer"
import { getDateFiltersTime } from "../../../utils/helper"
import ReturnBoxUi from "./RetrunBoxUi"


const ReturnBoxesController = ({ callBack=()=>{} }) => {
    const validate = useValidate()
    const params = useParams()
    const dispatch = useDispatch()
    const { inventoryCount, lifeStockCount } = useSelector(state => state)

    const [fields, setFields] = useState({

        err: '',

        boxId: "",
        replacedBoxId: "",

        usercode: params.usercode
    })


    const [boxDetails, setBoxDetails] = useState({})

    const [loading, setLoading] = useState(false)


    const validationSchema = useMemo(() => ([

        {
            required: true,
            value: fields.boxId,
            field: 'Returned Box Id ',
        }
    ]), [fields])

    const onSearchDevice = useCallback((e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await checkDeviceReturnableStatus({ boxId: fields.boxId, usercode: fields.usercode }),
                    async (response) => {


                        setBoxDetails(response)
                        setLoading(false)

                    },
                    (err) => {
                        setLoading(false)
                        setBoxDetails({})
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, err: validationResponse })
        }


    }, [fields])


    const validationSchemaForFinaleSubmit = useMemo(() => ([
        {
            required: true,
            value: fields.boxId,
            field: 'Returned Box Id ',
        },
        {
            required: true,
            value: fields.replacedBoxId,
            field: ' Box Id For Replacement ',
        }
    ]), [fields])

    const onSubmit = useCallback((e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchemaForFinaleSubmit)

        if (validationResponse === true) {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await replaceDeviceApi({ ...fields }),
                    async (response) => {
                        callBack()
                        dispatch(closeModal())                        
                        setLoading(false)
                    },
                    (err) => {
                        setLoading(false)                        
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, err: validationResponse })
        }


    }, [fields])



    return <ReturnBoxUi
        onSearchDevice={onSearchDevice}
        setFields={setFields}
        fields={fields}
        boxDetails={boxDetails}
        onSubmit={onSubmit}
    />

}
export default ReturnBoxesController