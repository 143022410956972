import { Badge, Chip, CircularProgress, Collapse, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"

import { center } from "../../../assets/css/theme/common"
import SearchBar from "../../../components/inputs/SearchBar"
import { Download, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Fragment, useState } from "react"
import { dateConverter } from "../../../utils/helper"
import GrayBgBox from "../../../components/layouts/GrayBgBox"
import SubmitButton from "../../../components/button/SubmitButton"
import { memo } from "react"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"
import DataTable from "../../../components/tables/DataTable"

const Row = memo(({ row, onDownloadInvoice, ...props }) => {

    const [open, setOpen] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const onDownloadBtnClick = async () => {        
        await onDownloadInvoice(row._id, row.invoiceId)

    }
    return <Fragment key={row.usercode}>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell >{dateConverter({ value: row.createdAt })}</TableCell>
            <TableCell >{row.invoiceId}</TableCell>
            <TableCell >
                {
                    row.devices && Array.isArray(row.devices) && row.devices.length
                }
            </TableCell>
            <TableCell >

                {
                    row.devices && Array.isArray(row.devices) && row.devices.reduce((total, num) => total + num.amount, 0)
                }
            </TableCell>
            <TableCell>
                {downloading ? <CircularProgress /> : <Tooltip title="download invoice">
                    <IconButton
                        aria-label="download"
                        size="small"

                    >
                        <Download onClick={onDownloadBtnClick} />
                    </IconButton>
                </Tooltip>}
            </TableCell>
            <TableCell >

                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>


            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
                    <GrayBgBox sx={{ width: "100%" ,flexWrap:"wrap"}}>
                        {
                            row.devices && Array.isArray(row.devices) && row.devices.map(device => <Chip key={device.boxId} color="primary" label={device.boxId} style={{marginRight:2,marginBottom:2}} ></Chip>)
                        }
                    </GrayBgBox>
                </Collapse>
            </TableCell >
        </TableRow>
    </Fragment>
})


const InvoicePageUi = ({ listLoading, filters, setFilters, list, columns,  onDownloadInvoice }) => {
    const user = useSelector(state => state.user)
    return (
        <>

            <Box pt={4} >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Invoices</Typography>
                        <Box pl={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <AsynSearchBar

                                fullWidth
                                title="Search by Invoice Number"
                                size="small"
                                placeholder={"Search by Invoice Number"}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />
                        </Box>
                    </Box>
                    <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={listLoading} />
                </Paper>
            </Box>

        </>
    )
}
export default InvoicePageUi