import { Box, Button, Fab, Grid, InputAdornment, Paper, Tooltip, Typography } from "@mui/material"
import ImageComponent from "./ImageComponent"

import Logo from '../assets/images/logo.png'

import SubmitButton from "./button/SubmitButton"
import { center } from "../assets/css/theme/common"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useSelector, useDispatch } from 'react-redux'
import { signOutAction } from "../store/actions/userReducerAction"
import { useLocation, useNavigate } from "react-router-dom"
import { loggedInUser } from "../utils/helper"
import PopUpModal from "./Modal"
import AsyncDropDown from "./inputs/AsyncDropDown"
import { fetchInventoryList } from "../apis/inventory.api"
import { DynamicSearchBar, grayInputStyle } from "./inputs/SearchBar"
import { ArrowBack, ArrowCircleLeft, ArrowLeft, Search } from "@mui/icons-material"
import CentralSearchBar from "./CentralSearchBar"
import AppModeLabel from "./texts/AppModeLabel"
import { useMemo } from "react"
import { memo } from "react"

const headerStyle = (theme) => {

    return {
        borderRadius: "32px",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: "none",
        height: "auto",
        justifyContent: "space-between",

        padding: 2,
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",

            padding: 1,
        },

    }
}

const headerContainerStyle = (theme) => {

    return {
        position: "sticky",
        top: "0px",
        width: "100%",
        display: "flex",
        backgroundColor: theme.palette.grey[200],

        zIndex: "1111",
    }
}

const rightContainerStyle = (theme) => {

    return {

        ...center,
        flex: 2,
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            padding: 1,
        },
    }
}


const Header = () => {

    const user = useSelector(state => state.user)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const location = useLocation()
    const splittedPath = location.pathname.split('/')

    const isProfielPage = splittedPath[3] && splittedPath[3] == 'profile'

    return (
        <>

            <Box sx={headerContainerStyle}>

                {
                    process.env.REACT_APP_APP_MODE != 'production' && <AppModeLabel />
                }
                <Paper elevation={2} sx={headerStyle}  >

                    <Box sx={{ flex: 2, justifyContent: "flex-start", alignItems: "center" }} >
                        {user.data.usercode != loggedInUser.get().usercode && <Fab onClick={() => { navigate(-1) }} color="light" aria-label="add" sx={{

                            boxShadow: "none"
                        }} >
                            <ArrowBack color="primary" />
                        </Fab>}
                        <Button component={"button"} onClick={() => { navigate('/dashboard/' + loggedInUser.get().usercode) }}>
                            <ImageComponent src={Logo} alt="Wehear Logo" />
                        </Button>
                    </Box>
                    <Box sx={{ flex: 1, display: "flex", ...center, flexDirection: "column" }}>

                        <Typography variant="h3" align="center" textTransform={"capitalize"} color="secondary">{user.data.name}</Typography>
                        <Typography variant="h4" align="center" textTransform={"capitalize"} color="primary">{user.data.roleName}</Typography>


                    </Box>
                    <Box sx={rightContainerStyle}>
                        <Box sx={{ flex: 1 }}>

                            <CentralSearchBar key={user.data.usercode} />
                        </Box>
                        <Box component={"span"} ml={1} mt={1} mr={1} mb={1} >

                            <Tooltip title={user.data.name.toUpperCase()} className="" arrow >
                                <Grid item xs={8} >
                                    {isProfielPage ? <SubmitButton sx={{ pl: 3, pr: 3 }} onClick={() => { dispatch(signOutAction()) }} title={'logout'} /> : <SubmitButton sx={{ pl: 3, pr: 3 }} onClick={() => navigate('/dashboard/' + loggedInUser.get().usercode + '/profile')} icon={<AccountCircleIcon color="light" mr={2} sx={{ marginRight: 1 }} />} title={loggedInUser.get().name.length > 13 ? loggedInUser.get().name.slice(0, 10) + '...' : loggedInUser.get().name} />}
                                </Grid>
                            </Tooltip>



                        </Box>
                    </Box>
                </Paper>
            </Box>
            <PopUpModal />
        </>
    )
}
export default memo(Header)