import { useCallback, useMemo, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchInventoryList, sellInventory, transferInventory } from "../../../apis/inventory.api"
import MessageDilog from "../../../components/MessageDilog"
import { callApiAction } from "../../../store/actions/commonAction"
import { addInventoryListAction } from "../../../store/actions/invenotryListAction"
import { fetchInventoryCountAction } from "../../../store/actions/inventoryCountAction"
import { fetchLifeTimeStockCountAction } from "../../../store/actions/lifeTimeStockCountAction"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import useValidate from "../../../store/hooks/useValidator"
import InventoryListReducer from "../../../store/reducers/InventoryListReducer"
import { getDateFiltersTime } from "../../../utils/helper"
import SellBoxesUi from "./SellBoxesUi"
import { invoicePaymentStatus } from "../../../utils/constants"


const SellBoxesController = ({ title, callBack, defaultData, isFree }) => {
    const validate = useValidate()
    const params = useParams()
    const dispatch = useDispatch()
    const { inventoryCount, lifeStockCount } = useSelector(state => state)

    const [fields, setFields] = useState({
        defaultAmount: isFree ? 0 : 10000,
        err: '',
        defaultCategory: '',
        invoiceId: isFree ? "FREE-" + new Date().getTime() : "",
        assignedTo: "",
        start: '',
        end: '',
        s: invoicePaymentStatus.PAID,
        pageNo: 1,
        pageSize: 500,
        onlyBoxId: true,
        caddress: '',
        cname: '',
        cemail: '',
        cphone: '',
        soldWith: null,
        usercode: params.usercode
    })

    const [boxes, dispatchBoxes] = useReducer(InventoryListReducer, defaultData)

    const [loading, setLoading] = useState(false)


    const validationSchema = useMemo(() => ([

        {
            required: true,
            value: fields.start,
            field: 'Starting box Id ',
        },
        {
            required: true,
            value: fields.start,
            field: 'Last box Id',
        }
    ]), [fields])

    const onSelectRange = useCallback((e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await fetchInventoryList(fields),
                    async (response) => {

                        const temp = response.result && Array.isArray(response.result) ? response.result : []
                        dispatchBoxes(addInventoryListAction(temp.map((val) => (
                            { boxId: val.boxId, amount: fields.defaultAmount, selected: true }
                        ))))
                        setFields({ ...fields, err: '', start: '', end: '' })
                        setLoading(false)

                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, err: validationResponse })
        }


    }, [fields])


    const validationSchemaForFinaleSubmit = useMemo(() => ([

        {
            
            value: fields.defaultAmount,
            field: 'Unit Price  ',
            custom:()=>{
                if(!fields.defaultAmount && isNaN(fields.defaultAmount)){
                    return "Amount is required"
                }
                return true
            }
        },

        {
            required: true,
            value: fields.invoiceId,
            field: 'Invoice number',
        },
        {
            required: true,
            value: fields.cname,
            field: 'Customer Name',
        },
        {
            required: true,
            value: fields.cphone,

            field: 'Customer Phone no',
        },

        {
            required: true,
            value: boxes,
            isArray: true,
            field: 'Boxes ',
        }
    ]), [fields, boxes])

    const onSubmit = useCallback((e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchemaForFinaleSubmit)

        if (validationResponse === true) {
            setLoading(true)
            const transferableData = {
                devices: boxes.filter((val) => val.selected).map((val) => ({
                    boxId: val.boxId,
                    amount: fields.defaultAmount,
                    paymentStatus: fields.paid
                })),
                invoiceId: fields.invoiceId,
                usercode: params.usercode,
                cname: fields.cname,
                cemail: fields.cemail,
                caddress: fields.caddress,
                cphone: fields.cphone,
                soldWith: fields.soldWith
            }

            dispatch(
                callApiAction(
                    async () => await sellInventory(transferableData),
                    async (response) => {

                        await callBack()

                        dispatch(fetchLifeTimeStockCountAction({ usercode: params.usercode }, () => { }, () => { }))
                        dispatch(fetchInventoryCountAction({ productCategory: params.productCategoryId, usercode: params.usercode, ...getDateFiltersTime(inventoryCount.filters.dateTime), ...inventoryCount.filters }, () => { }, () => { }))
                        dispatch(openModal({
                            component:<MessageDilog title={"Devices Sold"} message="Devices Sold successfully." />,
                            size : "sm",
                        }))

                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })

                    }
                )
            )
        } else {
            setFields({ ...fields, err: validationResponse })
        }


    }, [fields, boxes])



    return <SellBoxesUi
        isFree={isFree}
        boxes={boxes}
        dispatchBoxes={dispatchBoxes}
        setFields={setFields}
        loading={loading}
        fields={fields}
        title={title}
        onSubmit={onSubmit}
        onSelectRange={onSelectRange} />

}
export default SellBoxesController