import { DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from 'react-redux'

import SubmitButton from "../../components/button/SubmitButton"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import CustomInput from "../../components/inputs/CustomInput"
import { closeModal } from "../../store/actions/modalAction"
import { fetchInventoryList } from "../../apis/inventory.api"


const ApplyTicketUi = ({ fields, setFields, loading, onSubmit }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>
        <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" onSubmit={onSubmit} >
            <DialogTitle variant="h3">{'Apply Replacement Ticket'}
                <Box component={'div'} ><Typography variant="h4" color={'red'}>{fields.err} </Typography></Box>
            </DialogTitle>
            <DialogContent>
                <AsyncDropDown
                    OptionComponent={({ option, ...rest }) => {
                        return <MenuItem {...rest}>{option.boxId}</MenuItem>
                    }}
                    label="Select Devices To Be Returned*"
                    multiple
                    InputComponent={(props) => <CustomInput placeholder label="Select Devices To Be Returned" {...props} />}
                    onChange={(devices) => { setFields({ ...fields, devices: devices ? devices.map((item) => item.boxId) : [] }) }}
                    titleKey={'boxId'}
                    valueKey={"boxId"}
                    lazyFun={async (filters) => { return await fetchInventoryList({ ...filters, onlyBoxId: true, status: "all" }) }}

                />
                <CustomInput
                    multiline
                    rows={3}
                    disabled={loading}
                    value={fields.remarks}
                    onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                    type="text"
                    label={"Remarks*"}

                />

            </DialogContent>

            <DialogActions   >
                <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>
                <SubmitButton loading={loading} disabled={loading} type="submit" title="Apply" ></SubmitButton>
            </DialogActions>
        </Box>
    </>
}
export default ApplyTicketUi