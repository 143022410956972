import { useState } from "react"
import CustomInput from "../../../components/inputs/CustomInput"
import { Box, CircularProgress } from "@mui/material"
import TicketDetailsController from "../TicketDetailsController"
import SubmitButton from "../../../components/button/SubmitButton"
import { REPLACEMENT_TICKETS_STATUS, REPLACEMENT_TICKETS_STATUS_OBJ } from "../../../utils/constants"

const CustomerReplacementOTPUi = ({  fields, setFields, setLoading, loading, verifyOtp, ticketData, setTicketData, onOtpSend }) => {


    return <>
        {<Box component="form" onSubmit={verifyOtp} >
            { }
            <Box mt={2} mb={2}>
                <CustomInput
                    mt={2}
                    fullWidth={true}
                    disabled={loading}
                    placeholder={"Enter 6 digit OTP"}
                    value={fields.otp}
                    type="number"
                    onChange={(e) => { setFields({ ...fields, otp: e.target.value }) }} />
            </Box>
            {
            <SubmitButton type="submit" title="Verify OTP" disabled={loading} loading={loading}  />}
        </Box>}

    </>
}
export default CustomerReplacementOTPUi