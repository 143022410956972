import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { center } from "../../../assets/css/theme/common"

const outerboxDisplayStyle = (theme) => (
    {
        pl: 2,
        pr: 2,
        flexDirection:'column',
        justifyContent:"center",
        borderLeft: 1,
        borderLeftColor: theme.palette.primary.main,

    }
)
const CategoryCountDisplay = ({ title, subTitle }) => {
    return <Box sx={outerboxDisplayStyle}>


            <Typography sx={{ width: '100%' }} variant="h1">{title}</Typography>
            <Typography sx={{ width: '100%' }} variant="h6" color="GrayText">{subTitle}</Typography>
        
    </Box>
}
export default CategoryCountDisplay