
import { Box, Checkbox, DialogActions, DialogContent, DialogTitle, Divider, Grid, MenuItem, Switch, Table, Typography } from "@mui/material"
import { memo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchInventoryList, fetchSoldTypes } from "../../../apis/inventory.api"

import SubmitButton from "../../../components/button/SubmitButton"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import CustomInput from "../../../components/inputs/CustomInput"
import { DynamicSearchBar, InputComponent } from "../../../components/inputs/SearchBar"
import { updateInventoryListAction } from "../../../store/actions/invenotryListAction"
import { closeModal } from "../../../store/actions/modalAction"
import { permissions } from "../../../utils/constants"

const RetrunBoxUi = ({ title, onSubmit, boxDetails, fields, setFields, loading, onSearchDevice }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    const deviceFetched = !(!boxDetails || Object.keys(boxDetails).length == 0)
    return <>

        <DialogTitle variant="h3"> Replace Device
            <Typography variant="h6" color="red">{fields.err} </Typography>
        </DialogTitle>

        <DialogContent sx={{ minHeight: '100px', display: "flex", position: "relative" }}>
            {!deviceFetched && <Box sx={{ width: "100%", flexDirection: "column" }} component={"form"} onSubmit={onSearchDevice}>
                <Box mt={2} mb={2} sx={{ width: "100%" }}>
                    <CustomInput mt={2} fullWidth={true} disabled={loading} placeholder={"Returned Box Id"} value={fields.boxId} onChange={(e) => { setFields({ ...fields, err: '', boxId: e.target.value }) }} />
                </Box>
                <SubmitButton disabled={loading} loading={loading} type="submit" title="Search" ></SubmitButton>
            </Box>}

            {deviceFetched &&


                <Box sx={{ width: "100%", flexDirection: "column" }} onSubmit={onSearchDevice}>

                    <Divider />
                    <Grid container mt={1} mb={2} spacing={2}>
                        <Grid item xs={6}>
                            Returned Box Id
                        </Grid>
                        <Grid item xs={6}>
                            {fields.boxId}
                        </Grid>


                        <Grid item xs={6}>
                            Customer Name
                        </Grid>
                        <Grid item xs={6}>
                            {boxDetails.cname}
                        </Grid>

                        <Grid item xs={6}>
                            Customer Phone
                        </Grid>
                        <Grid item xs={6}>
                            {boxDetails.cphone}
                        </Grid>
                        <Grid item xs={6}>
                            Customer Address
                        </Grid>
                        <Grid item xs={6}>
                            {boxDetails.caddress}
                        </Grid>
                    </Grid>
                    <Divider />

                    <Typography mt={2}>Select New Device For Replacement</Typography>
                    <AsyncDropDown
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.boxId}</MenuItem>
                        }}
                        hideEndAdornment={true}
                        InputComponent={DynamicSearchBar}
                        onChange={(boxId) => { setFields({ ...fields, replacedBoxId: boxId?.boxId }) }}
                        titleKey={'boxId'}
                        valueKey={"boxId"}
                        placeholder="Select Box"
                        lazyFun={async (filters) => { return await fetchInventoryList({ onlyBoxId: true, status: "sellable", serachSameCategoryBoxId: fields.boxId, ...filters }) }}
                    />

                </Box>}
            {/* <Box sx={{ minHeight: '100px', width: "100%", display: "flex" }}>
                {boxes.length != 1 &&
                    <Box sx={{ position: "relaive", overflowY: 'scroll' }} >
                        {<Box onSubmit={onSelectRange} component={"form"} sx={{ border: 1, p: 1, borderRadius: 1, position: 'sticky', top: '0px', background: "light" }}>                            
                            <Typography variant="h5" mb={2}>Select Box Range</Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <InputComponent disabled={loading} placeholder={"Start"} value={fields.start} onChange={(e) => { setFields({ ...fields, err: '', start: e.target.value }) }} />
                                &nbsp;-&nbsp;
                                <InputComponent disabled={loading} placeholder={"End"} value={fields.end} onChange={(e) => { setFields({ ...fields, err: '', end: e.target.value }) }} />
                            </Box>
                            <SubmitButton loading={loading} type="submit" sx={{ mt: 1 }} title="Find Boxes" />

                        </Box>}


                        {<Box sx={{ flex: 1 }}>

                            <Typography variant="h5" mt={2}>Boxes</Typography>
                            {boxes.length == 0 && <Typography variant="h6" mt={3} mb={3} textAlign="center">No data</Typography>}
                            {boxes.length > 0 &&

                                <Box>
                                    {
                                        boxes.map((box, index) => (<BoxItem
                                            key={box.boxId}
                                            title={box.boxId}
                                            amount={box.amount}
                                            category={box.category}
                                            selected={box.selected}
                                            dispatchBoxes={dispatchBoxes}
                                        />))
                                    }
                                </Box>
                            }
                        </Box>}
                    </Box>}





                <Box sx={{ border: 1, p: 1, borderRadius: 1, ml: 1, flexDirection: "column", overflowY: "scroll", flex: 1, display: 'flex', maxHeight: "100%" }}>
                    <Typography variant="h5" mb={2}>Details</Typography>


                    <Box sx={{}}><CustomInput type="text" label="Customer Name*" onChange={(e) => setFields({ ...fields, cname: e.target.value })} /></Box>
                    <Box sx={{}}><CustomInput type="text" label="Customer Phone No.*" onChange={(e) => setFields({ ...fields, cphone: e.target.value })} /></Box>
                    <Box sx={{}}><CustomInput type="email" label="Customer Email" onChange={(e) => setFields({ ...fields, cemail: e.target.value })} /></Box>
                    <Box sx={{}}><CustomInput type="text" multiline rows={4} label="Customer Address" onChange={(e) => setFields({ ...fields, caddress: e.target.value })} /></Box>
                    <Box sx={{}}><CustomInput type="number" min={0} label="Unit Price(₹)*" onChange={(e) => setFields({ ...fields, defaultAmount: e.target.value })} /></Box>
                    <Box sx={{}}><CustomInput type="text" label="Invoice number*" onChange={(e) => setFields({ ...fields, invoiceId: e.target.value })} /></Box>
                    {user.permissions.includes(permissions.CRM_MANAGERS) &&
                        <Box sx={{}}>
                            <AsyncDropDown


                                label="Sales Channel"
                                onChange={async (changedVal) => { setFields({ ...fields, err: '', soldWith: changedVal }) }}
                                titleKey={'name'}
                                valueKey={"value"}
                                lazyFun={fetchSoldTypes} />
                        </Box>}

                </Box>


            </Box> */}
        </DialogContent>

        <DialogActions>
            <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>

            <SubmitButton loading={loading} disabled={!deviceFetched || loading} onClick={onSubmit ? onSubmit : handleClose} title="Replace" ></SubmitButton>
        </DialogActions>


    </>
}



export default RetrunBoxUi