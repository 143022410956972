import { Box, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useMatch, useNavigate, useParams, useResolvedPath } from "react-router-dom"

import { allowedDateFilters, getDateFiltersTime } from "../../utils/helper"


import { callApiAction } from "../../store/actions/commonAction"
import { getReplacementTicketCountApi } from "../../apis/replacement.api"
import ClickButton from "../../components/button/ClickButton"
import { REPLACEMENT_TICKETS_STATUS } from "../../utils/constants"

const TicketCount = ({ onlyme, onChangeStatus = () => { } }) => {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()    

    const [loading, setLoading] = useState()

    const [data, setData] = useState([])

    useEffect(() => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getReplacementTicketCountApi({ onlyme,usercode:params.usercode }),
            (response) => {
                setData(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [params.usercode, onlyme])

    const [tabId, setTab] = useState(null)

    const handleChange = (val) => {
        setTab(val)
        onChangeStatus(val)
    }

    return (
        <Box pt={2} pb={2}>
            <Grid container sx={{ justifyContent: "center" }} spacing={{
                xs: 1,
                md: 2,
            }}>
                {loading && [0, 1, 2, 3].map((val) => (<Grid key={val} item xs={6} md={3}>
                    <ClickButton loading={true} title={''} subTitle={""} />
                </Grid>))}

                {!loading && Array.isArray(data) && data.map((val) => {

                    return (

                        <Grid key={val.role} item xs={6} md={12 / data.length}>
                            <ClickButton
                                dropDownData={val.dropdown && allowedDateFilters}
                                dropDownProps={{
                                    // value: filters.dateTime,
                                    // onChange: (e) => { e.preventDefault(); dispatch(setInventoryCountFilters('dateTime', (e.target.value))) }
                                }}

                                onClick={() => {
                                    handleChange(val.name)
                                }}
                                active={tabId == val.name}
                                title={val.count}
                                subTitle={REPLACEMENT_TICKETS_STATUS[val.name]} />
                        </Grid>
                    )
                })}

            </Grid>
        </Box>
    )
}
export default TicketCount