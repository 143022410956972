import { memo, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import {  forwardLeadsApi } from "../../apis/lead.api"
import LeadForwardUI from "./LeadForwardUi"

const LeadForwardController = ({ callBack, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        to: null,
        message: "",



        usercode: params.usercode

    })


    const validationSchema = useMemo(() => ([
        {
            
            value: fields.to,
            field: 'to',
        },
        {
            required: true,
            value: fields.message,
            field: 'Message',
        },
    ]), [fields])


    const onSubmit = async (e) => {
        e.preventDefault()

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await forwardLeadsApi(fields),
                    async (response) => {

                        await callBack()
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    return <LeadForwardUI loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(LeadForwardController)