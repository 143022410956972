import { fetchLifeTimeStockCount, fetchProductCategoryApi } from "../../apis/inventory.api"
import { actions, soldCategoriesName } from "../../utils/constants"
import { callApiAction } from "./commonAction"

export const fetchProductCategoryAction = (onError=()=>{}) => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.START_PRODUCT_CATEGORY_LOADING })
        dispatch(callApiAction(
            async () => await fetchProductCategoryApi(),
            (response) => {

               

                dispatch({ type: actions.SET_PRODUCT_CATEGORY_DATA, value: response.result })
                

            },
            (err) => {

                onError(err)
            }
        ))
    }
}

