import { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { revertBoxApi } from "../../apis/inventory.api"
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { inventoryStatus, permissions } from "../../utils/constants"
import MessageDilog from "../MessageDilog"
import SubmitButton from "./SubmitButton"

const RevertButton = ({ boxId, status, callBack = () => { } }) => {

    const [loading, setLoading] = useState()

    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const onSubmit = () => {

        setLoading(true)
        dispatch(
            callApiAction(
                async () => await revertBoxApi({boxId}),
                async (response) => {

                    await callBack()
                    setLoading(false)
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false)
                    alert(err)
                }
            )
        )
    }
    const onRevertButtonclick = useCallback(() => {
        dispatch(openModal({
            component:<MessageDilog title="Alert !" onSubmit={onSubmit} message={`Are you sure to revert device ${boxId}?`} />,
            size : "sm",
        }))
    },[])

    if (user.permissions.includes(permissions.CRM_MANAGERS) )
        return <SubmitButton loading={loading}  disabled={loading}  title={"Revert"} onClick={onRevertButtonclick} />
    else
        return <></>
}
export default RevertButton