import { memo, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addUserApi } from "../../apis/user.api"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import LeadCreateUi from "./LeadCreateUI"
import { createLeadApi } from "../../apis/lead.api"

const LeadCreateController = ({ callBack }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        leadTitle: '',
        personName: '',
        contactNo: '',
        // status: null,
        type: null,
        to: null,
        message: "",

        companyName: '',


        email: '',
        address: "",

        usercode: params.usercode

    })


    const validationSchema = useMemo(() => ([
        
        {
            required: true,
            value: fields.leadTitle,
            field: 'Lead Title',
        },
        {
            required: true,
            value: fields.personName,
            field: 'Person Name',
        },
        {
            required: true,
            value: fields.contactNo,
            field: 'Contact No.',
        },
        // {
        //     required: true,
        //     value: fields.status,
        //     field: 'Status',
        // },
        {
            required: true,
            value: fields.type,
            field: 'Type',
        },
        {
            
            value: fields.to,
            field: 'to',
        },
        {
            required: true,
            value: fields.message,
            field: 'Message',
        },
    ]), [fields])


    const onSubmit = async (e) => {
        e.preventDefault()

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createLeadApi(fields),
                    async (response) => {

                        await callBack()
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    return <LeadCreateUi loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(LeadCreateController)