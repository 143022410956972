import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/css/spacing.css';
import './assets/css/css/theme.css';
import './assets/css/css/typography.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from '@reduxjs/toolkit';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import theme from './assets/css/theme';
import './App.css';
import rootReducer from './store/reducers';
import thunk from 'redux-thunk'
import { BrowserRouter } from 'react-router-dom';


const store = configureStore({ reducer: rootReducer, middleware: [thunk] })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
