import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchInvoiceHtml } from "../../../apis/inventory.api"

import jsPDF from 'jspdf';
import html2canvas from "html2canvas"
import { CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, styled, Typography } from "@mui/material"
import { closeModal } from "../../../store/actions/modalAction"
import SubmitButton from "../../../components/button/SubmitButton"
import { Box } from "@mui/system"
import GrayBgBox from "../../../components/layouts/GrayBgBox"
import { companyDetails, invoiceTypes, TAXES } from "../../../utils/constants"
import { dateConverter } from "../../../utils/helper"
import { center } from "../../../assets/css/theme/common"

const BorederedBox = styled(Box)(({ theme, isLast ,noBorder}) => ({
    width: "100%",
    border: noBorder?"1px solid transparent":"1px solid black",
    padding: '2px',
    height: "100%",
    display: "flex",
    minHeight: isLast ? "200px" : "none",
    flexDirection: "column"
}));

const InvoicePreview = ({ invoiceId, name }) => {

    const [invoiceData, setInvoiceData] = useState({})
    const [loading, setloading] = useState(false)
    const [downloadLoading, setdownloadLoading] = useState(false)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(closeModal())
    }

    const removeTaxes = (amount, amountPP = 0) => {
        let toalTaxPerecent = 0
        TAXES.forEach((taxItem) => {
            toalTaxPerecent += taxItem.percent
        })
        return {
            amount: (amount - ((amount * toalTaxPerecent) / 100)),
            amountPP: (amountPP - ((amountPP * toalTaxPerecent) / 100))
        }

    }

    const fetchInvoice = () => {
        setloading(true)
        dispatch(callApiAction(
            async () => await fetchInvoiceHtml({ invoiceId }),
            (response) => {

                const invoice = { ...response }
                //removing taxes
                const products = invoice.products

                if (products && Array.isArray(products)) {
                    for (let i = 0; i < products.length; i++) {
                        const newAmounts = removeTaxes(products[i].amount, products[i].amountPP)
                        products[i].amount = newAmounts.amount
                        products[i].amountPP = newAmounts.amountPP
                    }
                }
                // invoice.products = products
                const taxes = TAXES.map(({ name, percent }) => ({ name, percent, amount: ((invoice.totalAmount * percent) / 100) }))
                invoice['taxes'] = taxes
                setInvoiceData(invoice)
                setloading(false)

            },
            (err) => {
                setloading(false)
            }
        ))
    }
    const onDownloadInvoice = useCallback(async () => {

        try {
            setdownloadLoading(true)
            const input = document.getElementById('invoicepreview')
            await html2canvas(input)
                .then((canvas) => {
                    // canvas.style = 
                    const imgData = canvas.toDataURL('image/png');


                    var imgWidth = 210;
                    var pageHeight = 295;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF('p', 'mm');
                    var position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                        heightLeft -= pageHeight;
                    }
                    // const pdf = new jsPDF();
                    // console.log
                    // pdf.addImage(imgData, 'JPEG', 0, 0);
                    // doc.output('pdfjsnewwindow');

                    doc.save(name + ".pdf");


                    setdownloadLoading(false)
                }).catch((err) => {
                    alert(err.message)
                    setdownloadLoading(false)
                })


        } catch (e) {

            alert("OOPS! something went wrong..." + e.message)
        }

        // ))
    }, [])


    useEffect(() => {
        fetchInvoice()
    }, [])

    if (loading || !invoiceData) {
        return <GrayBgBox sx={{ ...center }}>
            <CircularProgress />
        </GrayBgBox>
    }
    return (
        <>
            <DialogTitle variant="h3">Preview Invoice {name}

            </DialogTitle>
            <DialogContent sx={{ minHeight: '100px', display: "flex", position: "relative" }}>
                <Box sx={{ width: "100%", overflow: "scroll" }}>
                    <Box id="invoicepreview" sx={{ width: "100%", minWidth: "700px", display: "flex", flexDirection: "column", padding: "20px" }} >
                        <Box sx={{ ...center }}>
                            <Typography variant="pdfh1" mb={3}>Tax Invoice</Typography>
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", border: "1px solid black" }} >
                            <Grid container >
                                <Grid item xs={5}>
                                    {<BorederedBox>
                                        <Typography variant="pdfh1" textTransform="capitalize" mb={1}>{invoiceData.from ? invoiceData.from.name : companyDetails.NAME}</Typography>
                                        <Typography variant="pdfh3">Address:{invoiceData.from ? invoiceData.from.address : companyDetails.ADDRESS}</Typography>
                                        <Typography variant="pdfh3">GST NO :{invoiceData.from ? invoiceData.from.gstNo : companyDetails.GST}</Typography>
                                        <Typography variant="pdfh3">Email :{invoiceData.from ? invoiceData.from.email : companyDetails.EMAIL}</Typography>
                                    </BorederedBox>}
                                </Grid>
                                <Grid item xs={7}>
                                    <Grid container sx={{ height: "100%" }}>
                                        <Grid item xs={4}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" >Invoice No</Typography>
                                                <Typography variant="pdfh3">{invoiceData.invoiceId}</Typography>
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={1}>Bill No</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>
                                        <Grid item xs={4}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" >Date</Typography>
                                                <Typography variant="pdfh3">{dateConverter({ type: "DD_MM_YYYY", value: invoiceData.date })}</Typography>
                                            </BorederedBox>

                                        </Grid>



                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Delivery Note</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Mode/Terms of Payment</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>



                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Reference No. & Date.</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Other References</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>







                            <Grid container >
                                <Grid item xs={5}>
                                    {invoiceData.type != invoiceTypes.SOLD && <BorederedBox>
                                        <Typography variant="pdfh1" textTransform="capitalize" mb={1}>{invoiceData.to ? invoiceData.to.name : companyDetails.NAME}</Typography>
                                        <Typography variant="pdfh3">Address:{invoiceData.to ? invoiceData.to.address : companyDetails.ADDRESS}</Typography>
                                        <Typography variant="pdfh3">GST NO :{invoiceData.to ? invoiceData.to.gstNo : companyDetails.GST}</Typography>
                                        <Typography variant="pdfh3">Email :{invoiceData.to ? invoiceData.to.email : companyDetails.EMAIL}</Typography>
                                    </BorederedBox>}

                                    {invoiceData.type == invoiceTypes.SOLD && invoiceData.customer && invoiceData.customer.cname && <BorederedBox>
                                        <Typography variant="pdfh1" textTransform="capitalize" mb={1}>{invoiceData.customer ? invoiceData.customer.cname : companyDetails.NAME}</Typography>
                                        <Typography variant="pdfh3">Address:{invoiceData.customer ? invoiceData.customer.caddress : companyDetails.ADDRESS}</Typography>
                                        <Typography variant="pdfh3">Phone : {invoiceData.customer ? invoiceData.customer.cphone : companyDetails.EMAIL}</Typography>
                                        {invoiceData.customer && invoiceData.customer.cemail && <Typography variant="pdfh3">Email : {invoiceData.customer ? invoiceData.customer.cemail : companyDetails.EMAIL}</Typography>}
                                        {invoiceData.customer && invoiceData.customer.soldWith && <Typography variant="pdfh3">Sales Channel : {invoiceData.customer.soldWith}</Typography>}
                                    </BorederedBox>}
                                </Grid>
                                <Grid item xs={7}>
                                    <Grid container sx={{ height: "100%" }}>
                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Buyer's Order No.</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Dated</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>




                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Dispatch Doc No.</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Delivery Note Date</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>



                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Dispatched through</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={3}>Destination</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>

                                        <Grid item xs={12}>
                                            <BorederedBox>
                                                <Typography variant="pdfh2" mb={5}>Terms of Delivery</Typography>
                                                <Typography variant="pdfh3"></Typography>
                                            </BorederedBox>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>


















                            <Grid container sx={{ background: "lightgray" }}>
                                <Grid item xs={1}>
                                    <BorederedBox>
                                        <Typography variant="pdfh1" mb={1} mt={1}>Sr no.</Typography>
                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <BorederedBox>
                                        <Typography variant="pdfh1" mb={1} mt={1}>Description of Goods</Typography>
                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={2}>
                                    <BorederedBox>
                                        <Typography variant="pdfh1" mb={1} mt={1}>Quantity(Pcs)</Typography>
                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={2}>
                                    <BorederedBox >
                                        <Typography variant="pdfh1" mb={1} mt={1}>Rate(per Pcs)</Typography>
                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={3}>
                                    <BorederedBox>
                                        <Typography variant="pdfh1" mb={1} mt={1}>Total Amount</Typography>
                                    </BorederedBox>
                                </Grid>
                            </Grid>
                            {
                                invoiceData.products && Array.isArray(invoiceData.products) && invoiceData.products.map((product, index) => {

                                    return <Grid container key={product.product}  >
                                        <Grid item xs={1}>
                                            <BorederedBox isLast={index == invoiceData.products.length - 1}>
                                                <Typography variant="pdfh3" mb={1} mt={1}>{index + 1}</Typography>
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BorederedBox isLast={index == invoiceData.products.length - 1}>
                                                <Typography variant="pdfh2" mb={1} mt={1}>{product.product}</Typography>
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <BorederedBox isLast={index == invoiceData.products.length - 1}>
                                                <Typography variant="pdfh3" mb={1} mt={1}>{product.count}</Typography>
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={2} isLast={index == invoiceData.products.length - 1}>
                                            <BorederedBox >
                                                <Typography variant="pdfh3" mb={1} mt={1}>{product.amountPP}</Typography>
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <BorederedBox isLast={index == invoiceData.products.length - 1}>
                                                <Typography variant="pdfh3" mb={1} mt={1}>{product.amount}</Typography>
                                            </BorederedBox>
                                        </Grid>
                                    </Grid>
                                }
                                )
                            }
                              {
                                invoiceData.taxes && Array.isArray(invoiceData.taxes) && invoiceData.taxes.map((tax, index) => {

                                    return <Grid container key={tax.tax}  >
                                        <Grid item xs={1}>
                                            <BorederedBox  >
                                                {/* <Typography variant="pdfh3" mb={1} mt={1}>{index + 1}</Typography> */}
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BorederedBox  >
                                                <Typography variant="pdfh2" mb={1} mt={1} align="right">{tax.name}</Typography>
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <BorederedBox  >
                                                <Typography variant="pdfh3" mb={1} mt={1}></Typography>
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={2} >
                                            <BorederedBox  >
                                                <Typography variant="pdfh3" mb={1} mt={1}>{tax.percent}%</Typography>
                                            </BorederedBox>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <BorederedBox  >
                                                <Typography variant="pdfh3" mb={1} mt={1}>{tax.amount}</Typography>
                                            </BorederedBox>
                                        </Grid>
                                    </Grid>
                                }
                                )
                            }



                            <Grid container sx={{ background: "lightgray" }}>
                                <Grid item xs={1}>
                                    <BorederedBox>
                                        <Typography variant="pdfh1" mb={1} mt={1}></Typography>
                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <BorederedBox>
                                        <Typography align="right" variant="pdfh1" mb={1} mt={1}>Total&nbsp;</Typography>
                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={2}>
                                    <BorederedBox>
                                        <Typography variant="pdfh1" mb={1} mt={1}>{invoiceData.totalCount}</Typography>
                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={2}>
                                    <BorederedBox >
                                        <Typography variant="pdfh1" mb={1} mt={1}></Typography>
                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={3}>
                                    <BorederedBox>
                                        <Typography variant="pdfh1" mb={1} mt={1}>{invoiceData.totalAmount}</Typography>
                                    </BorederedBox>
                                </Grid>
                            </Grid>















                            <Grid container >
                                <Grid item xs={5}>
                                    <BorederedBox sx={{ borderRight: "0px", borderBottom: "0px" }}>

                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={7}>
                                    <BorederedBox sx={{ borderLeft: "0px" }}>
                                        <Typography variant="pdfh1" mb={1} mt={2}>Company's Bank Details</Typography>
                                        {invoiceData.from ? <>

                                            <Typography variant="pdfh2">Bank Name: {invoiceData.from.bankName}</Typography>
                                            <Typography variant="pdfh2">IFSC : {invoiceData.from.bankIFSC}</Typography>
                                            <Typography variant="pdfh2">A/c No : {invoiceData.from.bankNo}</Typography>
                                        </> : <>
                                            <Typography variant="pdfh2">Bank Name: {companyDetails.bankName}</Typography>
                                            <Typography variant="pdfh2">IFSC : {companyDetails.bankIFSC}</Typography>
                                            <Typography variant="pdfh2">A/c No : {companyDetails.bankNo}</Typography></>}
                                    </BorederedBox>

                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5}>
                                    <BorederedBox sx={{ borderTop: "0px", justifyContent: "flex-end" }}>
                                        <Typography variant="pdfh3" sx={{ textDecoration: "underline" }} >Declartion</Typography>
                                        <Typography variant="pdfh3">We declare that this invoice shows the actual price of the goods described and that all particulars are true and

                                            correct.</Typography>
                                    </BorederedBox>
                                </Grid>
                                <Grid item xs={7}>
                                    <BorederedBox sx={{ justifyContent: "space-between" }}>
                                        <Typography variant="pdfh1" align="right" mb={4} >for {invoiceData.from ? invoiceData.from.companyName : companyDetails.companyName}</Typography>
                                        <Typography variant="pdfh3" align="right">Authorised Signature</Typography>

                                    </BorederedBox>

                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ ...center }}>
                            <Typography variant="pdfh3">This is auto generated invoice from system</Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>
                <SubmitButton onClick={onDownloadInvoice ? onDownloadInvoice : handleClose} title="Download" loading={downloadLoading} ></SubmitButton>
            </DialogActions>
        </>
    )
}
export default InvoicePreview