import { Box, CircularProgress, Paper, styled, Tab, Tabs } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { center } from "../../assets/css/theme/common"
import CustomContainer from "../../components/layouts/CustomContainer"
import GrayBgBox from "../../components/layouts/GrayBgBox"
import InventoryCount from "../../components/layouts/inventory/InventoryCount"
import LifeTimeStockCount from "../../components/layouts/inventory/LifeTImeStockCount"
import OneViewBox from "../../components/layouts/OneViewBox"
import { fetchProductCategoryAction } from "../../store/actions/productCategoryAction"


const AntTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: theme.typography.h4.fontSize + " !important",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'white',
    borderRight: "1px solid white",
    marginRight: "0px !important",

    // '&:hover': {
    //   color: '#40a9ff',
    //   opacity: 1,
    // },
    '&.Mui-selected': {
        //   color: 'white',
        background: "white",
        borderBottom: "0px !important"
    },
    // '&.Mui-focusVisible': {
    //   backgroundColor: '#d1eaff',
    // },
}));


const InventoryMain = ({ isChannel, isLifetime }) => {
    const { productCategory } = useSelector((state => state))
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()


    const handleChange = (event, newValue) => {
        navigate(`/dashboard/${params.usercode}/${isChannel ? "channel-" : isLifetime?"life-time-": ""}inventory/${newValue}`)
    }

    useEffect(() => {
        if (!productCategory.loading && productCategory.data && productCategory.data.length == 0) {
            dispatch(fetchProductCategoryAction(() => { }))
        }
    }, [])

    if (productCategory.loading || !productCategory.data || productCategory.data.length <= 0) {
        return <Box sx={{ ...center, width: "100%", height: "100%" }}><CircularProgress /></Box>
    }

    const dashboardName = isChannel ? "CHANNEL DASHBOARD" : isLifetime ? "LIFE TIME " : "DASHBOARD"
    return (<>
        <CustomContainer >
            <Paper sx={{ width: "100%", zIndex: 111, mt: 2 }} mt={2}>
                <Tabs
                    sx={{
                        background: "linear-gradient(0.22deg, #A91674 -94.29%, #E6204B 121.72%)", '& .MuiTabs-indicator': {
                            backgroundColor: 'transparent'
                        },
                    }}
                    value={params.productCategoryId ?? ""}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="scrollable prevent tabs example"
                >
                    <AntTab label={dashboardName} value="" />
                    {
                        productCategory.data && Array.isArray(productCategory.data) && productCategory.data.length > 0 &&
                        productCategory.data.map((productCate) => {
                            if (productCate.name != "OTHER")
                                return <AntTab value={productCate._id} key={productCate._id} label={productCate.name} />
                        })
                    }

                </Tabs>

                <GrayBgBox sx={{ flexDirection: "column" }}>
                    <Outlet />
                </GrayBgBox>
            </Paper>
        </CustomContainer>

    </>)
}
export default InventoryMain