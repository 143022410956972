import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { Link, useParams } from "react-router-dom"

import { fetchInventoryList, fetchLifeTimeStockList, fetchSoldList } from "../../../apis/inventory.api"
import { getDateFiltersTime, removeLastBracketFromName } from "../../../utils/helper"
import LifeTimeStockByCategoryUi from "./LifeTimeStockByCategoryUi"

const LifetimeStockByCatrgoryController = () => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)

    const [counts, setCounts] = useState({})
    const [countsLoading, setcountLoading] = useState(false)

    const inventoryCount = useSelector(state => state.inventoryCount)

    const dispatch = useDispatch()
    const params = useParams()

    const columns = useMemo(() => [
        { id: 1, fieldName: 'boxId', label: 'Box Id', align: "left", sort: true, renderValue: (parameter, setParams) => (
            
            <Link to={`/dashboard/${params.usercode}/device/${parameter.boxId}`}>{parameter.boxId}</Link>

            )},
        { id: 2, fieldName: 'macId', label: 'Mac Id', align: "left"},
        { id: 3, fieldName: 'product', label: 'Product Name', align: "left", renderValue: (params) => removeLastBracketFromName(params.product?.name) },
        { id: 4, fieldName: 'product', label: 'Product Colour', align: "left", renderValue: (params) => params.product?.color?.name },
        { id: 5, fieldName: 'product', label: 'Product Size', align: "left", renderValue: (params) =>  params.product?.type?.name},
    ], [dispatch]);

    const [filters, setFilters] = useState({
        usercode: params.usercode,
        category: params.category,
        productCategory: params.productCategoryId,
        pageNo: 1,
        pageSize: 10,
        search: ''

    })

    const fetchList = () => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchLifeTimeStockList({ ...filters }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
    const fetchProductCategoryCounts = () => {
        setcountLoading(true)
        dispatch(callApiAction(
            async () => await fetchLifeTimeStockList({ ...filters, countByProductCategory: true, category: params.category }),
            (response) => {
                const temp = [...response]
                if (response && Array.isArray(response)) {
                    let total = temp.reduce((totalCount, item) => (totalCount + parseInt(item.count)), 0)
                    temp.unshift({
                        _id: '',
                        name: "All",
                        count: total
                    })
                }
                setCounts(temp)
                setcountLoading(false)
            },
            (err) => {
                setcountLoading(false)
            }
        ))
    }

    useEffect(() => {

        // fetchProductCategoryCounts()

        if (filters.category != params.category || filters.productCategory != params.productCategoryId) {
            setFilters({ ...filters, category: params.category })
        }
    }, [params.category,params.productCategoryId])

    useEffect(() => {
        fetchList()

    }, [filters])


    return (
        <>
            <LifeTimeStockByCategoryUi
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                list={list}
                counts={counts}
                columns = {columns}
                countsLoading={countsLoading}
                onCallBack={(productCategory) => { setFilters({ ...filters, productCategory }) }}
            />

        </>
    )
}
export default LifetimeStockByCatrgoryController