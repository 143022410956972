import { Chip, CircularProgress, Grid, InputAdornment, MenuItem, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { allowedDateFilters, dateConverter, snake2Pascal } from "../../../utils/helper"
import { center } from "../../../assets/css/theme/common"
import SearchBar, { DynamicSearchBar } from "../../../components/inputs/SearchBar"
import OneViewBox from "../../../components/layouts/OneViewBox"
import { COMPANY_NAME, inventoryStatus, permissions } from "../../../utils/constants"
import RevertButton from "../../../components/button/RevertButton"
import SubmitButton from "../../../components/button/SubmitButton"
import DropDown from "../../../components/layouts/DropDown"
import { setInventoryCountFilters } from "../../../store/actions/inventoryCountAction"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { Search } from "@mui/icons-material"
import { fetchInventoryList } from "../../../apis/inventory.api"



const SummaryUI = ({ listLoading, filters, setFilters, list, callBack, headers, onReturnButtonClick }) => {
    const user = useSelector(state => state.user)
    const params = useParams()
    const dispatch = useDispatch()
    const { inventoryCount } = useSelector(state => state)
    return (
        <>


            {/* <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Inventory Summary</Typography>
                    </Box> */}

            {listLoading && <Box sx={{ ...center ,height:"100vh"}}><CircularProgress size={50} /> </Box>}

            {!listLoading && headers && headers.length > 0 && list.length > 0 &&

                <>
                    {/* <Box mb={3} sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Box>
                            <SubmitButton onClick={onReturnButtonClick} title="Replace Device" />
                        </Box>
                    </Box> */}

                    {
                        list && Array.isArray(list) && list.length > 0 && list.map((listItem, index) => {
                            return (
                              <Box
                                sx={{ mb: 3 }}
                                key={index}
                                component={Paper}
                                p={3}
                              >
                                <Box
                                  mb={2}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: 1,
                                    }}
                                  >
                                    {listItem.title && (
                                      <Typography
                                        variant="h4"
                                        color="secondary"
                                      >
                                        {listItem.title}
                                      </Typography>
                                    )}
                                    {listItem.subTitle && (
                                      <Typography
                                        variant="body2"
                                        color="primary"
                                        textTransform={"capitalize"}
                                      >
                                        {listItem.subTitle}
                                      </Typography>
                                    )}
                                  </Box>
                                  {listItem.dropdown && (
                                    <Box sx={{ width: "300px" }}>
                                      {listItem.dropdown && (
                                        <AsyncDropDown
                                          startAdornment={
                                            <InputAdornment position="end">
                                              <Search
                                                sx={{
                                                  fill: "gray",
                                                  marginRight: 1,
                                                }}
                                              />
                                            </InputAdornment>
                                          }
                                          OptionComponent={({
                                            option,
                                            ...rest
                                          }) => (
                                            <MenuItem {...rest}>
                                              {option.boxId}
                                            </MenuItem>
                                          )}
                                          hideEndAdornment={true}
                                          InputComponent={DynamicSearchBar}
                                          onChange={(e) => { e.preventDefault(); dispatch(setInventoryCountFilters('dateTime', (e.target.value)))
                                          }}
                                          titleKey="boxId"
                                          valueKey="boxId"
                                          lazyFun={async (filters) =>
                                            await fetchInventoryList({
                                              ...filters,
                                              centralSearch: true,
                                              onlyBoxId: true,
                                            })
                                          }
                                        /> 
                                        //  <DropDown dropDownData={allowedDateFilters} dropDownProps={{
                                        //     value: inventoryCount.filters.dateTime,
                                        //     onChange: (e) => { e.preventDefault(); dispatch(setInventoryCountFilters('dateTime', (e.target.value))) }
                                        // }} /> 
                                      )}
                                    </Box>
                                  )}
                                </Box>
                                <TableContainer>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell></TableCell>
                                        {headers.map((header) =>
                                          header.name != "OTHER" ? (
                                            <TableCell
                                              key={header._id}
                                              align="center"
                                            >
                                              {header.name}
                                            </TableCell>
                                          ) : (
                                            <></>
                                          )
                                        )}
                                        <TableCell align="center">
                                          TOTAL
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {listItem.data.map((row, index) => (
                                        <TableRow key={index}>
                                          {row.map((cells, cellIndex) => {
                                            if (typeof cells == "object") {
                                              return (
                                                <TableCell
                                                  sx={{
                                                    textTransform: "capitalize",
                                                  }}
                                                  key={cellIndex}
                                                  align="left"
                                                >
                                                  {cells.title}
                                                </TableCell>
                                              );
                                            } else if (
                                              cellIndex ==
                                              row.length - 1
                                            )
                                              return (
                                                <TableCell
                                                  key={cellIndex}
                                                  align="center"
                                                >
                                                  {cells}
                                                </TableCell>
                                              );
                                            else
                                              return (
                                                <TableCell
                                                  key={cellIndex}
                                                  align="center"
                                                >
                                                  <Link
                                                    to={
                                                      (headers[cellIndex - 1]
                                                        ? headers[cellIndex - 1]
                                                            ._id + "/"
                                                        : "") + row[0].redirect
                                                    }
                                                  >
                                                    {cells}
                                                  </Link>
                                                </TableCell>
                                              );
                                          })}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            );
                        })
                    }

                </>

            }


        </>
    )
}
export default SummaryUI