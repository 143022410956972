import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchChannelSalesList, fetchSoldList } from "../../../apis/inventory.api"
import { dateConverter, getDateFiltersTime } from "../../../utils/helper"
import ChannelSoldPageUi from "./ChannelSoldPageUi"
const ChannelSoldController = () => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)
    const inventoryCount = useSelector(state => state.inventoryCount)

    const dispatch = useDispatch()
    const params = useParams()
    const columns = useMemo(() => [
        { id: 1, fieldName: 'boxId', label: 'Box Id', align: "left",  minWidth: '150px' },
        { id: 2, fieldName: 'sold', label: 'Customer Name', align: "left",  renderValue: (params) => params.sold?.cname},
        { id: 3, fieldName: 'sold', label: 'Customer Phone', align: "left", renderValue: (params) => params.sold?.cphone},
        { id: 4, fieldName: 'sold', label: 'Amount', align: "left",renderValue: (params) => params.sold?.amount},
        { id: 5, fieldName: 'sold', label: 'Sale Date', align: "left" ,renderValue: (params) =>  dateConverter({value : params.sold?.date}) },
        { id: 6, fieldName: 'sold', label: 'Sold By', align: "left",renderValue: (params) => params.sold?.soldBy.name}
      ], [dispatch]);
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        productCategory:params.productCategoryId

    })

    const fetchList = () => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchChannelSalesList({ ...filters, ...inventoryCount.filters, ...getDateFiltersTime(inventoryCount.filters.dateTime) }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
   
   
    useEffect(() => {
        fetchList()
    }, [filters,inventoryCount.filters])
   

    return (
        <>
            <ChannelSoldPageUi
                
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                list={list}
                columns={columns}
                 />

        </>
    )
}
export default ChannelSoldController