import { useCallback, useMemo, useReducer, useState } from "react"
import { useParams } from "react-router-dom"
import importDeviceSheetReducer from "../../../store/reducers/importDeviceSheetReducer"
import ImportDeviceUi from "./ImportDeviceUi"

import Papa from "papaparse";
import { addDataToSheetAction, setErrorsInDeviceList } from "../../../store/actions/importDeviceSheetAction";
import useValidate from "../../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { registerBoxes } from "../../../apis/inventory.api";
import { fetchLifeTimeStockCountAction } from "../../../store/actions/lifeTimeStockCountAction";
import { fetchInventoryCountAction } from "../../../store/actions/inventoryCountAction";
import { getDateFiltersTime } from "../../../utils/helper";
import MessageDilog from "../../../components/MessageDilog";
import { openModal } from "../../../store/actions/modalAction";
import { callApiAction } from "../../../store/actions/commonAction";

const ImportDevicesController = ({ callBack }) => {

    const params = useParams()
    const validate = useValidate()
    const dispatch = useDispatch()
    const { inventoryCount } = useSelector(state => state)
    const [fields, setFields] = useState({
        defaultAmount: 10000,
        err: '',
        invoiceId: '',
        product: null,
        usercode: params.usercode
    })
    const [loading, setLoading] = useState()

    const [sheet, dispatchSheet] = useReducer(importDeviceSheetReducer, [])

    const onFileSelect = (e) => {

        setLoading(true)
        const file = e.target.files[0]
        const reader = new FileReader();


        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;

            const columns = Object.keys(parsedData[0]);

            if (!columns.includes('boxId')) {
                setFields({ ...fields, err: 'There is no boxId field' })
                setLoading(false)
                return
            }

            if (!columns.includes('macId')) {
                if (!columns.includes('convertedBoxes')) {
                    setFields({ ...fields, err: 'Add Either Mac Id or converted Boxes Id' })
                    setLoading(false)
                    return
                }
            }

            if (parsedData && Array.isArray(parsedData)) {
                for (let i = 0; i < parsedData.length; i++) {
                    if (parsedData[i]['boxId'] && parsedData[i]['boxId'] != '' && parsedData[i]['boxId'] != '\n') {
                        parsedData[i]['id'] = i + 1

                        if (parsedData[i]['convertedBoxes']) {
                            parsedData[i]['convertedBoxes'] = parsedData[i]['convertedBoxes'].split(',')
                        } else {
                            parsedData[i]['convertedBoxes'] = []
                        }
                        parsedData[i]['hasError'] = ''
                    } else {

                        parsedData.splice(i, 1)
                    }

                }


            } else {
                alert('cant read file.')
            }

            dispatchSheet(addDataToSheetAction(parsedData))

            setLoading(false)
        };
        reader.readAsText(file);

    }

    const validationSchemaForFinaleSubmit = useMemo(() => ([

        {
            required: true,
            value: fields.defaultAmount,
            field: 'Unit Price ',
        },

        {
            required: true,
            value: fields.invoiceId,
            field: 'Invoice number',
        },
        {
            required: true,
            value: fields.product,
            field: 'Product ',
        }

    ]), [fields])

    const onSubmit = useCallback((e) => {

        e.preventDefault()
        const validationResponse = validate(validationSchemaForFinaleSubmit)



        if (validationResponse === true) {
            setLoading(true)

            const transferableData = {
                invoiceId: fields.invoiceId,
                devices: sheet.map((val) => ({
                    boxId: val.boxId,
                    amount: fields.defaultAmount,
                    product: fields.product,
                    macId: val.macId ? val.macId : null,
                    convertedBoxes: val.convertedBoxes ? val.convertedBoxes : [],
                })),
            }
            dispatch(
                callApiAction(
                    async () => await registerBoxes(transferableData),
                    async (response) => {

                        await callBack()

                        dispatch(fetchLifeTimeStockCountAction({ usercode: params.usercode }, () => { }, () => { }))
                        dispatch(fetchInventoryCountAction({ productCategory: params.productCategoryId,usercode: params.usercode, ...getDateFiltersTime(inventoryCount.filters.dateTime), ...inventoryCount.filters }, () => { }, () => { }))
                        dispatch(openModal({
                            component:<MessageDilog title={"Devices Imported"} message="Devices Imported successfully." />,
                            size : "sm",
                        }))

                    },
                    (err, wholeErr) => {

                        setFields({ ...fields, err })

                        dispatchSheet(setErrorsInDeviceList(wholeErr))
                        setLoading(false)
                        
                    }
                )
            )

        } else
            setFields({ ...fields, err: validationResponse })
    }, [fields, sheet])
   console.log(sheet)
    return <ImportDeviceUi onSubmit={onSubmit} loading={loading} sheet={sheet} dispatchSheet={dispatchSheet} onFileSelect={onFileSelect} fields={fields} setFields={setFields} />

}
export default ImportDevicesController