import { memo, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { createReplacementTicketApi, updateReplacementTicketStatus } from "../../apis/replacement.api"
import ReplaceDeviceUi from "./ReplaceDeviceUi"

const ReplaceDeviceController = ({ callBack=()=>{} ,returnedDeviceLength,status,ticketNo}) => {

    
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        devices: null,
        ticketNo,
        status,
        usercode: params.usercode

    })


    const validationSchema = useMemo(() => ([
       
        {
            required: true,
            value: fields.devices,
            
            custom: () => {

                if (fields.devices.length != returnedDeviceLength) {
                    return `Please select exactly ${returnedDeviceLength} devices to be returened.`
                }
                return true
            }
        },

    ]), [fields,returnedDeviceLength])


    const onSubmit = async (e) => {
        e.preventDefault()

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateReplacementTicketStatus(fields),
                    async (response) => {
                        await callBack()
                        setLoading(false)
                        dispatch(closeModal())
                        
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    return <ReplaceDeviceUi loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(ReplaceDeviceController)