import axios from "axios";
import { getHeaders } from "../utils/helper";

import endpoints from "./endpoints";

export const fetchLifeTimeStockCount = async params => {
    const callResponse = await axios({
        url: endpoints.inventoryLifeCount,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchInvoiceHtml = async params => {
    const callResponse = await axios({
        url: endpoints.invoiceHtml,
        method: "get",
        headers: { ...getHeaders(), responseType: 'blob' },
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};



export const fetchStockCount = async params => {
    const callResponse = await axios({
        url: endpoints.inventoryCount,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchSoldList = async params => {
    const callResponse = await axios({
        url: endpoints.soldList,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const fetchSoldInvoiceList = async params => {
    const callResponse = await axios({
        url: endpoints.soldInvoices,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchInventoryList = async params => {

    const callResponse = await axios({
        url: endpoints.inventoryBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const checkDeviceSoldStatus = async params => {

    const callResponse = await axios({
        url: endpoints.checkSoldStatus,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const checkDeviceReturnableStatus = async params => {

    const callResponse = await axios({
        url: endpoints.checkReturnableStatus,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const replaceDeviceApi = async data => {

    const callResponse = await axios({
        url: endpoints.returnReplaceDevice,
        method: "POST",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const payInvoiceApi = async data => {

    const callResponse = await axios({
        url: endpoints.payInvoice,
        method: "POST",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchStockCountByProductCategory = async params => {

    const callResponse = await axios({
        url: endpoints.stockByProductCategory,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchLifeTimeStockList = async params => {

    const callResponse = await axios({
        url: endpoints.inventoryLifeData,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const transferInventory = async data => {

    const callResponse = await axios({
        url: endpoints.inventoryTransfer,
        method: "POST",
        headers: { "Content-Type": "application/json", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const sellInventory = async data => {

    const callResponse = await axios({
        url: endpoints.inventorySell,
        method: "POST",
        headers: { "Content-Type": "application/json", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchInventoryCatgoryList = async params => {

    const callResponse = await axios({
        url: endpoints.productCategory,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchChannelStockList = async params => {

    const callResponse = await axios({
        url: endpoints.channelStock,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const fetchChannelSalesList = async params => {

    const callResponse = await axios({
        url: endpoints.channelSales,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchInventoryDetails = async params => {

    const callResponse = await axios({
        url: endpoints.inventoryDetails,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const fetchSoldTypes = async params => {
    const callResponse = await axios({
        url: endpoints.salesType,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchInvoices = async params => {
    const callResponse = await axios({
        url: endpoints.invoices,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const fetchProducts = async params => {
    const callResponse = await axios({
        url: endpoints.products,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const registerBoxes = async data => {
    const callResponse = await axios({
        url: endpoints.inventoryBase,
        method: "POST",
        headers: { "Content-Type": "application/json", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const revertBoxApi = async data => {
    const callResponse = await axios({
        url: endpoints.revertBox,
        method: "POST",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const splitConvertedBoxApi = async data => {
    const callResponse = await axios({
        url: endpoints.splitConvertedtBox,
        method: "POST",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const inventorySummaryApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.inventorySummary,
        method: "GET",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const inventoryChannelSummaryApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.inventoryChannelSummary,
        method: "GET",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const fetchProductCategoryApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.productCategoryList,
        method: "GET",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};