import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Collapse, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { center } from "../../../assets/css/theme/common"
import SearchBar from "../../../components/inputs/SearchBar"
import CategoryCountDisplay from "../../../components/layouts/inventory/CategoryCountDisplay"
import ChannelStockDisplay from "../../../components/layouts/inventory/ChannelStockCount"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"

const Row = ({ row, ...props }) => {

    const [open, setOpen] = useState(false)

    let total = 0

    if (row.devices && Array.isArray(row.devices) && row.devices.length > 0) {
        row.devices.forEach((device) => {
            total += device.count
        })
    }

    return <React.Fragment key={row.usercode}>

        <Paper elevation={2} sx={{ flex: 1, display: "flex", m: 2, my: 2, mb: 0 }} >
            <Grid container spacingX={2} spacingY={0}>
                <Grid item xs={12} md={3}>

                    <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%", p: 2 }}>
                        <Link to={"/dashboard/" + row.usercode + "/inventory/"}>
                            <Typography variant="h6" color={"primary"}>{row.name}</Typography>
                        </Link>
                    </Box>

                </Grid>
                <Grid item xs={12} md={7.5}>
                    <ChannelStockDisplay title={total} subTitle={"CURRENT STOCK"} />
                </Grid>

                <Grid item xs={12} md={1.5}>
                    <Box sx={{ ...center, height: "100%", width: "100%", }}>
                        <IconButton
                            aria-label="expand row"
                            size="large"
                            fontSize={40}
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUp fontSize={"large"} /> : <KeyboardArrowDown fontSize={"large"} />}
                        </IconButton>
                    </Box>

                </Grid>
            </Grid>

        </Paper>





        <Collapse timeout="auto" in={open} sx={(theme) => ({ background: theme.palette.grey[200], flex: 1, display: "flex", mx: 2, borderRadius: 1 })}>
            <Grid container spacingX={2} spacingY={0} p={3}>
                <Grid item xs={12} md={12}>

                    <Typography variant="h3" mb={3}>Stock</Typography>



                    <Grid container spacingX={2} spacingY={2} mt={5}>
                        {row.devices.map((item) => <Grid item xs={4} md={3} key={item.name}> <CategoryCountDisplay title={item.count} subTitle={item.product && item.product.name} /></Grid>)}
                    </Grid>


                </Grid>

            </Grid>
        </Collapse>


    </React.Fragment>
}

const ChannelStockPageUi = ({ listLoading, filters, setFilters, list }) => {
    const user = useSelector(state => state.user)
    return (
        <>

            <Box pt={4} >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", flexWrap: "wrap", flex: 1, justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Channel Stock</Typography>
                        <Box pl={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                           {/* <SearchBar placeholder={"Search by Username"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                           <AsynSearchBar
                                fullWidth
                                title="Search by Username"
                                size="medium"
                                placeholder={"Search by Username"}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />
                        </Box>
                    </Box>
                    <TableContainer >


                        {listLoading &&

                            <>
                                <Skeleton width={"100%"} height={'50px'} />
                                <Skeleton width={"100%"} height={'50px'} />
                                <Skeleton width={"100%"} height={'50px'} />

                            </>
                        }
                        {!listLoading && list && list.result && list.result.length <= 0 &&
                            <Typography sx={{ ...center }} variant="h3" align="center" >No Data</Typography>
                        }
                        {!listLoading && list && list.result && list.result.length > 0 && list.result.map((row, index) => (
                            <Row key={row._id} row={row} />
                        ))}
                        {list && list.total > 0 && <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={list.total}
                            rowsPerPage={parseInt(filters.pageSize)}
                            page={parseInt(filters.pageNo) - 1}
                            onPageChange={(e, page) => { setFilters({ ...filters, 'pageNo': page + 1 }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, 'pageSize': e.target.value }) }}
                        />}
                    </TableContainer>
                </Paper>
            </Box>

        </>
    )
}
export default ChannelStockPageUi