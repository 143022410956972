// import { useCallback, useEffect, useState } from "react"
// import { useDispatch, useSelector } from 'react-redux'
// import { callApiAction } from "../../store/actions/commonAction"
// import { useParams } from "react-router-dom"

// import ListUi from "./ListUi"
// import { getReplacementTicketListApi } from "../../apis/replacement.api"
// import TicketCount from "./TicketCounts"

// const ListController = ({ onlyme }) => {

//     const [list, setList] = useState({})
//     const [listLoading, setListLoading] = useState(false)


//     const dispatch = useDispatch()
//     const params = useParams()
//     const [filters, setFilters] = useState({
//         usercode: params.usercode,
//         pageNo: 1,
//         pageSize: 10,
//         search: '',
//         onlyme,
//         status: null,
//         key:''

//     })
//     const fetchList = () => {

//         setListLoading(true)
//         dispatch(callApiAction(
//             async () => await getReplacementTicketListApi({ ...filters,onlyme }),
//             (response) => {

//                 setList(response)
//                 setListLoading(false)
//             },
//             (err) => {
//                 setListLoading(false)
//             }
//         ))
//     }



//     useEffect(() => {
//         fetchList()
//     }, [filters,onlyme])


//     return (
//         <>
//             <TicketCount key={filters.key} onlyme={onlyme} onChangeStatus={(val) => setFilters({ ...filters, status: val })} />
//             {filters.status && <ListUi

//                 listLoading={listLoading}
//                 filters={filters}
//                 setFilters={setFilters}
//                 list={list}
//             />}

//         </>
//     )
// }
// export default ListController


import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import ListUi, { StatusActionComponent } from "./ListUi"
import { getReplacementTicketListApi } from "../../apis/replacement.api"
import TicketCount from "./TicketCounts"
import { IconButton } from "@mui/material"
import { Visibility } from "@mui/icons-material"
import { openModal } from "../../store/actions/modalAction"
import { dateConverter } from "../../utils/helper"
import TicketDetailsController from "./TicketDetailsController"


const ListController = ({ onlyme }) => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)


    const dispatch = useDispatch()
    const params = useParams()


    const columns = useMemo(() => [

        { id: 1, fieldName: 'ticketNo', label: 'Ticket Id', align: "left", minWidth: '150px' },
        { id: 2, fieldName: 'type', label: 'Ticket Type', align: "left" },

        { id: 3, fieldName: 'returnedDevice', label: 'Total Device', align: "left", renderValue: (params, setParams) => params.returnedDevice.length },
        { id: 4, fieldName: 'logs', label: 'Date', align: "left", renderValue: (params) => dateConverter({ type: "DD_MM_YYYY", value: params.createdAt }) },
        {
            id: 5, fieldName: 'Action', label: 'Action', align: "left", renderValue: (params, setParams) =>
                <StatusActionComponent
                    filters={filters}
                    setFilters={setFilters}
                    ticketNo={params.ticketNo}
                    by={params.by}
                    status={params.status}
                    devices={params.returnedDevice}
                />
        },

        {
            id: 6, fieldName: '', label: '', align: "left", renderValue: (params, setParams) => <>
                <IconButton onClick={() => onViewMoreClick(params)} size="small" ><Visibility /></IconButton>
            </>
        },


    ], [dispatch]);

    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        onlyme,
        status: null,
        key: ''

    })
    const fetchList = () => {

        setListLoading(true)
        dispatch(callApiAction(
            async () => await getReplacementTicketListApi({ ...filters, onlyme }),
            (response) => {

                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
    const onViewMoreClick = (params) => {
        console.log(params.ticketNo)
        dispatch(openModal({
            component: <TicketDetailsController ticketNo={params.ticketNo} />,
            size: "sm"
        }))
    }


    useEffect(() => {
        fetchList()
    }, [filters, onlyme])


    return (
        <>
            <TicketCount key={filters.key} onlyme={onlyme} onChangeStatus={(val) => setFilters({ ...filters, status: val })} />
            {filters.status && <ListUi
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                list={list}
                columns={columns}
            />}

        </>
    )
}
export default ListController