import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const SubmitButtonStyled = styled(Button)(({ theme }) => ({

    width: "100%",
    background: 'linear-gradient(93.3deg,#e6204b -67.4%,#a91674 180.21%)',
    color: theme.palette.light.main,
    whiteSpace:"nowrap",
    minWidth: "100px",
    ":disabled":{
        background:"lightgray"
    }
}));

const ResetButtonStyled = styled(Button)(({ theme, color }) => ({

    background: color ?? 'transparent',
    width: '100%',
    minWidth: '200px',
    textDecoration: "underline",
    textUnderlineOffset: "3px",
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
      padding: theme.spacing(1),
    }
  
}))

export const ResetButton = ({ title, loading, ...props }) => {
    return (
      <ResetButtonStyled variant="text" {...props} >
        {props.icon}
        {!loading && title}
  
        {loading && <CircularProgress size={25} color="light" ml={4} />}
      </ResetButtonStyled>
    )
}

const SubmitButton = ({ title, loading, ...props }) => {
    return <SubmitButtonStyled disabled={loading} {...props} >
        {props.icon}
        {!loading && title}

        {loading && <CircularProgress size={25} color="light" ml={4} />}

    </SubmitButtonStyled>
}

export default SubmitButton

