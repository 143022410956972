import axios from "axios";
import { getHeaders, refreshToken } from "../utils/helper";

import endpoints from "./endpoints";

export const signInApi = async data => {
  const callResponse = await axios({
    url: endpoints.signIn,
    method: "post",
    data,
  })
    .then(response => response.data)
    .catch(err => (err.response ? err.response.data : {}));
  return callResponse;
};
export const updateUserPassword = async data => {
  const callResponse = await axios({
    url: endpoints.resetPassword,
    method: "PUT",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const resetTokenApi = async () => {
  const callResponse = await axios({
    url: endpoints.resetToken,
    method: "post",
    data: {
      refreshToken: refreshToken.get(),
    },
  })
    .then(response => response.data)
    .catch(err => (err.response ? err.response.data : {}));
  return callResponse;
};
